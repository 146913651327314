import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { addCustomAvatar } from '../slices/avatarSlice';
import { addCustomBackground } from '../slices/backgroundSlice';
// import { addAudio, addCurrentAudio } from '../slices/commonSlice';
import { getDemographyAnswersFromStorage, updateDemographyStepperCount } from '../slices/demographicAnswerSlice';
import { getFeedbackAnswersFromStorage, updateFeedbackStepperCount } from '../slices/feedbackAnswerSlice';
import { updateRole, validateRoleId } from '../slices/roleSlice';

export const FeedbackAuthLayout: React.FC = () => {
  const dispatch = useDispatch<any>();
  // const roleData = useSelector((state: any) => state.roleInfo);
  const feedbackID: string | null = sessionStorage.getItem('feedbackId');
  const feedbackTerms: string | null = sessionStorage.getItem('terms');
  const demographyAnswers: string | null = sessionStorage.getItem('demographic');
  const feedbackAnswers: string | null = sessionStorage.getItem('feedback');
  const fbStepperCounts: string | null = sessionStorage.getItem('fbStepper');
  const avatar: string | null = sessionStorage.getItem('avatar');
  const background: string | null = sessionStorage.getItem('bg');
  // const audio: string | null = sessionStorage.getItem('audio');
  // const currentAudio: string | null = sessionStorage.getItem('currentAudio');

  useEffect(() => {
    console.log('CYP Initializing');
    const param: any = {
      id: feedbackID,
      terms: Boolean(feedbackTerms) || false
    };
    const validateRoleParam = { id: feedbackID, type: "demography" };
    dispatch(validateRoleId(validateRoleParam));
    dispatch(updateRole(param));
    if (avatar) {
      dispatch(addCustomAvatar(JSON.parse(avatar)));
    }
    if (background) {
      dispatch(addCustomBackground(JSON.parse(background)));
    }
    // if (audio && currentAudio) {
    //   dispatch(addAudio(audio));
    //   dispatch(addCurrentAudio(currentAudio));
    // }
    dispatch(getDemographyAnswersFromStorage(demographyAnswers));
    dispatch(getFeedbackAnswersFromStorage(feedbackAnswers));
    if (demographyAnswers) {
      const demographyCounts: string | null = sessionStorage.getItem('dgStepper');
      dispatch(updateDemographyStepperCount(demographyCounts));
    }
    if (feedbackAnswers) {
      dispatch(updateFeedbackStepperCount(fbStepperCounts));
    }
  }, [])

  if (feedbackID) {
    return <Outlet />;
  }

  return <Navigate to='/' replace />;
}