import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { baseURL } from '../environments/base';

const requestInterceptor = (request: AxiosRequestConfig) => {
  request.headers = setHeaders();
  return request;
};

const setHeaders = () => {
  try {
    const userLoggedInData = localStorage.getItem('userLoginData');
    if (!userLoggedInData) {
      return {};
    }
    const parsedJson = JSON.parse(userLoggedInData);
    if (!parsedJson.accessToken) {
      return {};
    }
    return {
      'authorization': `Bearer ${parsedJson.accessToken}`,
      'x-refresh-token': parsedJson.refreshToken
    }
  } catch (error) {
    return {};
  }
}

const responseInterceptor = (response: AxiosResponse) => {
  const { data } = response;
  return data;
};

const errorResponseInterceptor = (error: any) => {
  if (error.response) {
    const { status } = error.response;
    if (status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = '/admin';
      return;
    }
    if (error.response.data &&
      error.response.data.hasOwnProperty('feedbackStatus') &&
      error.response.data.hasOwnProperty('question_set_for')) {
      return Promise.reject({
        role: error.response.data.question_set_for,
        feedbackStatus: error.response.data.feedbackStatus,
        message: error.response.data.message,
        status,
      });
    }
    if (error.response.data && error.response.data.message) {
      return Promise.reject({
        message: error.response.data.message,
        status,
      });
    }
    return Promise.reject({
      message: 'Unknown error.',
      status,
    });
  } else if (error.request) {
    return Promise.reject({
      message: 'Internal server error. Please try again later.',
    });
  }

  console.log(error.toString());
  return Promise.reject({ message: 'Something went wrong.' });
};

const api = axios.create({
  baseURL,
});

api.interceptors.request.use(requestInterceptor);
api.interceptors.response.use(responseInterceptor, errorResponseInterceptor);

export default api;