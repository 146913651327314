import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBack from '../../assets/images/icons/ArrowBack';
import { PageLayout } from '../../components/PageLayout';
import MVFooter from '../../assets/images/powered-by-mindwave.png';
import NHSLogo from '../../assets/images/nhs-logo.png';
import QRCode from 'react-qr-code';

interface PrintQRCodeProps {

}

export const PrintQRCode: React.FC<PrintQRCodeProps> = ({ }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state: { data } } = location;

  const printhandler = () => {
    return window.print();
  };

  const handleBackBtn = () => {
    navigate(-1);
  };

  const generateQRUrl = (id: string) => {
    return `${window.location.origin}/qr/${id}`;
  }

  useEffect(() => {
    setTimeout(() => {
      printhandler();
    }, 1000);
  }, []);

  return (
    <PageLayout size="lg" role="commissioner">
      <section className='back-section'>
        <div className='back_block' onClick={handleBackBtn}>
          <ArrowBack />
          <p>Back</p>
        </div>
      </section>
      <section className='pdf_section'>
        <div>
          <table>
            <thead>
              <tr>
                <th>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <header className='pdf_qr_header'>
                    <h1>Safe2Share</h1>
                    <div className='output-group'>
                      <p>Date: </p>
                      <p>{data.created_date || ''}</p>
                    </div>
                    <div className='output-group'>
                      <p>Unit name: </p>
                      <p>{data?.Unit?.name || ''}</p>
                    </div>
                    <div className='output-group'>
                      <p>QR for: </p>
                      <p>{data?.question_set_for==="child"?"Service User":"Carers and Support Network"}</p>
                    </div>
                  </header>
                  <main className='pdf_body'>
                    <div className='responses-view-block'>
                      <div className='qr-code-section'>
                        <QRCode
                          size={256}
                          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                          value={generateQRUrl(data.id)}
                          viewBox={`0 0 256 256`}
                        />
                      </div>
                    </div>
                  </main>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <footer className='pdf_footer_block last-page-only'>
                    <div className='mindwave-print-footer'>
                      <img src={MVFooter} alt='Mindwave Logo' />
                    </div>
                    <div className='pdf_footer'>
                      <img src={NHSLogo} alt='nhs logo' />
                    </div>
                  </footer>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </section>
    </PageLayout >
  );
};