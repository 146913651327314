import React from "react";
import { PageLayout } from "../components/PageLayout";

interface NotFoundProps { }

export const NotFound: React.FC<NotFoundProps> = () => {
  return (
    <div className='not-found-section'>
      <PageLayout pageName='notfound'>
        <div className="dashboard-card">
          <h1 className="text-logo not-found-label">404 Not found</h1>
        </div>
      </PageLayout>
    </div>
  );
};
