import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useParams } from 'react-router-dom';

import { InnerHeaderBlock } from '../../components/innerHeaderBlock/InnerHeaderBlock';
import { PageLayout } from "../../components/PageLayout";
import { AddUnitMapPreview } from './AddUnitMapPreview';
import "../styles/unit.css";
import { useEffect, useState } from 'react';
import { CustomButton, ErrorMessage, SelectDropdown } from '../../components';
import { regionList } from '../../data/regionlist';
import { createUnitService, getUnitByIdService, updateUnitService } from '../../services/apiService';
import RefreshIcon from '../../assets/images/icons/RefreshIcon';
const LATITUDE_REGEX = /^-?([0-8]?[0-9]|90)(\.[0-9]{1,10})$/
const GROUPID_REGEX = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

const formvalidationSchema = Yup.object().shape({
  name: Yup.string().required("Unit name is required"),
  // location_code: Yup.string(),
  group_id: Yup.string()
    .required("Azure group ID is required")
    .matches(
      GROUPID_REGEX,
      "Incorrect group ID"
    ),
  // latitude: Yup.string()
  //   .matches(
  //     LATITUDE_REGEX,
  //     "Please enter the valid latitude"
  //   ),
  // longitude: Yup.string()
  //   .matches(
  //     LATITUDE_REGEX,
  //     "Please enter the valid longitude"
  //   ),
});

const AddUnitForm = () => {
  const navigate = useNavigate();
  let { unit_id } = useParams();
  const [mapPreviewData, setMapPreviewData] = useState<any>([]);
  const [colorDataSeries, setColorDataSeries] = useState<any>(['', 90]);
  const [addUnitData, setAddUnitData] = useState<any>({
    name: '',
    group_id: '',
  });
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [errorState, setErrorState] = useState<any>({
    unitError: {},
    addNewUnitError: {},
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      group_id: '',
    },
    enableReinitialize: true,

    validationSchema: formvalidationSchema,
    onSubmit: async (values: any) => {
      try {
        if (values) {
          setAddUnitData(values);
          // const unitObj = regionList.find((item: any) => item.code === values.location_code);
          // if (unitObj) {
          //   values.location = unitObj.name;
          // }          
          if (unit_id) {
            await updateUnitService(values);
            return setFormSubmitted(true);
          }
          await createUnitService(values);
          setFormSubmitted(true);
        }
      } catch (error) {
        setErrorState({
          ...errorState,
          addNewUnitError: error
        })
      }
    },
  });

  const handleBackBtn = () => {
    navigate("/unit");
  };

  // const handleMapPreview = () => {
  //   const mapObj: any = {
  //     lat: Number(formik.values.latitude),
  //     lon: Number(formik.values.longitude),
  //     name: formik.values.name,
  //   };
  //   setMapPreviewData((prevState: any) => [JSON.parse(JSON.stringify(mapObj))]);
  // };

  const getUnitById = async (unit_id: string) => {
    const unitResponse = await getUnitByIdService(unit_id);
    const objectKeys = Object.keys(unitResponse.data);
    objectKeys.map((key: string) => {
      formik.setFieldValue(key, unitResponse.data[key]);
      if (key === 'location_code') {
        setColorDataSeries([unitResponse.data[key], 90])
      }
    })
  }

  useEffect(() => {
    if (unit_id) {
      getUnitById(unit_id)
    }
    // eslint-disable-next-line
  }, [])
  return (
    <PageLayout size='lg' role="commissioner">
      <InnerHeaderBlock
        backtext="Back"
        showSignOut
        handleBackBtn={handleBackBtn}
      />
      <div className="qrcode-block">
        <div className="title-section">
          <h1 className="">Create new unit</h1>
          <p>As a task force team, you can create a new unit by filling in the details below.</p>
        </div>
        {errorState && errorState.addNewUnitError?.message ? (
          <ErrorMessage message={errorState.addNewUnitError?.message} />
        ) : null}
        {/* Split form and map view */}
        {!isFormSubmitted ?
          <div className='form-container mt-14'>
            <form onSubmit={formik.handleSubmit} className='add-unit-block'>
              <div className="input-group">
                <label htmlFor="name">Unit name</label>
                <input
                  className={`text-field`}
                  type="text"
                  name="name"
                  placeholder="Enter name of unit"
                  id="name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
                {formik.errors.name && formik.touched.name ? <span className='mb-3 form-error'>{formik.errors.name}</span> : null}
                <div className='h-3' />
              </div>
              {/* <div className="input-group unit-region">
                <SelectDropdown
                  type="select"
                  name="location_code"
                  label='Region name'
                  placeholder="Select the unit's region"
                  optionsArray={regionList.map((item: any) => {
                    return {
                      label: item.name,
                      value: item.code,
                    };
                  })}
                  onChange={(e: any) => {
                    formik.setFieldValue('location_code', e.value);
                    setColorDataSeries([e.value, 90])
                  }}
                  value={formik.values.location_code}
                />
                {formik.errors.location_code && formik.touched.location_code ? <span className='mb-3 form-error'>{formik.errors.location_code}</span> : null}
                <div className='h-3' />
              </div> */}
              <div className="input-group">
                <label htmlFor="group_id">Azure group ID</label>
                <input
                  className={`text-field`}
                  type="text"
                  name="group_id"
                  placeholder="Enter name of the Azure group ID"
                  id="group_id"
                  onChange={(event: any) => {
                    formik.setFieldTouched('group_id');
                    formik.handleChange(event);
                  }}
                  value={formik.values.group_id}
                />
                {formik.errors.group_id && formik.touched.group_id ? <span className='mb-3 form-error'>{formik.errors.group_id}</span> : null}
                <div className='h-3' />
              </div>
              {/* <div className="input-group">
                <label htmlFor="latitude">Unit latitude</label>
                <input
                  className={`text-field unit-loc`}
                  type="text"
                  name="latitude"
                  placeholder="Enter unit's latitude"
                  id="latitude"
                  onChange={(event: any) => {
                    formik.setFieldTouched('latitude');
                    formik.handleChange(event);
                    const latValue = event?.target?.value;
                    if (latValue.length === 0) {
                      setMapPreviewData([]);
                    }
                  }}
                  value={formik.values.latitude}
                />
                <p className='sample-text'>Eg: 50.82898</p>
                {formik.errors.latitude && formik.touched.latitude ? <span className='mb-3 form-error'>{formik.errors.latitude}</span> : null}
                <div className='h-3' />
              </div> */}
              {/* <div className="input-group">
                <label htmlFor="longitude">Unit longitude</label>
                <input
                  className={`text-field unit-loc`}
                  type="text"
                  name="longitude"
                  placeholder="Enter unit's latitude"
                  id="longitude"
                  onChange={(event: any) => {
                    formik.setFieldTouched('longitude');
                    formik.handleChange(event);
                    const latValue = event?.target?.value;
                    if (latValue.length === 0) {
                      setMapPreviewData([]);
                    }
                  }}
                  value={formik.values.longitude}
                />
                <p className='sample-text'>Eg: -0.18026</p>
                {formik.errors.longitude && formik.touched.longitude ? <span className='mb-3 form-error'>{formik.errors.longitude}</span> : null}
                <div className='h-3' />
              </div> */}
              <div className="add-unit-btn">
                {unit_id ?
                  <CustomButton
                    name='Update unit'
                    type='submit' /> :
                  <CustomButton
                    name='Add new unit'
                    disable={!(formik.isValid && formik.dirty)}
                    type='submit' />}
              </div>
            </form>
            {/* <div className='map-preview-blk'>
              <div className='map-view-header'>
                <h5 className='map-label'>Unit location preview</h5>
                <span
                  className='refresh-btn'
                  onClick={handleMapPreview}>
                  <RefreshIcon />
                </span>
              </div>
              <div className='unit-map-preview'>
                {mapPreviewData && mapPreviewData.length ?
                  <AddUnitMapPreview colorDataPoints={colorDataSeries} mapData={mapPreviewData} /> :
                  <div className='preview-blk'>
                    <p className='preview-msg'>You need to preview the location before creating new unit</p>
                    <div>
                      <CustomButton
                        name='Preview location'
                        disable={unit_id ? false : ((formik.values?.latitude?.length === 0 || !LATITUDE_REGEX.test(formik.values?.latitude)) ||
                          (formik.values?.longitude?.length === 0 || !LATITUDE_REGEX.test(formik.values?.latitude)))}
                        hanldeClick={handleMapPreview} />
                    </div>
                  </div>}
              </div>
            </div> */}
          </div> :
          <div className='success-card mt-6'>
            <h5 className='success-title'>Unit {unit_id ? 'Updated' : 'created'} successfully</h5>
            <p className='success-msg'>You have {unit_id ? 'Updated' : 'created'} {addUnitData.name || ''} successfully. Please go back to your all units page to view.</p>
            <div>
              <CustomButton name='Back to units' hanldeClick={handleBackBtn} />
            </div>
          </div>}
      </div>
    </PageLayout>
  );
};

export default AddUnitForm;
