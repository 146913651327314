import React from "react";

const IconCheckBox: React.FC = () => (
  <svg
    className="check-box-svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
  >
    <g id="Group_3695" data-name="Group 3695" transform="translate(-357 -565)">
      <g
        id="Rectangle_2545"
        data-name="Rectangle 2545"
        transform="translate(357 565)"
        className="color-first"
      >
        <path
          d="M 31.5 31.5 L 0.5 31.5 L 0.5 0.5 L 31.5 0.5 L 31.5 31.5 Z"
          stroke="none"
        />
      </g>
      <path
        id="Path_15102"
        data-name="Path 15102"
        d="M-5567.529,511.942l-5.613-6.12,1.474-1.352,4.188,4.567,10.462-10.657,1.427,1.4Z"
        transform="translate(5937.355 76.215)"
        className="color-sec"
      />
    </g>
  </svg>
);

export default IconCheckBox;
