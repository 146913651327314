import React from "react";

const IconDropdown: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <g data-name="icon-dropdown" transform="translate(-1265 -1977)">
      <rect
        id="IconDropdown_rect"
        data-name="Rectangle 862"
        width="32"
        height="32"
        transform="translate(1265 2009) rotate(-90)"
        fill="none"
      />
      <path
        id="IconDropdown_path"
        data-name="Path 19497"
        d="M797.588,487.588a2,2,0,0,1,2.833,0l6.6,6.6,6.6-6.6a2,2,0,0,1,2.833,2.833l-8.014,8.014a2,2,0,0,1-2.833,0l-8.014-8.014a2,2,0,0,1,0-2.833Z"
        transform="translate(474 1500)"
        fill-rule="evenodd"
      />
    </g>
  </svg>
);

export default IconDropdown;
