import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  feedbackAnswers: [],
  fbStepperCount: [1],
  currentStep: 1,
  hasFeedbackPreview: false,
};

const feedbackAnswerSlice = createSlice({
  name: "feedbackAnswer",
  initialState,
  reducers: {
    setCurrentStep(state, action) {
      state.currentStep = action.payload;
    },
    setFeedbackPreview(state, action) {
      state.hasFeedbackPreview = action.payload;
    },
    addAnswer: (state, action) => {
      const { feedbackAnswers } = state;
      console.log(
        JSON.parse(JSON.stringify(feedbackAnswers)),
        "add feedback answer"
      );
      feedbackAnswers.push(action.payload);
      sessionStorage.setItem("feedback", JSON.stringify(feedbackAnswers));
    },
    updateAnswer: (state, action) => {
      const param = action.payload;
      const existingAnswers = JSON.parse(JSON.stringify(state.feedbackAnswers));
      const updateValue = existingAnswers.map((fdAnsItem: any) => {
        let existingfdAnsItem = { ...fdAnsItem };
        if (existingfdAnsItem.question === param.question) {
          if (param.type==="textarea") {
            existingfdAnsItem.answer = param.answer || "";
          } else {
            existingfdAnsItem.option = param.option;
          }
        }
        return existingfdAnsItem;
      });
      state.feedbackAnswers = updateValue;
      sessionStorage.setItem("feedback", JSON.stringify(updateValue));
    },
    getFeedbackAnswersFromStorage: (state, action) => {
      if (action && action.payload) {
        const feedbackAnswersList = JSON.parse(action.payload);
        return {
          ...state,
          feedbackAnswers: feedbackAnswersList,
        };
      }
    },
    addFeedbackStepperCount: (state, action) => {
      const { fbStepperCount } = state;
      if (
        action &&
        action.payload &&
        !fbStepperCount.includes(action.payload)
      ) {
        fbStepperCount.push(action.payload);
        sessionStorage.setItem("fbStepper", JSON.stringify(fbStepperCount));
      }
    },
    removeFeedbackStepperCount: (state) => {
      state.fbStepperCount.pop();

      sessionStorage.setItem("fbStepper", JSON.stringify(state.fbStepperCount));
    },
    updateFeedbackStepperCount: (state, action) => {
      if (action && action.payload) {
        const counts = JSON.parse(action.payload);
        return {
          ...state,
          fbStepperCount: counts,
        };
      }
    },
    resetFeedbackAnswer: (state) => {
      state.feedbackAnswers = [];
      state.fbStepperCount = [1];
      sessionStorage.removeItem("feedback");
      sessionStorage.removeItem("fbStepper");
    },
    resetFbStepperCount: (state) => {
      state.fbStepperCount = [1];
      sessionStorage.setItem("fbStepper", JSON.stringify([1]));
    },
  },
});

export const {
  addAnswer,
  updateAnswer,
  getFeedbackAnswersFromStorage,
  resetFeedbackAnswer,
  addFeedbackStepperCount,
  removeFeedbackStepperCount,
  updateFeedbackStepperCount,
  resetFbStepperCount,
  setCurrentStep,
  setFeedbackPreview,
} = feedbackAnswerSlice.actions;
export default feedbackAnswerSlice.reducer;
