import { ICYPDemographyFeedback } from "../types/feedback";

interface demographyInputStats {
  ethnicity_stats: Array<any>;
  age_stats: Array<any>;
  gender_male_count: number;
  gender_female_count: number;
  gender_others_binary_count: number;
  gender_others_count: number;
  sex_male_count: number;
  sex_female_count: number;
  sex_others_count: number;
  cyp_count: number;
  parent_count: number;
  have_admin_access: boolean;
  nonBinaryCount:number
}

interface cypFeedback {
  id: string;
  question: string;
  date: string;
  answer: string;
}

export const convertDemographyStatsData = (
  demographyStats: demographyInputStats
) => {
  const returnArray: any = [
    {
      id: 1,
      heading: "Service User",
      value: demographyStats.cyp_count,
    },
    // {
    //   id: 2,
    //   heading: "Age range",
    //   value: demographyStats.age_stats,
    // },
    {
      id: 3,
      heading: "Carers and Support Network",
      value: demographyStats.parent_count,
    },
    {
      id: 4,
      heading: "Gender - Male",
      value: demographyStats.gender_male_count,
    },
    {
      id: 5,
      heading: "Gender - Female",
      value: demographyStats.gender_female_count,
    },
    {
      id: 6,
      heading: "Gender - Prefer not to say",
      value: demographyStats.gender_others_count,
    },
    {
      id: 7,
      heading: "Gender - Non binary",
      value: demographyStats.nonBinaryCount,
    },
    {
      id: 8,
      heading: "Gender - Other",
      value: demographyStats.gender_others_binary_count,
    },
    // {
    //   id: 8,
    //   heading: "Sex - Male",
    //   value: demographyStats.sex_male_count,
    // },
    // {
    //   id: 9,
    //   heading: "Sex - Female",
    //   value: demographyStats.sex_female_count,
    // },
    // {
    //   id: 10,
    //   heading: "Sex - Prefer not to say",
    //   value: demographyStats.sex_others_count,
    // },
  ];
  const length = returnArray.length;
  demographyStats.ethnicity_stats.map((ethnicity, index) => {
    returnArray.push({
      id: length + (index + 1),
      heading: Object.keys(ethnicity)[0],
      value: Object.values(ethnicity)[0],
    });
  });
  demographyStats.age_stats.map((age, index) => {
    returnArray.push({
      id: length + (index + 1),
      heading: Object.keys(age)[0],
      value: Object.values(age)[0],
    });
  });
  return returnArray;
};

export const convertCypFeedbackQuestions = (questionList: any) => {
  const feedbackList: Array<cypFeedback> = questionList.feedback.map(
    (questionObj: any, index: number) => {
      return {
        id: index + 1,
        question: `${
          questionObj.Question.question
        }`,
        date: questionObj.date,
        answer: `${
          questionObj.answer
            ? questionObj.answer
            : questionObj.Option.option_value + "/4"
        }`,
      };
    }
  );
  const demographyData: ICYPDemographyFeedback = {
    gender: getDemographyAnswer(questionList.demography, "gender"),
    ethnicity: getDemographyAnswer(questionList.demography, "ethnicity"),
    age: getDemographyAnswer(questionList.demography, "age"),
    sex: getDemographyAnswer(questionList.demography, "sex"),
    cyp_id: questionList.cyp_id,
  };  
  return {
    demographyData,
    feedbackList,
  };
};

const getDemographyAnswer = (questionList: any, type: string) => {
  
  if (type === "gender") {
    const genderQuestion = questionList.find(
      (questionObj: any) =>
        questionObj.Question.question === "Please select your gender?"
    );
    if (genderQuestion) {
      return genderQuestion.Option.option_text;
    }
    return "-";
  } else if (type === "ethnicity") {
    const ethnicityQuestion = questionList.find(
      (questionObj: any) =>
        questionObj.Question.question === "Please select your ethnicity?"
    );
    if (ethnicityQuestion) {
      return ethnicityQuestion.Option.option_text;
    }
    return "-";
  } else if (type === "age") {
    const ethnicityQuestion = questionList.find(
      (questionObj: any) =>
        questionObj.Question.question === "Please select your age group?"
    );
    if (ethnicityQuestion) {
      return ethnicityQuestion.Option.option_text;
    }
    return "-";
  } else if (type === "sex") {
    const ethnicityQuestion = questionList.find(
      (questionObj: any) =>
        questionObj.Question.question === "What sex were you assigned at birth?"
    );
    if (ethnicityQuestion) {
      return ethnicityQuestion.Option.option_text;
    }
    return "-";
  } else {
    return "-";
  }
};
