import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { PageLayout } from "../../components/PageLayout";
import { Card } from "../../components/Card";
import { CustomButton } from "../../components";
import "../styles/background.css";
import { addCustomBackground } from "../../slices/backgroundSlice";
import BackButton from "../../components/backButton/BackButton";

import AvatarImg from "../../assets/images/flowers-background.jpg";
import AvatarImg2 from "../../assets/images/Fruits.jpeg";
import AvatarImg3 from "../../assets/images/Sunset.jpeg";
import AvatarImg4 from "../../assets/images/Mushrooms.jpeg";
import AvatarImg5 from "../../assets/images/Beach.jpeg";




import Iconsvg from "../../components/icon/Icon";

const BG_SETS = [
  {
    name: "Flowers",
    value: "flowers",
    isImage: true,
    color: "",
    bgSrc: AvatarImg,
  },
  {
    name: "Fruits",
    value: "fruits",
    isImage: true,
    color: "",
    bgSrc: AvatarImg2,
  },
  {
    name: "Sunset",
    value: "sunset",
    isImage: true,
    color: "",
    bgSrc: AvatarImg3,
  },
  {
    name: "Mushrooms",
    value: "mushrooms",
    isImage: true,
    color: "",
    bgSrc: AvatarImg4,
  },
  {
    name: "Beach",
    value: "beach",
    isImage: true,
    color: "",
    bgSrc: AvatarImg5,
  },
  {
    name: "Default",
    value: "default",
    isImage: false,
    color: "#F0F0F0",
    bgSrc: "",
  },
];

export const Background: React.FC = () => {
  const [customBg, setCustomBg] = useState<any>({});
  const dispatch = useDispatch();

  const iconShow = true;
  const tabIndex = 0;

  const handleBackground = (event: any, item: any) => {
    const { value } = event.target;
    setCustomBg((prevState: any) => ({
      ...prevState,
      isBgImg: item.isImage,
      bgValue: value,
    }));
  };

  const applyBackground = () => {    
    if (customBg && Object.keys(customBg).length) {
      console.log(customBg)
      dispatch(addCustomBackground(customBg));
    }
  };

  return (
    <PageLayout>
      <div className="background-section">
        <h1 className="mb-24 text-center">Background</h1>
        <Card
          cardTitle="Select a background"
          type={true}
          removeBottomPadding={true}
        >
          <div>
            <p>Please select a background</p>
          </div>
          <div className="h-4"></div>
          <fieldset className="avatar-selector-container">
            {BG_SETS &&
              BG_SETS.map((item: any, idx: number) => {
                const { isImage, name } = item;
                console.log(item.value,item.bgSrc,"<<<<<<<<<");
                
                return (
                  <div key={`bg-card-${idx}`}>
                    <div
                      className="avatar-selector-wapper"
                      key={`bg-item-${idx}`}
                    >
                      <label className="avatar-selector">
                        <input
                          tabIndex={tabIndex}
                          onChange={(event: any) =>
                            handleBackground(event, item)
                          }
                          type="radio"
                          name={item.name}
                          value={item.value}
                          checked={customBg && customBg.bgValue === item.value}
                          readOnly
                        />
                        <div
                          className={`label-text ${iconShow ? "icon-label" : ""
                            }`}
                        >
                          {isImage ? (
                            <img
                              className="background-img"
                              src={item.bgSrc}
                              alt="AvatarImg"
                            />
                          ) : (
                            <div
                              className="bg-item-section"
                              style={{ backgroundColor: item.color }}
                            />
                          )}

                          <div
                            className={
                              customBg && customBg.bgValue === item.value
                                ? "ticked-img"
                                : ""
                            }
                          >
                            {customBg && customBg.bgValue === item.value ? (
                              <Iconsvg name="selectorCheckBox" />
                            ) : null}
                          </div>
                        </div>
                      </label>
                      <p className="bg-label">{name}</p>
                    </div>
                  </div>
                );
              })}
          </fieldset>
          <div className="h-4"></div>
          <div className="flex justify-center">
            <CustomButton
              name="Apply"
              hanldeClick={applyBackground}
              disable={customBg && !Object.keys(customBg).length}
            />
          </div>
          <div className="h-4"></div>
          <div className="h-4"></div>
          <>
            <BackButton
              type={false}
              buttonLabel="Button"
              iconName="backButton"
              buttonContent="Back"
            />
          </>
        </Card>
      </div>
    </PageLayout>
  );
};
