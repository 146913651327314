import { useField } from 'formik';
import React, { useState } from 'react';
import '../components/styles/textinput.css';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  time?: boolean;
  label?: string;
  addClass?: string;
  type?: string;
  icon?: 'search' | 'password' | 'delete' | 'file' | 'toggle' | null;
  onIconClick?: (e: any) => void;
  showInfoIcon?: boolean;
  showForgot?: boolean;
}

export const TextField: React.FC<Props> = ({
  label = '',
  addClass = '',
  type = '',
  icon = '',
  onIconClick,
  showInfoIcon,
  showForgot,
  ...props
}) => {
  const [field, meta] = useField({ name: props.name });
  const [showInfo, setshowInfo] = useState(false);
  const tabIndex = 0;

  return (
    <div className={`input-group ${addClass ? addClass : ''}`}>
      <div className='flex'>
        {label && <label>{label}</label>}
        {showInfoIcon && (
          <span
            className='info_icon'
            onClick={() => (showInfo ? setshowInfo(false) : setshowInfo(true))}
          >
          </span>
        )}
      </div>
      <div className='relative'>
        <input
          className={`text-field  ${meta.touched && meta.error ? 'error' : ''}`}
          {...field}
          {...props}
          type={type}
        />
      </div>
      <div
        className={`flex items-baseline ${meta.touched && meta.error ? 'justify-between' : 'justify-end'
          }`}
      >
        {meta.touched && meta.error ? (
          <div className='aw-error'>{meta.error}</div>
        ) : null}
      </div>
    </div>
  );
};