import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogOut from '../../assets/images/icons/Logout';
import { useMsal } from "@azure/msal-react";

import './InnerHeaderBlock.css';
import ArrowBack from '../../assets/images/icons/ArrowBack';
import { resetLogin } from '../../slices/adminLoginSlice';
import { useDispatch } from 'react-redux';
interface InnerHeaderBlockProps {
  backtext?: string;
  handleSignOut?: (e: any) => void;
  showSignOut?: boolean;
  heading?: string;
  contant?: any;
  children?: any;
  backLink?: string;
  handleBackBtn?: any;
  contant2?:string
}

export const InnerHeaderBlock: React.FC<InnerHeaderBlockProps> = ({
  backtext,
  showSignOut,
  handleSignOut,
  heading,
  contant,
  children,
  backLink = '/',
  handleBackBtn,
  contant2
}) => {
  const { instance } = useMsal();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className='head-info-block'>
      <section className='back-section'>
        <div>
          {backtext ? (
            <div
              className='back_block'
              onClick={handleBackBtn}
            >
              <ArrowBack />
              <p>{backtext}</p>
            </div>
          ) : null}
        </div>
      </section>
      {heading && <h1>{heading}</h1>}
      <div>
        {contant && <>
          <p>{contant}</p>
          <p>{contant2}</p>
        </>}
        {children}
      </div>
    </div>
  );
};
