import { createSlice } from '@reduxjs/toolkit';
interface IAvatarPayload {
  status: string;
  image: string;
}
const initialState: any = {
  avatar: {}
};

const avatarSlice = createSlice({
  name: 'avatar',
  initialState,
  reducers: {
    addCustomAvatar: (state: any, action: any) => {
      const { payload } = action || {};
      state.avatar = payload;
      sessionStorage.setItem('avatar', JSON.stringify(state.avatar))
    }
  }
})

export const { addCustomAvatar } = avatarSlice.actions;
export default avatarSlice.reducer;