import React, { useState } from 'react'
import { SkeletonLoading } from '../components';

interface FeedbackDemographicStatsProps {
  demographyStatsArray: any;
  isLoader: boolean
}
const count = 9;

export const FeedbackDemographicStats: React.FC<FeedbackDemographicStatsProps> = ({
  demographyStatsArray,
  isLoader
}) => {
  const [isSeeMore, setSeeMore] = useState(false);

  const renderDemographyStatItem = (item: any, idx: number) => {
    return (
      <div className='table-view-card-info' key={idx}>
        {isLoader ? <SkeletonLoading /> : <p>{item.heading}</p>}
        {isLoader ? <SkeletonLoading /> : <p>{item.value}</p>}
      </div>
    )
  };

  const handleSeeMore = () => {
    setSeeMore(!isSeeMore);
  };

  return (
    <>
      {demographyStatsArray && demographyStatsArray.length ?
        <section className='table-view-card-section'>
          {!isSeeMore && demographyStatsArray.length > count ?
            demographyStatsArray.slice(0, count).map((item: any, idx: number) => {
              return renderDemographyStatItem(item, idx);
            }) :
            demographyStatsArray.map((item: any, idx: number) => {
              return renderDemographyStatItem(item, idx);
            })}
        </section> : null}
      {demographyStatsArray && demographyStatsArray.length ?
        <div className='seemore-block'>
          {!isLoader && !isSeeMore && demographyStatsArray.length > count ?
            <button className="clear-link-style" onClick={handleSeeMore}>
              See more
            </button> :
            <button className="clear-link-style" onClick={handleSeeMore}>
              See less
            </button>}
        </div> : null}
    </>
  );
};