import React from 'react';
import QRCode from "react-qr-code";
import { useNavigate } from 'react-router-dom';
import "./qritem.css";

interface QRItemProps {
  data: {
    id: string
    Unit: {
      name: string
    },
    name: string
    created_date: string
    created_time: string
    question_set_for: string
    User: {
      display_name: string
    }
  }
}

const generateQRUrl = (id: string) => {
  return `${window.location.origin}/qr/${id}`;
}

export const QRItem: React.FC<QRItemProps> = ({ data }) => {
  const navigate = useNavigate();

  const handlePrint = (selectedItem: any) => {
    navigate("/print-qr", {
      state: { data: selectedItem },
    });
  };

  return (
    <div className='qr-card-section'>
      <div className='qr-info-section'>
        <p className='qr-label'>
          <span className='qr-title'>Unit name: </span>
          <span className='qr-value'>{data.Unit.name}</span>
        </p>
        <p className='qr-label'>
          <span className='qr-title'>Created on: </span>
          <span className='qr-value'>{data.created_date}</span>
        </p>
        <p className='qr-label'>
          <span className='qr-title'>QR for: </span>
          <span className='qr-value'>{data.question_set_for==="child"?"Service User":"Carers and Support Network"}</span>
        </p>
        <p className='qr-label'>
          <span className='qr-title'>Feedback URL: </span>
          <a className='qr-url' target="_blank" rel="noreferrer" href={generateQRUrl(data.id)}>{generateQRUrl(data.id)}</a>
        </p>
        <div className="action-block">
          {/* <button className="unit-button">Download PDF</button> */}
          <button className="unit-button" onClick={() => handlePrint(data)}>Print</button>
        </div>
      </div>
      <div className='qr-code-section'>
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={generateQRUrl(data.id)}
          viewBox={`0 0 256 256`}
        />
      </div>
    </div>
  );
}