import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { InnerHeaderBlock } from "../../components/innerHeaderBlock/InnerHeaderBlock";
import { PageLayout } from "../../components/PageLayout";
import { Checkbox } from "../../components/checkBox/CheckBox";
import { CustomButton } from "../../components/CustomButton";
import { updateAdminTermsAndCondition } from "../../services/apiService";
import { useSelector, useDispatch } from "react-redux";
import LoginResponse from "../../types/auth";
import { setTermAndCondition } from "../../slices/adminLoginSlice";
import { commissionerTypes } from "../../data/userdata";

interface ReportTermsAndConditionsProps {}

export const ReportTermsAndConditions: React.FC<
  ReportTermsAndConditionsProps
> = () => {
  const [isAgreeTAC, setAgreeTAC] = useState<any>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLoginData: LoginResponse = useSelector(
    (state: any) => state.adminLogin
  );

  useEffect(() => {
    setAgreeTAC(userLoginData.terms_and_condition);
  }, []);
  const handleTermsCondition = (event: any) => {
    const { checked } = event?.target;
    setAgreeTAC(checked);
  };

  const handleClick = async () => {
    if (isAgreeTAC) {
      await updateAdminTermsAndCondition({
        terms_and_condition: isAgreeTAC,
      });
      dispatch(setTermAndCondition(isAgreeTAC));
      navigate("/report-dashboard");
    }
  };

  const handleBackBtn = () => {
    if (userLoginData.isUserLoggedIn && userLoginData.terms_and_condition) {
      navigate(-1);
    } else {
      navigate("/admin");
    }
  };

  return (
    <PageLayout size="lg" role="commissioner">
      {/* <pre>{JSON.stringify(userLoginData, null, 4)}</pre> */}
      <div>
        <InnerHeaderBlock
          backtext="Back"
          showSignOut={userLoginData?.isUserLoggedIn}
          heading="Terms and conditions"
          handleBackBtn={handleBackBtn}
        >
          <div className="head-info">
            <div
              className={
                userLoginData?.role === commissionerTypes.commissioner
                  ? "block"
                  : "hidden"
              }
            >
              <p className=" font-bold">What will you be doing?</p>
              <p>
                The provider site will be able to see an overall assessment of
                the quality of care, whether that be low, satisfactory or good.
              </p>
              <p>
                If a unit is low, this does not always mean that the unit is
                failing to support its service user, carers and/or support networks. It
                may be an indication that further support is required. We trust
                that you will interpret the results of the heatmap alongside
                local knowledge of the individual unit, and escalate any
                concerns within existing quality, risk management and patient
                feedback protocols.
              </p>
              <p>
                The provider site will receive some demographic information from
                service user, carers and support networks which we ask should
                not be shared with parties outside of this project as this has
                not been agreed upon by the service user, carers and support
                networks and would violate their rights.
              </p>
              <p className=" font-bold">Why are we collecting this data?</p>
              <p>
                We are collecting this data to understand where units are
                experiencing high, satisfactory or low-quality of feedback from
                their perspective.
              </p>
              <p className=" font-bold">How do we intend to use this data?</p>
              <p>
                We intend to use this data to highlight and flag the service
                user, carers and support networks perspectives on the quality of
                the care they are receiving through the lens of quality as
                defined by the service user, carers and support networks. We
                hope the data will allow provider sites a unique insight into
                the experiences of service users, and provide an early
                opportunity to use this quality intelligence to support the
                units.
              </p>
            </div>
            <div
              className={
                userLoginData?.role === commissionerTypes.senior
                  ? "block"
                  : "hidden"
              }
            >
              <p className=" font-bold">What will you be doing?</p>
              <p>
                The provider site will be able to see an overall assessment of
                the quality of care, whether that be low, satisfactory or good.
              </p>
              <p>
                If a unit is low, this does not always mean that the unit is
                failing to support its service user, carers and/or support networks. It
                may be an indication that further support is required. We trust
                that you will interpret the results of the heatmap alongside
                local knowledge of the individual unit, and escalate any
                concerns within existing quality, risk management and patient
                feedback protocols.
              </p>
              <p>
                The provider site will receive some demographic information from
                service user, carers and support networks which we ask should
                not be shared with parties outside of this project as this has
                not been agreed upon by the service user, carers and support
                networks and would violate their rights.
              </p>
              <p className=" font-bold">Why are we collecting this data?</p>
              <p>
                We are collecting this data to understand where units are
                experiencing high, satisfactory or low-quality of feedback from
                their perspective.
              </p>
              <p className=" font-bold">How do we intend to use this data?</p>
              <p>
                We intend to use this data to highlight and flag the service
                user, carers and support networks perspectives on the quality of
                the care they are receiving through the lens of quality as
                defined by the service user, carers and support networks. We
                hope the data will allow provider sites a unique insight into
                the experiences of service users, and provide an early
                opportunity to use this quality intelligence to support the
                units.
              </p>
            </div>
          </div>
        </InnerHeaderBlock>
        {userLoginData.isUserLoggedIn ? (
          <>
            <div className="flex justify-center">
              <Checkbox
                type="checkbox"
                label="I agree with the terms and conditions"
                name="checkBoxCard"
                onChange={handleTermsCondition}
                selectedValue={isAgreeTAC}
              />
            </div>
            <div className="text-center max_s:mb-[100px]">
              <CustomButton
                name="Submit"
                hanldeClick={handleClick}
                disable={!isAgreeTAC}
              />
            </div>
          </>
        ) : null}
      </div>
    </PageLayout>
  );
};
