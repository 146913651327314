import React from 'react'
import Loading from './Loading';
import { PageLayout } from './PageLayout';

export const ReportInitialLoader = () => {
  return (
    <PageLayout size="lg" role="commissioner">
      <Loading addclass="inner-loader" />
    </PageLayout>
  );
}