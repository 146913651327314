import React, { ChangeEvent } from "react";
import "../styles/checkbox.css";

interface CheckboxProps {
  disabled?: boolean;
  label?: string;
  name?: string;
  type: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  addClass?: string;
  selectedValue?: boolean;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  onChange,
  onBlur,
  name,
  type,
  addClass = "",
  selectedValue,
}) => {
  const isActive = selectedValue === true ? "active" : "";
  return (
    <div>
      <label className={`tickbox-group ${addClass} ${isActive}`}>
        {label}
        <input
          type={type}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          checked={selectedValue || false}
        />
        <span className="tickmark" />
      </label>
    </div>
  );
};
