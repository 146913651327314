import React from "react";
import "../styles/feedbackPreview.css";
import Iconsvg from "../../components/icon/Icon";
import { useDispatch, useSelector } from "react-redux";
import {
  removeFeedbackStepperCount,
  setCurrentStep,
  setFeedbackPreview,
} from "../../slices/feedbackAnswerSlice";
import { CustomRadioButton } from "../../components";
import { useNavigate } from "react-router-dom";

interface FeedbackPreviewProps {
  activeStep?: any;
  currentStep?: any;
  setEditCount?: any;
}

export const FeedbackPreview: React.FC<FeedbackPreviewProps> = ({
  activeStep,
  setEditCount,
}) => {
  const feedbackQuestionList = useSelector(
    (state: any) => state.feedbackQuestion
  );
  const feedbackAnswerList = useSelector((state: any) => state.feedbackAnswer);

  const { feedbackAnswers, currentStep } = feedbackAnswerList;

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const editHandler = () => {
    activeStep(currentStep);
    dispatch(setFeedbackPreview(false));
  };

  const handlePrevStep = () => {
    activeStep(currentStep);
    dispatch(setFeedbackPreview(false));
  };
  const handleNextStep = () => {
    navigate("/submit");
  };

  const groupByTopic = (questions: any) => {
    return questions.reduce((acc: any, question: any) => {
      (acc[question.topic] = acc[question.topic] || []).push(question);
      return acc;
    }, {});
  };
  const groupedQuestions = groupByTopic(feedbackQuestionList.feedbackQues);

  const getOptionValue = (optionItem: any) => {
    let answer = "";
    if (
      feedbackAnswers &&
      Array.isArray(feedbackAnswers) &&
      feedbackAnswers.length
    ) {
      const answerOption = feedbackAnswers.filter(
        (opt: any) => opt.question === optionItem.id
      );
      answer =
        answerOption && answerOption.length > 0
          ? answerOption[0]["option"]
          : "";
    }
    return answer;
  };
  const renderAnswer = (quesItem: any) => {
    switch (quesItem.type) {
      case "radio": {
        return (
          <CustomRadioButton
            disabled={true}
            type="radio"
            name={quesItem.id}
            data={quesItem.Option.map((opt: any) => ({
              ...opt,
              option_value: opt.id,
              option_label: opt.option_value,
            }))}
            checkedOption={getOptionValue(quesItem)}
            isCircle={true}
            menuType="feedback"
            readOnly={true}
          />
        );
      }
      default:
        const answer = feedbackAnswers.find(
          (item: any) => item.question === quesItem.id
        );
        return (
          <p className="feedback-textarea">
            {answer?.answer}
          </p>
        );
    }
  };
  let questionCounter = 0;
  return (
    <>
      <h1 className="page-head max_md:mt-8">
        Feedback preview
        <p className="sec-header-text">Here’s a preview of your responses.</p>
      </h1>
      <div>
        {Object.entries(groupedQuestions).map(([topic, questions]: any) => (
          <div key={topic} className="feedback-container">
            <p className="headerText">{topic}</p>
            {questions.map((item: any) => {
              questionCounter++;
              return (
                <div key={item.id} className="mb-10">
                  <p>
                    {questionCounter}. {item.question}
                  </p>
                  {renderAnswer(item)}
                </div>
              );
            })}
          </div>
        ))}
      </div>
      <div className="editBtn">
        <button type="button" onClick={editHandler}>
          Edit response
        </button>
      </div>
      <div className="feedback-footer">
        <button
          className="clear-button-style add-transistion"
          onClick={handlePrevStep}
        >
          <Iconsvg name="backButton" />
          Back
        </button>
        <button
          className="clear-button-style add-transistion"
          onClick={handleNextStep}
        >
          <Iconsvg name="nextButton" />
          Next
        </button>
      </div>
    </>
  );
};
