import React from 'react'
import { PageLayout } from '../../components/PageLayout';
import { feedBackContent } from "../../content/feedBack/content";
import { patientTypes } from '../../data/userdata';

export const FeedbackNotAvailable = () => {
  const userRole: string | null = sessionStorage.getItem('role');
  const pilotMessage: any =
    userRole === patientTypes.parent
      ? feedBackContent.parent.noQuestionMessage
      : feedBackContent.child.noQuestionMessage;

  return (
    <PageLayout pageName='question-not-available'>
      <div className="fb-notfound-session dashboard-card">
        <h2 className='fb-notfound-title'>{feedBackContent.pilotTitle}</h2>
        <div dangerouslySetInnerHTML={{ __html: pilotMessage }}></div>
      </div>
    </PageLayout>
  );
}