import React from "react";

const IconAbout: React.FC = () => (
  <svg
    className="fill-buttonColor"
    xmlns="http://www.w3.org/2000/svg"
    width="49.147"
    height="59.829"
    viewBox="0 0 49.147 59.829"
  >
    <path
      id="Path_14904"
      data-name="Path 14904"
      d="M43.9-9.921a71.715,71.715,0,0,0-11.6-7.108,37.994,37.994,0,0,0-12.978-3.7V-46.726a31.124,31.124,0,0,1,13.327,4.232A78.8,78.8,0,0,1,43.9-34.515a78.8,78.8,0,0,1,11.246-7.979,31.124,31.124,0,0,1,13.327-4.232v25.992a38.342,38.342,0,0,0-12.937,3.7A70.908,70.908,0,0,0,43.9-9.921Zm0-37.5a10.66,10.66,0,0,1-7.874-3.228,10.833,10.833,0,0,1-3.2-7.937,10.833,10.833,0,0,1,3.2-7.937A10.66,10.66,0,0,1,43.9-69.75a10.66,10.66,0,0,1,7.874,3.228,10.833,10.833,0,0,1,3.2,7.937,10.833,10.833,0,0,1-3.2,7.937A10.66,10.66,0,0,1,43.9-47.421Z"
      transform="translate(-19.329 69.75)"
    />
  </svg>
);
export default IconAbout;
