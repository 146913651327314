import { useMsal } from '@azure/msal-react';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  Outlet,
  useNavigate,
} from 'react-router-dom';
import { ReportInitialLoader } from '../components/ReportInitialLoader';
import { getTokenVerifyRequest } from '../services/apiService';
import { resetLogin, storeUserAccessLevel } from '../slices/adminLoginSlice';
import { LoginResponse } from '../types';

export const ReportAdmin: React.FC = () => {
  const [authStatus, setAuthStatus] = useState('pending');
  let { accounts } = useMsal();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const userLoginData: LoginResponse = useSelector(
    (state: any) => state.adminLogin
  );
  const { accessToken, isUserLoggedIn } = userLoginData || {};

  // Check user token verification
  const getTokenVerification = async () => {
    console.log('Initializing');
    setAuthStatus('pending');
    try {
      if (!accessToken) {
        accounts = [];
        dispatch(resetLogin());
        return navigate('/admin');
      }
      const tokenResponse: any = await getTokenVerifyRequest();
      if (tokenResponse) {
        dispatch(storeUserAccessLevel(tokenResponse));
        setAuthStatus('success');
      }
    } catch (error) {
      accounts = [];
      dispatch(resetLogin());
      return navigate('/admin');
    }
  };

  useEffect(() => {
    getTokenVerification();
    // eslint-disable-next-line
  }, []);

  if (isUserLoggedIn && authStatus === 'success') {
    return <Outlet />;
  } else if (isUserLoggedIn && authStatus === 'failed') {
    return <Navigate to='/admin' replace />
  } else if (!isUserLoggedIn && authStatus === 'failed') {
    return <Navigate to='/admin' replace />
  } else if (authStatus === 'pending') {
    return <ReportInitialLoader />
  } else {
    return <Navigate to='/admin' replace />
  }
}