import React from 'react';

const ArrowBack: React.FC = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='19'
    height='16'
    viewBox='0 0 19 16'
  >
    <defs>
      <clipPath id='clip-Artboard_1'>
        <rect width='19' height='16' />
      </clipPath>
    </defs>
    <g
      id='Artboard_1'
      data-name='Artboard – 1'
      clipPath='url(#clip-Artboard_1)'
    >
      <path
        id='Path_1'
        data-name='Path 1'
        d='M214.331,176.66h14.2V174.4h-14.2l6.4-6.4h-3.2L210,175.53l7.531,7.531h3.2Z'
        transform='translate(-209.764 -167.53)'
        fill='#1C5DAF'
      />
    </g>
  </svg>
);

export default ArrowBack;
