import React, { useEffect } from "react";
import { PageLayout } from "../../components/PageLayout";
import { CustomButton } from "../../components";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { validateRoleId } from "../../slices/roleSlice";

interface LandingPageProps { }

export const LandingPage: React.FC<LandingPageProps> = () => {
  const navigate: any = useNavigate();
  const dispatch = useDispatch<any>();
  const roleData = useSelector((state: any) => state.roleInfo);
  let { id } = useParams();

  useEffect(() => {
    if (id) {
      sessionStorage.setItem('feedbackId', id);
      const param = { id, type: "demography" };
      dispatch(validateRoleId(param));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (roleData.errorMessage) {
      // navigate("/");
    }
  }, [roleData.errorMessage]);

  const handleClick = () => {
    if (roleData && roleData.userRole && roleData.userRole === "Parent") {
      navigate("/terms-and-conditions/read?demographic='1'");
    } else {
      navigate("/terms-and-conditions/read?demographic='1'");
    }
  };
  return (
    <PageLayout>
      <div className="dashboard-card">
        <div className="comment-box-icon"></div>
        <div className="title-text"></div>
        <h1 className="text-logo mx-auto">Welcome</h1>
        <div className="h-4"></div>
        <div className="text-right"></div>
        <div className="h-6"></div>
        <p className="max-w-[400px] font-semibold	 mx-auto content-text">
          Welcome to Safe2Share, where you can let your voice be heard. Please
          click the start button to get going👋
        </p>
        <div className="h-6"></div>
        <div></div>
        <div className="h-14"></div>
        {roleData?.roleId ? (
          <div className="text-neutral text-center">
            <CustomButton name="Start" hanldeClick={handleClick} />
          </div>
        ) : (
          <div className="invalid-card">
            <p>Please enter valid URL to start your journey</p>
          </div>
        )}
      </div>
    </PageLayout>
  );
};
