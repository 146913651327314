import React, { useEffect } from "react";
import "./fontSize.css";
import useCustomState from "../../utils/useCustomState";

interface FontSizeProps {
  resetAll?: boolean;
  selected?: () => void;
  setColorChange?:any;
  colorChange:boolean
}

type StateValue = {
  counter: number;
  enabled: boolean;
};
export const FontSize: React.FC<FontSizeProps> = ({colorChange,setColorChange, resetAll, selected }) => {
  const setUserPreference = (value: number) => {
    localStorage.setItem("--font-size", value.toString());
  };

  const getUserPreference = () => {
    const pref = localStorage.getItem("--font-size");
    if (!pref) {
      return 0;
    }
    return Number(pref);
  };

  const checkIfEnabledBefore = () => {
    return Boolean(getUserPreference());
  };



  const [state, setState] = useCustomState({
    counter: getUserPreference(),
    enabled: checkIfEnabledBefore(),
  });

  const addFont = ({ bodyClass }: { bodyClass: string }) => {
    document.body.classList.add(bodyClass);
  };
  
  const removeFont = ({ bodyClass }: { bodyClass: string }) => {
    document.body.classList.remove(bodyClass);
  };

  const bodyClassName = "mw-font-a";

  useEffect(() => {
    if (state.counter) {
      addFont({
        bodyClass: bodyClassName,
      });
      setUserPreference(1);
    } else {
      removeFont({
        bodyClass: bodyClassName,
      });
      setUserPreference(0);
    }
  }, [state.enabled, state.counter]);

  useEffect(() => {
    if (resetAll) {
      setState({
        counter: 0,
        enabled: true,
      });
    }
  }, [resetAll]);
  const handleClick = () => {
    setColorChange(!colorChange)
    if (state.counter === 0) {
      setState({
        counter: 1,
        enabled: false,
      });
    } else {
      setState({
        counter: 0,
        enabled: true,
      });
    }
  };
  return (
    <button type="button" onClick={handleClick} className={`${colorChange?"toggel-color-off":"toggel-color"}`}  style={{
      backgroundColor: "transparent",
      border: "none",
    }}>
    Large text
    </button>
  );
};
