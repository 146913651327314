import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  audio: "",
  currentAudio: "",
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    addAudio: (state, action) => {
      const { payload } = action;
      state.audio = payload;
      // sessionStorage.setItem('audio', JSON.stringify(state.audio));
    },
    addCurrentAudio: (state, action) => {
      const { payload } = action;
      state.currentAudio = payload;
      // sessionStorage.setItem('currentAudio', JSON.stringify(state.audio));
    },
  },
});

export const { addAudio, addCurrentAudio } = commonSlice.actions;
export default commonSlice.reducer;
