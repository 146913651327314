import React, { useState } from 'react';
import { Form, Formik } from "formik";
import QRCode from "react-qr-code";

import { PageLayout } from '../../components/PageLayout';
import { CustomButton, SelectDropdown, TextField } from '../../components';
import { cpyData, unitList } from "../../data/userdata";
import { InnerHeaderBlock } from '../../components/innerHeaderBlock/InnerHeaderBlock';
import { useNavigate } from 'react-router-dom';

interface AddQRCodeProps {

}

export const AddQRCode: React.FC<AddQRCodeProps> = ({ }) => {
  const [qrName, setQrName] = useState("");
  const [selectCYP, setSelectCYP] = useState(null);
  const [selectUnit, setSelectUnit] = useState(null);
  const [isSuccessPreview, setSuccessPreview] = useState(false);

  const navigate = useNavigate();

  const handleOnSubmit = () => {
    setSuccessPreview(true)
  }

  const handleBackBtn = () => {
    navigate("/qr");
  }

  return (
    <PageLayout size='lg' role='commissioner'>
      <InnerHeaderBlock
        backtext="Back"
        showSignOut
        handleBackBtn={handleBackBtn}
      />
      <div className="dashboard-block qr-block">
        <div className="title-text"></div>
        <h1 className="text-center">Generate New QR code</h1>
        <div className="h-4"></div>
        <div className="h-4"></div>

        {!isSuccessPreview ?
          <Formik Formik
            enableReinitialize
            initialValues={{}}
            onSubmit={handleOnSubmit}
          >
            {({ setFieldValue, setFieldTouched, touched, errors, values }) => (
              <Form
                onFocus={() => {
                  // setErrMessage("");
                }}
              >
                <section>
                  <>
                    <TextField
                      type="text"
                      addClass="mt-4"
                      label="QR Name"
                      id="qrName"
                      name="qrName"
                      placeholder="Qr name"
                      onChange={(e: any) => {
                        setFieldValue("qrName", e.target.value);
                      }}
                      value={qrName}
                    />
                  </>

                  <>
                    <SelectDropdown
                      type="text"
                      name="cyp"
                      label='Choose CYP'
                      placeholder="Select CYP"
                      optionsArray={cpyData}
                      onChange={(e: any) => {
                        setSelectCYP(e.value);
                      }}
                      value={selectUnit}
                    />
                    <div className="h-4"></div>
                  </>

                  <>
                    <SelectDropdown
                      type="text"
                      name="unit"
                      label='Choose your Unit'
                      placeholder="Select unit"
                      optionsArray={unitList}
                      onChange={(e: any) => {
                        setSelectUnit(e.value);
                      }}
                      value={selectCYP}
                    />
                    <div className="h-4"></div>
                  </>
                  <div className='flex justify-center'>
                    <div className="h-4"></div>
                    <CustomButton
                      name='Submit'
                      type="submit"
                      hanldeClick={() => null}
                    />
                    <div className="h-4"></div>
                  </div>
                </section>
              </Form>
            )}
          </Formik>
          :
          <div>
            <p className='qr-label'>Name: <span>Test user</span></p>
            <p className='qr-label'>Unit: <span>Unit 2</span></p>
            <p className='qr-label'>Url: <span>https://www.google.com/</span></p>
            <div className='qr-preview-block'>
              <QRCode
                size={100}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={"test"}
                viewBox={`0 0 256 256`}
              />
            </div>
          </div>}
      </div>
    </PageLayout >
  );
}