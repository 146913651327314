import React from "react";
import "./styles/toggle.css";

interface ToggleProps {
  inputText1: string;
  inputText2: string;
  type: boolean;
  id: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

export const Toggle: React.FC<ToggleProps> = ({
  inputText1,
  inputText2,
  type,
  id,
  handleChange,
  disabled,
}) => {
  const tabIndex = 0;
  return (
    <div className={`toggle-wrapper ${type ? "" : "w-toggle"}`}>
      <input
        type="radio"
        name="select"
        id={id + "on"}
        className="on-toggle"
        value="on"
        onChange={handleChange}
        defaultChecked
        disabled={disabled}
      />
      <input
        type="radio"
        name="select"
        id={id + "off"}
        className="off-toggle"
        value="off"
        onChange={handleChange}
        disabled={disabled}
      />
      <label
        htmlFor={id + "on"}
        className={`option ${type ? "on-button" : "full-content"} ${
          disabled && "disabled"
        }`}
      >
        <span
          className="input-text"
          tabIndex={tabIndex}
          aria-label={inputText1}
        >
          {inputText1}
        </span>
      </label>
      <label
        htmlFor={id + "off"}
        className={`option ${type ? "off-button" : "part-content"} ${
          disabled && "disabled"
        }`}
      >
        <span
          className="input-text"
          tabIndex={tabIndex}
          aria-label={inputText2}
        >
          {inputText2}
        </span>
      </label>
    </div>
  );
};
