import React from 'react';

const Print: React.FC = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='40'
    height='36'
    viewBox='0 0 40 36'
  >
    <path
      id='print_FILL1_wght400_GRAD0_opsz48'
      d='M35.9,14.1H12.1V6H35.9Zm1.05,9.25a1.538,1.538,0,1,0-1.05-.45A1.439,1.439,0,0,0,36.95,23.35ZM32.9,39V29.4H15.1V39Zm3,3H12.1V33.2H4V20.9a5.209,5.209,0,0,1,5.3-5.3H38.7A5.209,5.209,0,0,1,44,20.9V33.2H35.9Z'
      transform='translate(-4 -6)'
      fill='#2e244e'
    />
  </svg>
);
export default Print;
