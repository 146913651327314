import React from "react";
import { PageLayout } from "../../components/PageLayout";
import { Card } from "../../components/Card";
import { CustomButton } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { patientTypes } from "../../data/userdata";
import { setAnswers } from "../../slices/submitAnswerSlice";
import {
  removeFeedbackStepperCount,
  resetFeedbackAnswer,
  setCurrentStep,
  setFeedbackPreview,
} from "../../slices/feedbackAnswerSlice";
import { resetDemographicAnswer } from "../../slices/demographicAnswerSlice";
import { useNavigate } from "react-router-dom";
import Iconsvg from "../../components/icon/Icon";

interface SubmitFeedbackProps {}

export const SubmitFeedback: React.FC<SubmitFeedbackProps> = ({}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const feedbackAnswerList = useSelector((state: any) => state.feedbackAnswer);
  const roleData = useSelector((state: any) => state.roleInfo);
  const { feedbackAnswers } = feedbackAnswerList;
  const demographicAnswerList = useSelector(
    (state: any) => state.demographicAnswer
  );
  const handleSubmit = () => {
    const resp = [...demographicAnswerList.answers, ...feedbackAnswers];
    const param = { id: roleData.roleId, answersList: resp };
    dispatch(setAnswers(JSON.stringify(param)));
    navigate("/success");
    dispatch(resetFeedbackAnswer());
    dispatch(setCurrentStep(1));
    dispatch(resetDemographicAnswer());
    dispatch(setFeedbackPreview(false));
  };
  const goBackFromSubmit = () => {
    navigate(-1);
    dispatch(removeFeedbackStepperCount());
  };
  const getRenderSubmitContent = () => {
    if (
      roleData &&
      roleData.userRole &&
      roleData.userRole === patientTypes.parent
    ) {
      return (
        <>
          <p className="text-base">
            If your significant other or person you are caring for is feeling
            immediately unsafe on this ward, please alert a member of the ward
            staff to their situation. If you feel unsafe to do this, please
            request to contact their Nurse in Charge, Ward Manager, Matron/Head
            of Nursing or local advocacy service, who will be able to support
            them with their situation. These contact details should be made
            available to you on request.
          </p>
          <br />
          <p className="text-base">
            There is no need to share the answers you have given in Safe2Share,
            but they will be able to offer you support.
          </p>
          <br />
          <p className="text-base">
            When you click ‘submit’ you will not be able to edit your answers.
            Are you happy and ready to submit all your answers now?
          </p>
        </>
      );
    } else {
      return (
        <>
          <p className="text-base">
            If you are feeling immediately unsafe on this ward please alert a
            member of the ward staff to your situation. If you feel unsafe to do
            this, please request to contact your family/carer, Nurse in Charge,
            Ward Manager, Matron/Head of Nursing or local advocacy service, who
            will be able to support you with your situation. These contact
            details should be made available to you on request.
          </p>
          <br />
          <p className="text-base">
            There is no need to share the answers you have given in Safe2Share,
            but they will be able to offer you support.
          </p>
          <br />
          <p className="text-base">
            When you click ‘submit’ you will not be able to edit your answers.
            Are you happy and ready to submit all your answers now?
          </p>
        </>
      );
    }
  };
  return (
    <PageLayout>
      <h1 className="page-head max_md:mt-8">Feedback questions</h1>
      <Card type={true} cardTitle="Submit">
        <div>
          {getRenderSubmitContent()}
          <div className="h-4" />
          <div className="flex justify-center">
            <CustomButton
              id="feedback-submit"
              name="Submit"
              hanldeClick={handleSubmit}
            />
          </div>
          <button
            className="clear-button-style add-transistion absolute bottom-6"
            onClick={goBackFromSubmit}
          >
            <Iconsvg name="backButton" />
            Back
          </button>
        </div>
      </Card>
    </PageLayout>
  );
};
