import React from 'react';

const CalenderDate: React.FC = () => (
  <svg
    id='Group_4268'
    data-name='Group 4268'
    xmlns='http://www.w3.org/2000/svg'
    width='22.439'
    height='21.636'
    viewBox='0 0 22.439 21.636'
  >
    <path
      id='Path_14990'
      data-name='Path 14990'
      d='M103.58,24.982h-1.7v-.567a.9.9,0,0,0-1.8,0v.567H96.116v-.567a.9.9,0,1,0-1.8,0v.567H90.329v-.567a.9.9,0,0,0-.9-.9.877.877,0,0,0-.874.9v.567h-1.7A2.853,2.853,0,0,0,84,27.84V42.3a2.853,2.853,0,0,0,2.858,2.858H103.58a2.853,2.853,0,0,0,2.858-2.858V27.84a2.853,2.853,0,0,0-2.858-2.858ZM86.857,26.753h1.7v.567a.9.9,0,0,0,1.8,0v-.567h3.992v.567a.9.9,0,0,0,1.8,0v-.567h3.992v.567a.9.9,0,1,0,1.8,0v-.567h1.7a1.1,1.1,0,0,1,1.087,1.087v1.677H85.77V27.84a1.084,1.084,0,0,1,1.087-1.087Zm16.723,16.6H86.857a1.1,1.1,0,0,1-1.087-1.087V31.288h18.9V42.295a1.079,1.079,0,0,1-1.087,1.063Z'
      transform='translate(-83.999 -23.517)'
      fill='#0060af'
    />
    <path
      id='Path_14991'
      data-name='Path 14991'
      d='M178.722,254.24h-2.149a.736.736,0,0,0-.732.732v1.653a.736.736,0,0,0,.732.732h2.149a.736.736,0,0,0,.732-.732v-1.653A.721.721,0,0,0,178.722,254.24Z'
      transform='translate(-171.966 -244.509)'
      fill='#0060af'
    />
    <path
      id='Path_14992'
      data-name='Path 14992'
      d='M441.362,254.24h-2.149a.736.736,0,0,0-.732.732v1.653a.736.736,0,0,0,.732.732h2.149a.736.736,0,0,0,.732-.732v-1.653A.721.721,0,0,0,441.362,254.24Z'
      transform='translate(-423.529 -244.508)'
      fill='#0060af'
    />
    <path
      id='Path_14993'
      data-name='Path 14993'
      d='M310.322,254.24h-2.149a.736.736,0,0,0-.732.732v1.653a.736.736,0,0,0,.732.732h2.149a.736.736,0,0,0,.732-.732v-1.653A.751.751,0,0,0,310.322,254.24Z'
      transform='translate(-298.016 -244.509)'
      fill='#0060af'
    />
    <path
      id='Path_14994'
      data-name='Path 14994'
      d='M178.722,374.08h-2.149a.736.736,0,0,0-.732.732v1.653a.736.736,0,0,0,.732.732h2.149a.736.736,0,0,0,.732-.732v-1.653A.736.736,0,0,0,178.722,374.08Z'
      transform='translate(-171.966 -359.294)'
      fill='#0060af'
    />
    <path
      id='Path_14995'
      data-name='Path 14995'
      d='M441.362,374.08h-2.149a.736.736,0,0,0-.732.732v1.653a.736.736,0,0,0,.732.732h2.149a.736.736,0,0,0,.732-.732v-1.653A.736.736,0,0,0,441.362,374.08Z'
      transform='translate(-423.529 -359.294)'
      fill='#0060af'
    />
    <path
      id='Path_14996'
      data-name='Path 14996'
      d='M310.322,374.08h-2.149a.736.736,0,0,0-.732.732v1.653a.736.736,0,0,0,.732.732h2.149a.736.736,0,0,0,.732-.732v-1.653A.769.769,0,0,0,310.322,374.08Z'
      transform='translate(-298.016 -359.294)'
      fill='#0060af'
    />
  </svg>
);

export default CalenderDate;
