import React from "react";
import { PageLayout } from "../../components/PageLayout";

import "../styles/Report.css";
import { InnerHeaderBlock } from "../../components/innerHeaderBlock/InnerHeaderBlock";
import Explore from "../../assets/images/icons/Explore";
import Note from "../../assets/images/icons/Note";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LoginResponse from "../../types/auth";
import QRCodeIcon from "../../assets/images/icons/QRCodeIcon";
import UnitIcon from "../../assets/images/icons/UnitIcon";
import { commissionerTypes } from "../../data/userdata";

interface ReportDashboardProps {}

const seniorCommissionerMenus = [
  { name: "Heat map", value: "heatmap", icon: "explore" },
  { name: "Audit trail", value: "auditTrail", icon: "note" },
  { name: "QR codes", value: "qrCodes", icon: "qrcode" },
  // { name: "Units", value: "unit", icon: "unit" },
];
const commissionerMenus = [
  { name: "Heat map", value: "heatmap", icon: "explore" },
  { name: "Audit trail", value: "auditTrail", icon: "note" },
];
const qrManagerMenus = [
  { name: "QR codes", value: "qrCodes", icon: "qrcode" },
  { name: "Units", value: "unit", icon: "unit" },
];

const icons: any = {
  explore: <Explore />,
  note: <Note />,
  qrcode: <QRCodeIcon />,
  unit: <UnitIcon />,
};

export const ReportDashboard: React.FC<ReportDashboardProps> = () => {
  const navigate = useNavigate();
  const userLoginData: LoginResponse = useSelector(
    (state: any) => state.adminLogin
  );
  const { isUserAdminAccess, isTaskForceGroupUser } = userLoginData;

  let dashboardMenus = [];
  if (isUserAdminAccess) {
    dashboardMenus = seniorCommissionerMenus;
  } else if (isTaskForceGroupUser) {
    dashboardMenus = qrManagerMenus;
  } else {
    dashboardMenus = commissionerMenus;
  }

  const onClickMenuItem = (selectedMenu: any) => {
    switch (selectedMenu) {
      case "heatmap":
        navigate("/feedback-overview");
        break;
      case "auditTrail":
        navigate("/audit-trail");
        break;
      case "qrCodes":
        navigate("/qr");
        break;
      case "unit":
        navigate("/unit");
        break;
      default:
        break;
    }
  };

  return (
    <PageLayout size="lg" role="commissioner">
      <InnerHeaderBlock showSignOut heading="Dashboard" />
      <div className="head-info">
        <p
          className={
            userLoginData?.role === commissionerTypes.commissioner
              ? "block"
              : "hidden"
          }
        >
          You’ve logged in as a provider site staff member. You can access
          the heatmap or audit trail by clicking on the tabs below.
        </p>
        <p
          className={
            userLoginData?.role === commissionerTypes.regional
              ? "block"
              : "hidden"
          }
        >
          As a <span>{userLoginData?.role}</span>. You can access the heatmap or
          audit trail by clicking on the tabs below.
        </p>
        <p
          className={
            userLoginData?.role === commissionerTypes.senior
              ? "block"
              : "hidden"
          }
        >
          You’ve logged in as a provider site staff member. You can access
          the heatmap or audit trail by clicking on the tabs below.
        </p>
      </div>
      <section className="report-card-section">
        {dashboardMenus.map((item: any) => {
          return (
            <div
              className="report-card-block"
              key={`menu-item-${item.value}`}
              onClick={() => onClickMenuItem(item.value)}
            >
              <span>{icons[item.icon]}</span>
              <p>{item.name}</p>
            </div>
          );
        })}
      </section>
    </PageLayout>
  );
};
