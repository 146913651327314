import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface SkeletonLoadingProps {
  count?: number
}

export const SkeletonLoading: React.FC<SkeletonLoadingProps> = ({ count }) => {
  return (
    <div className='skeleton-loading-sections'>
      <Skeleton count={count} />
    </div>
  );
};