import DropDown from "../../assets/images/icons/IconDropdown";
import LeftOutline from "../../assets/images/icons/IconDropdown";
import BackButton from "../../assets/images/icons/IconBackButton";
import NextButton from "../../assets/images/icons/IconNextButton";
import SoundActivated from "../../assets/images/icons/IconSoundActivated";
import SoundNotActivated from "../../assets/images/icons/IconSoundNotActivated";
import SelectorCheckBox from "../../assets/images/icons/IconCheckBox";
import GiveFeedback from "../../assets/images/icons/IconGiveFeedback";
import IconAbout from "../../assets/images/icons/IconAbout";
import IconBackground from "../../assets/images/icons/IconBackground";
import IconSetting from "../../assets/images/icons/IconSetting";

// type ReactSVGComponent = React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;

type IconComponentProps = {id?:number | string; name: string; addClass?: string; };
type IconTypes = { [name: string]: any };

const iconTypes: IconTypes = {
  dropDown: DropDown,
  leftOutline: LeftOutline,
  backButton: BackButton,
  nextButton: NextButton,
  soundActivated: SoundActivated,
  soundNotActivated: SoundNotActivated,
  selectorCheckBox: SelectorCheckBox,
  giveFeedback: GiveFeedback,
  about: IconAbout,
  background: IconBackground,
  setting: IconSetting,
};

const IconComponent = ({id, name, addClass, ...props }: IconComponentProps,) => {
  const Icons = iconTypes[name];
  // console.log(addClass);
  // console.log(name);

  return (
    <div className={`icon-view ${addClass ? addClass : ""}`}>
      {Icons && <Icons {...props} id={id} />}
    </div>
  );
};

export default IconComponent;
