import { createSlice } from '@reduxjs/toolkit';

interface hmFilterOption {
  viewOption: string;
  dateRange: any;
  unitOption: string;
  roleOption: string;
  feedbackView: any;
}

const defaultStartDate = () => {
  const d = new Date();
  d.setDate(d.getDate() - 30);
  return d;
};

const initialState: hmFilterOption = {
  viewOption: "graphview",
  dateRange: {
    startDate: defaultStartDate(),
    endDate: new Date()
  },
  unitOption: "",
  roleOption: "both",
  feedbackView: {
    roleType: "both",
    sortType: "desc"
  }
};


const heapmapFIlterOptionSlice = createSlice({
  name: 'heapmapOptions',
  initialState,
  reducers: {
    setViewOption: (state: any, action: any) => {
      const { payload } = action || {};
      if (payload) {
        return {
          ...state,
          viewOption: payload,
        }
      }
    },
    setDateRangeOption: (state: any, action: any) => {
      const { payload } = action || {};
      if (payload) {
        state.dateRange.startDate = payload.fromDate;
        state.dateRange.endDate = payload.toDate
      }
    },
    setUnitOption: (state: any, action: any) => {
      const { payload } = action || {};
      if (payload) {
        return {
          ...state,
          unitOption: payload.value,
        }
      }
    },
    setRoleOption: (state: any, action: any) => {
      const { payload } = action || {};
      if (payload) {
        return {
          ...state,
          roleOption: payload.value,
        }
      }
    },
    setFeedbackViewRoleType: (state: any, action: any) => {
      const { payload } = action || {};
      if (payload) {
        state.feedbackView.roleType = payload.value;
      }
    },
    setFeedbackViewSortType: (state: any, action: any) => {
      const { payload } = action || {};
      if (payload) {
        state.feedbackView.sortType = payload.value;
      }
    },
    resetFilterOption: (state: any) => {
      return {
        ...state,
        viewOption: "graphview",
        dateRange: {
          startDate: defaultStartDate(),
          endDate: new Date()
        },
        unitOption: "",
        roleOption: "both"
      }
    },
    resetFeedbackview: (state: any) => {
      state.feedbackView.roleType = "both";
      state.feedbackView.sortType = "desc";
    }
  }
})

export const {
  setViewOption,
  setDateRangeOption,
  setUnitOption,
  setRoleOption,
  resetFilterOption,
  setFeedbackViewRoleType,
  setFeedbackViewSortType,
  resetFeedbackview
} = heapmapFIlterOptionSlice.actions;
export default heapmapFIlterOptionSlice.reducer;