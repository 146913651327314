import axios from "../middlewares/axios-interceptor";
import { IFeedbackStatPayload } from "../types/feedback";
const API_URL = `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}`;

interface feedbackStatsPayload {
  start_date: string;
  end_date: string;
  unit_id?: string;
  feedback_for?: string;
}
interface cypFeedbackStatsPayload {
  date: string;
  unit_id?: string
}

interface cypFeedbackAnswersPayload {
  feedback_id: string;
  unit_id: string
}

const buildQueryString = (payload: any) => {
  const esc = encodeURIComponent;
  return Object.keys(payload)
    .map(k => esc(k) + '=' + esc(payload[k]))
    .join('&')
}
/*
  Get the questions by providing your role ID and the type (demographic or feedback).
*/
export const getQuestionsRequest = (payload: any) => {
  const URL = `${API_URL}/questionnaire/${payload?.id || ""}?question_category=${payload?.type}`;
  return axios.get(URL);
};

/*
  Submit answers by providing your role ID.
*/
export const submitAnswersRequest = (payload: any) => axios.post(`${API_URL}/answer/${payload.id}`, payload.answersList);

export const updateAdminTermsAndCondition = (payload: any) => axios.put(`${API_URL}/users/terms-condition`, payload);

export const getUnitListService = () => axios.get(`${API_URL}/unit/unit-list`);

export const getTokenVerifyRequest = () => axios.get(`${API_URL}/users/verify-token`);

export const getDemographyFeedbackStatsService = async (payload: feedbackStatsPayload) => {
  let URL = `${API_URL}/answer/demography-stats?${buildQueryString(payload)}`;
  return await axios.get(URL);
};

export const getFeedbackGraphStatsService = async (payload: feedbackStatsPayload) => {
  let URL = `${API_URL}/answer/feedback-stats-graph?${buildQueryString(payload)}`;
  return await axios.get(URL);
};

export const getFeedbackOverviewService = async (payload: feedbackStatsPayload) => {
  let URL = `${API_URL}/answer/unit?${buildQueryString(payload)}`;
  // if (payload.unit_id) {
  //   URL += `&unit_id=${payload.unit_id}`
  // }
  return await axios.get(URL);
};

export const getFeedbackByDateService = async (payload: cypFeedbackStatsPayload) => {
  let URL = `${API_URL}/answer/feedback-stats?${buildQueryString(payload)}`;
  return await axios.get(URL);
};

export const getQuestionStatsService = async (payload: IFeedbackStatPayload) => {
  let URL = `${API_URL}/answer/question-stats?${buildQueryString(payload)}`;
  return await axios.get(URL);
};

export const getFeedbackByFeedbackIdService = async (payload: cypFeedbackAnswersPayload) => {
  let URL = `${API_URL}/answer/${payload.feedback_id}?${buildQueryString(payload)}`;
  return await axios.get(URL);
};

export const getAuditTrailService = async (payload: any) => {
  let URL = `${API_URL}/audit-trail?${buildQueryString(payload)}`;
  return await axios.get(URL);
};

export const getAllUnitListService = () => axios.get(`${API_URL}/unit`);

export const getUnitByIdService = (unit_id: string) => axios.get(`${API_URL}/unit/${unit_id}`);

export const createUnitService = (payload: any) => axios.post(`${API_URL}/unit`, payload);

export const updateUnitService = (payload: any) => axios.put(`${API_URL}/unit`, payload);

export const getQuestionSetService = async (payload: any) => {
  const URL = `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}/questionnaire/question-set?${buildQueryString(payload)}`;
  return await axios.get(URL);
};

export const getPilotStatusInReport = async () => {
  const PILOT_STATUS_URL = `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}/users/check-site-status`;
  return await axios.get(PILOT_STATUS_URL);
}
export const getDownloadCSV=async(payload:IFeedbackStatPayload)=>{
  let URL = `${API_URL}/answer/download?${buildQueryString(payload)}`;
  return await axios.get(URL);
}
export const getAuditDownloadCSV=async(payload:IFeedbackStatPayload)=>{
  let URL = `${API_URL}/audit-trail/download?${buildQueryString(payload)}`;
  return await axios.get(URL);
}