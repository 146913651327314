import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PageLayout } from "../../components/PageLayout";
import { Card } from "../../components/Card";
import BackButton from "../../components/backButton/BackButton";
import { Toggle } from "../../components/Toggle";
import { addAudio } from "../../slices/commonSlice";
import { Link } from "react-router-dom";
import { FontSize } from "../../components/fontSize/FontSize";

const getUserPreference = () => {
  const pref = localStorage.getItem("--font-size");
  if (!pref) {
    return 0;
  }
  return Number(pref);
};

interface SettingsProps {}

export const Settings: React.FC<SettingsProps> = () => {
  const [soundStatusPage, setsoundStatusPage] = useState(true);
  const dispatch = useDispatch();

  const TurnOnSound = (event: any) => {
    // const SoundStatus = event.target.value;
    dispatch(addAudio("")); // commonData.currentAudio
    if (event.target.value === "on") {
      setsoundStatusPage(true);
    } else {
      setsoundStatusPage(false);
    }
  };
  const [isLargeText, setIsLargeText] = useState<boolean>(false);
const [colorChange,setColorChange]=useState<boolean>(getUserPreference() ? false : true);
  const handleDefaultClick = () => {
    setIsLargeText(!isLargeText);
    setColorChange(true)
  };
  useEffect(() => {
    if (isLargeText) {
      setIsLargeText(false);
    }
  }, [isLargeText]);

  return (
    <PageLayout>
      <div>
        <h1 className="mb-24 text-center">Home</h1>
        <Card cardTitle="Settings" type={true} removeBottomPadding={true}>
          <div>
            <div className="mb-12">
              <p>
                This is the settings page where you can change the platform to
                suit you
              </p>
            </div>
            <div>
              <div className="grid grid-cols-2 items-center mb-14">
                {/* <p>Visual Timer</p>
                <div>
                  {/* <Toggle
                    inputText1="ON"
                    inputText2="OFF"
                    type={true}
                    id="visual-"
                  />
                </div> */}
              </div>
              <div className="grid grid-cols-2 items-start mb-16 max_md:mb-8">
                <p>Text size</p>
                <div>
                  <div>
                    <button
                    className={`${!colorChange?"toggel-color-off":"toggel-color"}`} 
                      onClick={handleDefaultClick}
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      Default
                    </button>

                    <FontSize resetAll={isLargeText} colorChange={colorChange} setColorChange={setColorChange} />
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 items-start mb-28 max_md:mb-8">
                <p>Sounds</p>
                <div>
                  <Toggle
                    inputText1="ON"
                    inputText2="OFF"
                    type={true}
                    id="sounds-"
                    handleChange={TurnOnSound}
                  />
                  {soundStatusPage && (
                    <div className="mt-6">
                      <Link to="/sounds" className="clear-link-style">
                        Select sound
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* <div
              className=" text-center mb-12"
              aria-hidden={selected !== "on" ? true : false}
            >
              <CustomButton
                name="Select Sound"
                hanldeClick={() => {
                  navigate("/sounds");
                }}
              />
            </div> */}
            <div>
              <BackButton
                type={false}
                buttonLabel="Button"
                iconName="backButton"
                buttonContent="Back"
              />
            </div>
          </div>
        </Card>
      </div>
    </PageLayout>
  );
};
