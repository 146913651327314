import React, { useEffect, useState } from "react";
import { PageLayout } from "../../components/PageLayout";
import { useNavigate } from "react-router-dom";
import "../styles/unit.css";
import { InnerHeaderBlock } from '../../components/innerHeaderBlock/InnerHeaderBlock';
import { getAllUnitListService } from "../../services/apiService";
import { ErrorMessage } from '../../components';
import Loading from '../../components/Loading';

const Unit = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [errorState, setErrorState] = useState<any>({
    unitListError: {},
  });

  const navigate: any = useNavigate();
  const handleBackBtn = () => {
    navigate('/report-dashboard');
  };
  const [unitList, setUnitList] = useState([]);

  const getAllUnits = async () => {
    setLoader(true);
    try {
      const unitResponse = await getAllUnitListService();
      setUnitList(unitResponse.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      setErrorState({
        ...errorState,
        unitListError: error
      });
    }
  }

  useEffect(() => {
    getAllUnits()
  }, []);

  return (
    <PageLayout size='lg' role="commissioner">
      <InnerHeaderBlock
        backtext="Back"
        showSignOut
        handleBackBtn={handleBackBtn}
      />
      <div>
        <div className="title-section">
          <h1 className="">Units</h1>
          <p>As a Task force team. You can add, edit and remove units you have been created.</p>
        </div>
        <div className="flex justify-end mb-4">
          <button className="unit-button" onClick={() => navigate("/add-unit")}>
            Add new unit
          </button>
        </div>
        {errorState && errorState.unitListError?.message ?
          <ErrorMessage message={errorState.unitListError?.message} /> :
          loader ?
            <div className="no-data-block">
              <Loading addclass="inner-loader" />
            </div> :
            unitList && unitList.length ?
              unitList.map((unit: any, index: number) => {
                return (
                  <div className='qr-card-section add-unit-section' key={index}>
                    <div className="qr-info-section">
                      <p className='qr-label'>
                        <span className='qr-title'>Unit name: </span>
                        <span className='qr-value'>{unit.name}</span>
                      </p>
                      {/* <p className='qr-label'>
                        <span className='qr-title'>Region name: </span>
                        <span className='qr-value'>{unit.location}</span>
                      </p>
                      <p className='qr-label'>
                        <span className='qr-title'>Unit latitude: </span>
                        <span className='qr-value'>{unit.latitude}</span>
                      </p>
                      <p className='qr-label'>
                        <span className='qr-title'>Unit longitude: </span>
                        <span className='qr-value'>{unit.longitude}</span>
                      </p> */}
                      <div className="action-block">
                        <button className="unit-button" onClick={() => navigate(`/unit/${unit.id}`)}>Edit</button>
                      </div>
                    </div>
                  </div>
                )
              }) :
              <div className="no-data-block">
                <p>No unit available. Please create new unit</p>
              </div>}
      </div>
    </PageLayout>
  );
};

export default Unit;
