import React from "react";

const Avatar = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70.289"
      height="69"
      viewBox="0 0 70.289 69"
    >
      <path
        id="Path_14707"
        data-name="Path 14707"
        d="M25.92-11.135a47.552,47.552,0,0,1,11.432-5.4A41.813,41.813,0,0,1,49.739-18.3a41.813,41.813,0,0,1,12.387,1.766,47.552,47.552,0,0,1,11.432,5.4,30.656,30.656,0,0,0,6.773-9.975,31.231,31.231,0,0,0,2.434-12.365,30.977,30.977,0,0,0-9.633-22.963A32.152,32.152,0,0,0,49.739-65.9a32.152,32.152,0,0,0-23.392,9.457,30.977,30.977,0,0,0-9.633,22.963,31.231,31.231,0,0,0,2.434,12.365A30.656,30.656,0,0,0,25.92-11.135ZM49.739-28.7A12.165,12.165,0,0,1,40.9-32.278a11.721,11.721,0,0,1-3.648-8.679A11.721,11.721,0,0,1,40.9-49.635a12.165,12.165,0,0,1,8.841-3.581,12.165,12.165,0,0,1,8.841,3.581,11.721,11.721,0,0,1,3.648,8.679,11.721,11.721,0,0,1-3.648,8.679A12.165,12.165,0,0,1,49.739-28.7Zm0,29.722A35.443,35.443,0,0,1,36.031-1.629,34.876,34.876,0,0,1,24.813-9.005,34.157,34.157,0,0,1,17.3-20.018a33.693,33.693,0,0,1-2.7-13.457,33.587,33.587,0,0,1,2.7-13.507,34.307,34.307,0,0,1,7.515-10.963,34.876,34.876,0,0,1,11.219-7.377,35.443,35.443,0,0,1,13.708-2.653A35.331,35.331,0,0,1,63.5-65.322a35.022,35.022,0,0,1,11.168,7.377,34.308,34.308,0,0,1,7.515,10.963,33.587,33.587,0,0,1,2.7,13.507,33.693,33.693,0,0,1-2.7,13.457A34.157,34.157,0,0,1,74.666-9.005,35.022,35.022,0,0,1,63.5-1.629,35.331,35.331,0,0,1,49.739,1.025Z"
        transform="translate(-14.595 67.975)"
        fill="#fff"
      />
    </svg>
  );
};

export default Avatar;
