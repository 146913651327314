import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import '../styles/pdf.css';
import { PageLayout } from '../../components/PageLayout';
import Print from '../../assets/images/icons/Print';

import NHSLogo from '../../assets/images/nhs-logo.png';
import ArrowBack from '../../assets/images/icons/ArrowBack';
import { useSelector } from 'react-redux';
import MVFooter from '../../assets/images/powered-by-mindwave.png';


export const PrintPDF = () => {
  const navigate = useNavigate();
  const roleData = useSelector((state: any) => state.roleInfo);

  const feedbackQuestionList = useSelector(
    (state: any) => state.feedbackQuestion
  );
  const { feedbackQues, role, unit } = feedbackQuestionList || {};
  const today = new Date();
  const formattedtoday =
    today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();

  const printhandler = () => window.print();

  useEffect(() => {
    if (feedbackQues && feedbackQues.length) {
      setTimeout(() => {
        printhandler();
      }, 1000);
    }

    if (roleData && !roleData.roleId) {
      navigate('/');
    }
    // eslint-disable-next-line
  }, []);

  const handleBackBtn = () => {
    navigate(-1);
  };

  return (
    <PageLayout size='lg'>
      <div className='print-head-icon-block'>
        <section className='back-section'>
          <div>
            <div className='back_block' onClick={handleBackBtn}>
              <ArrowBack />
              <p>Back</p>
            </div>
          </div>
        </section>
        <div className='relative'>
          <span className='print-icon-btn' onClick={() => printhandler()}>
            <Print />
          </span>
        </div>
      </div>
      <section className='pdf_section'>
        <div>
          <table>
            <thead>
              <tr>
                <th>
                  {' '}
                  <div className='h-[45px]'></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <header className='pdf_header'>
                    <h1>Safe2Share</h1>
                    <div className='output-group'>
                      <p>Date: </p>
                      <p>{formattedtoday || ''}</p>
                    </div>
                    <div className='output-group'>
                      <p>Unit: </p>
                      <p>{unit || ''}</p>
                    </div>
                    <div className='output-group'>
                      <p>Role: </p>
                      <p>{role || ''}</p>
                    </div>
                  </header>
                  <main className='pdf_body'>
                    <div className='print-main-section'>
                      <div className='m-auto'>
                        <h1 className='text-logo mx-auto mb-5'>Safe2Share</h1>
                        <p className='max-w-[400px] font-semibold	 mx-auto content-text'>
                          Welcome to the Safe2Share, where you can let your
                          voice be heard. Please turn the page to answer some
                          questions about your experience in your unit.
                        </p>
                      </div>
                    </div>
                    <div className='responses-view-block'>
                      <div className='view-data-group'>
                        <p className='pdf-info-card'>
                          On a scale of 1 to 5, please choose a number which best describes your thoughts
                        </p>
                      </div>
                      {/* Render feedback question list */}
                      {feedbackQues && feedbackQues.length
                        ? feedbackQues.map((item: any, index: number) => (
                          <div className='view-data-group'>
                            <label className='form-label'>
                              {index + 1}. {item.question}
                            </label>
                            <div className='ques-group-block'>
                              {item.Option.map((optionItem: any) => (
                                <span
                                  className='ques-checkbox'
                                  ques-value={optionItem.option_value}
                                >
                                  <>{optionItem.option_text}</>
                                </span>
                              ))}
                            </div>
                            <div className='hr-line'></div>
                          </div>
                        ))
                        : null}
                    </div>
                  </main>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <footer className='pdf_footer_block last-page-only'>
                    <div className='mindwave-print-footer'>
                      <img src={MVFooter} alt='Mindwave Logo' />
                    </div>
                    <div className='pdf_footer'>
                      <img src={NHSLogo} alt='nhs logo' />
                    </div>
                  </footer>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </section>
    </PageLayout>
  );
};
