import React from 'react';
import { Provider } from 'react-redux';
import store from './store';
import { AppRoutes } from './routes/AppRoutes';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
import { TracksPlayer } from './components/TracksPlayer';

const msalInstance = new PublicClientApplication(msalConfig);

function App() {

  return (
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <div className='App'>
          <AppRoutes />
          <TracksPlayer />
        </div>
      </Provider>
    </MsalProvider>
  );
}

export default App;
