import React, { useEffect } from 'react';
import { PageLayout } from '../../components/PageLayout';
import { CustomButton, ErrorMessage } from '../../components';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import { userLogin, resetLogin } from '../../slices/adminLoginSlice';
import { useDispatch, useSelector } from 'react-redux';

import "../styles/Report.css";

interface LoginResponse {
  isUserLoggedIn: boolean;
  accessToken: string;
  refreshToken: string;
  isLoader: boolean;
  error: string;
  terms_and_condition: boolean;
}

interface LandingPageProps {}

export const LandingPageAdmin: React.FC<LandingPageProps> = () => {
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const userLoginData: LoginResponse = useSelector(
    (state: any) => state.adminLogin
  );

  const handleLogin = async () => {
    try {
      await instance.loginRedirect(loginRequest);
    } catch (error) {
      console.log("error...", error);
    }
  };

  const handleLogout = async () => {
    try {
      await instance.logoutRedirect({
        postLogoutRedirectUri: "/admin",
      });
      dispatch(resetLogin());
      navigate("/admin");
    } catch (error) {
      console.log("error...", error);
    }
  };

  const getAzureAccessToken = async () => {
    try {
      if (!accounts.length) {
        return;
      }
      const tokenResponse = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      const loginPayload = {
        access_token: tokenResponse.accessToken,
        id_token: tokenResponse.idToken,
      };
      await dispatch(userLogin(loginPayload));
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getAzureAccessToken();
    // eslint-disable-next-line
  }, [accounts]);

  useEffect(() => {
    if (userLoginData.isUserLoggedIn) {
      if (!userLoginData.terms_and_condition) {
        return navigate("/admin/terms-and-conditions");
      }
      return navigate("/report-dashboard");
    }
    // eslint-disable-next-line
  }, [userLoginData]);

  return (
    <PageLayout size='lg' role='commissioner'>
      <div className='dashboard-block'>
        {userLoginData && userLoginData.error ?
          <ErrorMessage message={userLoginData?.error} /> : null}
        <h1>WELCOME</h1>
        <p>
          Welcome to the <span>Safe2Share</span>, where you can view information
          about <span>Service User</span> and <span>Carers and Support Network</span> regarding the
          quality of care they experience in their units.
        </p>
        <div className="text-center">
          <p className="mb-5">Please select login to continue</p>
          {userLoginData.isUserLoggedIn === true ? (
            <CustomButton name="Logout" hanldeClick={handleLogout} />
          ) : (
            <CustomButton name="Login" hanldeClick={handleLogin} />
          )}
        </div>
      </div>
    </PageLayout>
  );
};
