import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import { getPilotStatusInReport } from '../services/apiService';

export const PilotCheckInReport = () => {
  const navigate = useNavigate();

  const getPilotStatus = async () => {
    try {
      const resp: any = await getPilotStatusInReport();
      if (resp && !resp.commissionerPortalStatus) {
        return navigate('/report-pilot-closed');
      }
    } catch (error) {
      return;
    }
  }
  useEffect(() => {
    getPilotStatus();
    // eslint-disable-next-line
  }, [])

  return (
    <Outlet />
  );
}