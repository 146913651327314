import "./soundSelector.css";
import Iconsvg from "../../components/icon/Icon";
import { useSelector } from "react-redux";

type Props = {
  checked: any;
  disable?: boolean;
  iconShow?: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  userData: any;
  play: (value: any) => void;
  pause: (value: any) => void;
  playing: any;
};

const SoundSelector = (props: Props) => {
  const {
    checked,
    disable,
    iconShow,
    handleChange,
    userData,
    play,
    pause,
    playing,
  } = props;
  const tabIndex = 0;

  // const [playing, setPlaying] = useState<any>({});

  // const audioRef: any = useRef({});

  // const play = (item: any) => {
  //   const play: any = {};
  //   for (var key of Object.keys(audioRef.current)) {
  //     audioRef.current[key].pause();
  //     play[key] = false;
  //   }
  //   audioRef.current[item.value].loop = true;
  //   audioRef.current[item.value].play();
  //   play[item.value] = true;
  //   setPlaying(play);
  // };

  // const pause = (item: any) => {
  //   const play: any = {};
  //   for (var key of Object.keys(audioRef.current)) {
  //     audioRef.current[key].pause();
  //     play[key] = false;
  //   }
  //   setPlaying(play);
  // };

  // useEffect(() => {
  //   const play: any = {};
  //   for (let i = 0; i < userData.length; i++) {
  //     play[userData[i].value] = false;
  //     audioRef.current[userData[i].value] = new Audio(userData[i].audio);
  //   }
  //   setPlaying(play);
  // }, []);
  const audioList = useSelector((state: any) => state.common);

  return (
    <fieldset className="reset-fieldset sound-radio-container">
      {userData &&
        userData.map((item: any, idx: number) => {
          return (
            <div className="sound-radio-wapper" key={`sound-item-${idx}`}>
              <div>{item.label}</div>
              <div className="inner-sound-container ">
                <div className="sound-icon">
                  {/* <MusicCard song={item.audio} /> */}
                  <div className="music-card">
                    <div className="music-card_image-con">
                      <button
                        className="bg-transparent border-none"
                        onClick={() => {
                          if (playing[item.value]) {
                            pause(item);
                          } else {
                            play(item);
                          }
                        }}
                      >
                        {playing[item.value] ? (
                          <Iconsvg name={"soundActivated"} />
                        ) : (
                          <Iconsvg name={"soundNotActivated"} />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <label className="sound-radio">
                    <input
                      tabIndex={tabIndex}
                      disabled={disable}
                      onChange={handleChange}
                      type="radio"
                      name={item.label}
                      value={item.value}
                      checked={
                        checked
                          ? checked === item.value
                          : audioList.audio === item.audio
                      }
                      readOnly
                    />
                    <span className={`label-text`}>
                      {audioList.audio === item.audio ? "Selected" : "Select"}
                    </span>
                    {/* ${iconShow ? "icon-label" : ""} */}
                  </label>
                </div>
              </div>
            </div>
          );
        })}
    </fieldset>
  );
};

export default SoundSelector;
