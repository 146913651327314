import React from "react";
import { CustomButton } from "../../components";
import { Card } from "../../components/Card";
import { PageLayout } from "../../components/PageLayout";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading from "../../components/Loading";
import { patientTypes } from "../../data/userdata";
import { feedBackContent } from "../../content/feedBack/content";

export const SuccessPage = () => {
  const navigate = useNavigate();
  const answerResponse = useSelector((state: any) => state.setAnswersList);
  const { isError, message, isLoader } = answerResponse;

  const roleData = useSelector((state: any) => state.roleInfo);
  const successMessage: any =
    roleData.userRole === patientTypes.parent
      ? feedBackContent.parent.feedbackSuccessMsg
      : feedBackContent.child.feedbackSuccessMsg;

  const handleSubmit = () => {
    navigate("/dashboard");
  };

  return (
    <>
      <PageLayout>
        <Card
          type={true}
          cardTitle={isLoader ? "" : isError ? "Error" : "Success"}
        >
          {isLoader ? (
            <div className="flex justify-center">
              <Loading />
            </div>
          ) : (
            <div className="card-content">
              <div className='success-blk'>
                <React.Fragment>
                  {!isError ?
                    <div dangerouslySetInnerHTML={{ __html: successMessage }}></div>
                    : message}
                </React.Fragment>
                <div className="text-center">
                  <CustomButton
                    name="Back to dashboard"
                    hanldeClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
          )}
        </Card>
      </PageLayout>
    </>
  );
};
