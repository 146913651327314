import React from "react";
import { ReactComponent as CheckSVG } from "../../assets/images/icons/checked.svg";
import "./avatarSelector.css";

type Props = {
  disable?: boolean;
  iconShow?: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  userdata: any;
  checkedOption: any
};

export const AvatarSelector = (props: Props) => {
  const { checkedOption, disable, iconShow, handleChange, userdata } = props;

  const tabIndex = 0;
  return (
    <fieldset className="reset-fieldset avatar-selector-container">
      {userdata &&
        userdata.map((item: any, idx: number) => (
          <div className="avatar-selector-wapper" key={`avatar-item-${idx}`}>
            <label className="avatar-selector">
              <input
                tabIndex={tabIndex}
                disabled={disable}
                onChange={handleChange}
                type="radio"
                name={item.label}
                value={item.value}
                checked={checkedOption && checkedOption.status === item.value}
                readOnly
              />
              <div className={`label-text ${iconShow ? "icon-label" : ""}`}>
                <img src={item.imageUrl} alt="AvatarImg" />
                <div className={checkedOption && checkedOption.status === item.value ? "ticked-img" : ""}>
                  {checkedOption && checkedOption.status === item.value ? (
                    <div>
                      <CheckSVG />
                    </div>
                  ) : null}
                </div>
              </div>
            </label>
          </div>
        ))}
    </fieldset>
  );
};
