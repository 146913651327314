import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { PageLayout } from "../../components/PageLayout";
import "../styles/Report.css";
import { useParams } from "react-router";
import Table from "../../components/table/Table";
import { InnerHeaderBlock } from "../../components/innerHeaderBlock/InnerHeaderBlock";
import { getFeedbackByFeedbackIdService } from "../../services/apiService";
import { convertCypFeedbackQuestions } from "../../utils/DataConvertion";
import Loading from "../../components/Loading";
import { ErrorMessage } from "../../components";
import {
  ICYPDemographyFeedback,
  IFeedbackAnswerList,
} from "../../types/feedback";

interface FeedbackInfoProps {}

export const FeedbackInfo: React.FC<FeedbackInfoProps> = () => {
  const initDemographyData: ICYPDemographyFeedback = {
    ethnicity: "-",
    age: "-",
    gender: "-",
    cyp_id: "-",
    sex: "-",
  };
  const [feedbackTableData, setFeedbackTableData] = useState<
    IFeedbackAnswerList[]
  >([]);
  const [feedbackDemographyData, setFeedbackDemographyData] =
    useState<ICYPDemographyFeedback>(initDemographyData);
  const [isFeedbackInfoLoader, setFeedbackInfoLoader] =
    useState<boolean>(false);
  const [errorState, setErrorState] = useState<any>({
    cypQuestionErr: {},
  });

  const navigate = useNavigate();
  const { unit_id, feedback_id } = useParams();

  const getCypFeedbackANswers = async () => {
    setFeedbackInfoLoader(true);
    try {
      let reqPayload: any = {
        unit_id: unit_id,
        feedback_id: feedback_id,
      };
      const tableViewData = await getFeedbackByFeedbackIdService(reqPayload);
      const convertedQuestionObj: any = convertCypFeedbackQuestions(
        tableViewData.data
      );

      setFeedbackTableData(convertedQuestionObj.feedbackList);
      setFeedbackDemographyData(convertedQuestionObj.demographyData);
      setFeedbackInfoLoader(false);
    } catch (error) {
      setFeedbackInfoLoader(false);
      setErrorState({
        ...errorState,
        cypQuestionErr: error,
      });
    }
  };
  useEffect(() => {
    getCypFeedbackANswers();
    // eslint-disable-next-line
  }, []);
  
  const columns = [
    {
      name: "Question",
      selector: (row: any) => `${row.question}`,
      sortable: true,
      minWidth: "120px",
      warp: false,
      cell: (row: any) => <div tabIndex={0}>{`${row.id}. ${row.question}`}</div>,
      sortField: "unit",
    },
    {
      name: "Date",
      selector: (row: any) => `${row.date}`,
      sortable: true,
      minWidth: "120px",
      cell: (row: any) => <div tabIndex={0}>{`${row.date}`}</div>,
      sortField: "date",
    },
    {
      name: "Answer",
      selector: (row: any) => `${row.answer}`,
      sortable: true,
      minWidth: "160px",
      cell: (row: any) => <div tabIndex={0}>{`${row.answer}`}</div>,
      sortField: "submissioncount",
    },
  ];
  const handleBackBtn = () => {
    navigate(-1);
  };
  return (
    <PageLayout size="lg" role="commissioner">
      <div>
        <InnerHeaderBlock
          backtext="Back"
          backLink="/feedback-overview"
          showSignOut
          heading="Heat map data"
          handleBackBtn={handleBackBtn}
        >
          <div className="head-info">
            <p>This is where you can view individual submissions</p>
          </div>
        </InnerHeaderBlock>
        <div className="feedback-info-block">
          <h1>Submission ID: {feedbackDemographyData.cyp_id}</h1>
          <p>Demographic information</p>
          <div className="feedback-info">
            <p>
              <span>Age: </span>
              {feedbackDemographyData.age}
            </p>
            <p>
              <span>Gender: </span>
              {feedbackDemographyData.gender}
            </p>
            <p>
              <span>Ethnicity: </span>
              {feedbackDemographyData.ethnicity}
            </p>
            {/* <p>
              <span>Sex: </span>
              {feedbackDemographyData.sex}
            </p> */}
          </div>
        </div>
        <div className="h-6" />
        <section>
          {errorState &&
          errorState.cypQuestionErr &&
          errorState.cypQuestionErr.message ? (
            <ErrorMessage message={errorState.cypQuestionErr?.message} />
          ) : isFeedbackInfoLoader ? (
            <div className="no-data-block">
              <Loading addclass="inner-loader" />
            </div>
          ) : (
            <Table
              columns={columns}
              data={feedbackTableData}
              showPagination={false}
            />
          )}
        </section>
      </div>
    </PageLayout>
  );
};
