import React from "react";

const IconSoundActivated: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27.589"
    height="29.506"
    viewBox="0 0 27.589 29.506"
  >
    <g id="Bold-S" transform="translate(-10.159 71.72)">
      <path
        id="Path_14855"
        data-name="Path 14855"
        d="M27.54-43.133v-3.679a9.683,9.683,0,0,0,5.067-3.8,10.761,10.761,0,0,0,1.958-6.318A10.514,10.514,0,0,0,32.59-63.2a10.551,10.551,0,0,0-5.05-3.878V-70.76a12.423,12.423,0,0,1,7.336,4.917,14.526,14.526,0,0,1,2.872,8.876,14.678,14.678,0,0,1-2.854,8.9A12.335,12.335,0,0,1,27.54-43.133ZM10.159-51.769v-10.4h6l8.747-9.555v29.506l-8.747-9.555ZM27.54-50.451V-63.485a7.635,7.635,0,0,1,2.926,2.639A6.885,6.885,0,0,1,31.6-56.968a6.885,6.885,0,0,1-1.135,3.878A7.635,7.635,0,0,1,27.54-50.451Z"
      />
    </g>
  </svg>
);
export default IconSoundActivated;
