import React, { useEffect, useState } from "react";

import { ErrorMessage, SelectDropdown } from "../../components";
import { PageLayout } from "../../components/PageLayout";
import { AuditTrailOrder, commissionerTypes } from "../../data/userdata";

import Table from "../../components/table/Table";
import { InnerHeaderBlock } from "../../components/innerHeaderBlock/InnerHeaderBlock";
import {
  getAuditDownloadCSV,
  getAuditTrailService,
} from "../../services/apiService";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import { useSelector } from "react-redux";
import LoginResponse from "../../types/auth";
import { CSVLink } from "react-csv";

interface AuditTrailProps {}

export const AuditTrail: React.FC<AuditTrailProps> = () => {
  const navigate = useNavigate();
  const [orderOptions, setOrderOptions] = React.useState("desc");
  const [auditTrailArray, setAuditTrailArray] = React.useState<any>([]);
  const [downloadCSV, setDownloadCSV] = useState<any>([]);
  const [perPageCount, setPerPageCount] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalRows, setTotalRows] = React.useState(0);
  const [audiTrailLoader, setAuditrailLoader] = React.useState(false);
  const [errorState, setErrorState] = React.useState<any>({});

  const userLoginData: LoginResponse = useSelector(
    (state: any) => state.adminLogin
  );

  const getAuditTrail = async () => {
    setAuditrailLoader(true);
    const reqPayload = {
      sort_by: orderOptions,
      page: currentPage,
      per_page: perPageCount,
    };
    try {
      const auditTrailResponse = await getAuditTrailService(reqPayload);
      setAuditTrailArray(auditTrailResponse.data.auditTrailArray);
      setTotalRows(auditTrailResponse.data.count);
      setAuditrailLoader(false);
    } catch (error: any) {
      setAuditrailLoader(false);
      setErrorState(error);
    }
  };

  useEffect(() => {
    getAuditTrail();
  }, [orderOptions, perPageCount, currentPage, totalRows]);
  const columns = [
    {
      name: "Date",
      selector: (row: any) => `${row.date}`,
      sortable: true,
      minWidth: "120px",
      cell: (row: any) => <div tabIndex={0}>{`${row.date}`}</div>,
      sortField: "date",
    },
    {
      name: "Time",
      selector: (row: any) => `${row.time}`,
      sortable: true,
      minWidth: "160px",
      cell: (row: any) => <div tabIndex={0}>{`${row.time}`}</div>,
      sortField: "submissioncount",
    },
    {
      name: "Submission ID",
      selector: (row: any) => `${row.cyp_id}`,
      sortable: false,
      minWidth: "220px",
      center: false,
      cell: (row: any) => <div tabIndex={0}>{`${row.cyp_id}`}</div>,
    },
    {
      name: "Viewed by",
      selector: (row: any) => `${row.view_by}`,
      sortable: false,
      allowOverflow: true,
      minWidth: "150px",
      cell: (row: any) => <div tabIndex={0}>{`${row.view_by}`}</div>,
    },
  ];

  const handleBackBtn = () => {
    navigate(-1);
  };
  return (
    <PageLayout size="lg" role="commissioner">
      <div className="relative">
        <InnerHeaderBlock
          backtext="Back"
          backLink="/report-dashboard"
          showSignOut
          heading="Audit trail"
          handleBackBtn={handleBackBtn}
        >
          <div>
            <p>
              You can view an audit trail that shows a time log of what the
              staff in your provider sites have viewed and when.
            </p>
          </div>
        </InnerHeaderBlock>
        <section className="select-group-section">
          <div className="select-group-block">
            <SelectDropdown
              addclass="secondary-select"
              type="text"
              name="view"
              placeholder="Order"
              optionsArray={AuditTrailOrder}
              onChange={(e: any) => {
                setOrderOptions(e.value);
              }}
              value={orderOptions}
            />
          </div>
        </section>
        <div className="h-6" />
        <section>
          {errorState && errorState.message ? (
            <ErrorMessage message={errorState.message} />
          ) : (
            <Table
              progressPending={audiTrailLoader}
              page={currentPage}
              columns={columns}
              data={auditTrailArray}
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={(count: number) => {
                setPerPageCount(count);
              }}
              onChangePage={(pageNumber: number) => {
                setCurrentPage(pageNumber);
              }}
            />
          )}
        </section>
      </div>
    </PageLayout>
  );
};
