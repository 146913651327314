import React from "react";

interface IconNextButtonProps {
  id:string
}


export const IconNextButton: React.FC<IconNextButtonProps> = ({id = ''}) => {
    return (
      <svg
      id={id} 
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <g id="Thin-M" transform="translate(-15 80.23)">
        <path
          data-name="Path 14679"
          d="M35-52.9l7.333-7.333L35-67.563l-1.556,1.556,4.667,4.667H27.222v2.222H38.111l-4.667,4.667ZM35-40.23a19.353,19.353,0,0,1-7.8-1.582,20.318,20.318,0,0,1-6.333-4.28,20.318,20.318,0,0,1-4.28-6.333A19.353,19.353,0,0,1,15-60.23a19.369,19.369,0,0,1,1.582-7.807,20.352,20.352,0,0,1,4.28-6.333A20.337,20.337,0,0,1,27.2-78.648,19.353,19.353,0,0,1,35-80.23a19.369,19.369,0,0,1,7.807,1.582A20.371,20.371,0,0,1,49.14-74.37a20.372,20.372,0,0,1,4.278,6.333A19.369,19.369,0,0,1,55-60.23a19.353,19.353,0,0,1-1.582,7.8,20.337,20.337,0,0,1-4.278,6.333,20.352,20.352,0,0,1-6.333,4.28A19.369,19.369,0,0,1,35-40.23Zm0-2.222a17.158,17.158,0,0,0,12.611-5.167A17.158,17.158,0,0,0,52.778-60.23a17.158,17.158,0,0,0-5.167-12.611A17.158,17.158,0,0,0,35-78.008a17.158,17.158,0,0,0-12.611,5.167A17.158,17.158,0,0,0,17.222-60.23a17.158,17.158,0,0,0,5.167,12.611A17.158,17.158,0,0,0,35-42.452Z"
          transform="translate(0 0)"
        />
      </g>
    </svg>
    );
}



export default IconNextButton;
