import React from "react";

interface AverageAssessmentProps {
  status?: string;
  average: number;
}

export const AverageAssessment: React.FC<AverageAssessmentProps> = ({
  average,
}) => {
  return (
    <div className="w-full" tabIndex={0}>
      {average <= 1 ? (
        <div className="circle-bock">
          <div className="circle avg-low" />
          <p>Low</p>
        </div>
      ) : average <= 2 ? (
        <div className="circle-bock">
          <div className="circle avg-Sub" />
          <p>Moderate</p>
        </div>
      ) : average <= 3 ? (
        <div className="circle-bock">
          <div className="circle avg-good" />
          <p>Satisfactory</p>
        </div>
      ) : (
        <div className="circle-bock">
          <div className="circle avg-great" />
          <p>Good</p>
        </div>
      )}
    </div>
  );
};
