import React from "react";
import { PageLayout } from "../../components/PageLayout";
import { Card } from "../../components/Card";
import "../styles/dashboard.css";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Iconsvg from "../../components/icon/Icon";
import { useSelector } from "react-redux";
import { feedBackContent } from "../../content/feedBack/content";
import { patientTypes } from "../../data/userdata";

interface DashboardProps {}

export const Dashboard: React.FC<DashboardProps> = () => {
  const navigate: any = useNavigate();
  const location = useLocation();
  const roleData = useSelector((state: any) => state.roleInfo);
  const dashboardContent =
    roleData.userRole === patientTypes.parent
      ? feedBackContent.parent.dashboard
      : feedBackContent.child.dashboard;
  return (
    <PageLayout>
      <div className="dashboard-section">
        <h1 className="dashboardHeader">Home</h1>
        <Card cardTitle="Welcome! 👋" type={true}>
          <div>
            <p className="text-base">
              {dashboardContent &&
                dashboardContent.map((item: any, idx: number) => (
                  <p className="mb-6" key={`about-item-${idx}`}>
                    {item.name}
                    <Link
                      to="/about"
                      className="font-bold text-primaryContent p-1"
                    >
                      Read more
                    </Link>
                  </p>
                ))}
            </p>
          </div>
          <div className="inner-card-wapper">
            <div
              className="m-auto"
              onClick={() =>
                navigate("/feedback", {
                  state: { previousPathname: location?.pathname },
                })
              }
            >
              <div className="inner-card">
                <Iconsvg name="giveFeedback" />
                <p className="dashboardCards">Start feedback</p>
              </div>
            </div>
            {roleData &&
            roleData.userRole &&
            roleData.userRole === "Parent" ? null : (
              <div className="m-auto" onClick={() => navigate("/settings")}>
                <div className="inner-card">
                  <Iconsvg name="setting" />
                  <p>Settings</p>
                </div>
              </div>
            )}
            {roleData &&
            roleData.userRole &&
            roleData.userRole === "Parent" ? null : (
              <div className="m-auto" onClick={() => navigate("/background")}>
                <div className="inner-card">
                  <Iconsvg name="background" />
                  <p>Background</p>
                </div>
              </div>
            )}
            <div className="m-auto" onClick={() => navigate("/about")}>
              <div className="inner-card">
                <Iconsvg name="about" />
                <p>About</p>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </PageLayout>
  );
};
