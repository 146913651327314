import { useState } from "react";

const isFunction = (functionToCheck: any) => {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === "[object Function]"
  );
};

const mergeState = (prevState: any, newValues: any) => ({
  ...prevState,
  ...newValues,
});

const useCustomState = (initialState: any) => {
  const [state, setValue] = useState(initialState);

  const setState = (cb: any) => {
    setValue((prevState: any) => {
      const values = isFunction(cb) ? cb(prevState) : mergeState(prevState, cb);
      return values;
    });
  };

  return [state, setState];
};

export default useCustomState;
