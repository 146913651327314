import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageLayout } from "../../components/PageLayout";
import { Card } from "../../components/Card";
import SoundSelector from "../../components/soundSelector/SoundSelector";
import { CustomButton } from "../../components";
import BackButton from "../../components/backButton/BackButton";
import { addAudio, addCurrentAudio } from "../../slices/commonSlice";
import { useNavigate } from "react-router-dom";
const rain = require("../../assets/audio/rain.mp3");
const thunder = require("../../assets/audio/thunder.mp3");
const sandyBeach = require("../../assets/audio/sandy-beach.mp3");

type OptionsProps = {
  label: string;
  value: string;
  audio: any;
};
const soundOptions: OptionsProps[] = [
  {
    label: "Rain",
    value: "rain",
    audio: rain,
  },
  {
    label: "Thunder",
    value: "thunder",
    audio: thunder,
  },
  {
    label: "Sandy beach",
    value: "sandy-beach",
    audio: sandyBeach,
  },
];
export const Sounds: React.FC = () => {
  const commonValue = useSelector((state: any) => state.common);
  const [soundRadio, setSoundRadio] = useState();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [playing, setPlaying] = useState<any>({});

  const audioRef: any = useRef({});
  const applyRef = useRef(false);

  const play = (item: any) => {
    dispatch(addAudio(""));
    const play: any = {};
    for (var key of Object.keys(audioRef.current)) {
      audioRef.current[key].pause();
      play[key] = false;
    }
    audioRef.current[item.value].loop = true;
    audioRef.current[item.value].play();
    play[item.value] = true;
    setPlaying(play);
  };

  const pause = () => {
    const play: any = {};
    for (var key of Object.keys(audioRef.current)) {
      audioRef.current[key].pause();
      play[key] = false;
    }
    setPlaying(play);
  };

  useEffect(() => {
    const play: any = {};
    for (let i = 0; i < soundOptions.length; i++) {
      play[soundOptions[i].value] = commonValue.audio === soundOptions[i].audio;
      audioRef.current[soundOptions[i].value] = new Audio(
        soundOptions[i].audio
      );
    }
    dispatch(addCurrentAudio(commonValue.audio));
    setPlaying(play);
    return () => {
      pause();
      if (!applyRef.current) {
        // console.log("inside condition");
        dispatch(addAudio(commonValue.audio));
      }
    };
    // eslint-disable-next-line
  }, []);

  // const addAudio = (srcValue: string) => {
  //   const findAudioTag = document.getElementById("bg-audio");
  //   findAudioTag?.setAttribute("src", srcValue);
  // };

  const soundRadioChange = (event: any) => {
    // console.log("This is after clicked submit", soundRadio);
    const value = soundOptions.find((item) => item.value === soundRadio);
    dispatch(addAudio(value ? value.audio : ""));
    dispatch(addCurrentAudio(value ? value.audio : ""));
    applyRef.current = true;
    for (var key of Object.keys(audioRef.current)) {
      audioRef.current[key].pause();
    }
    history(-1);
  };
  const handleClick = (event: any) => {
    setSoundRadio(event.target.value);
  };
  return (
    <PageLayout>
      <div>
        <h1 className="mb-24 text-center">Home</h1>
        <Card cardTitle="Sound" type={true} removeBottomPadding={true}>
          <div>
            <p>
              Please select a sound you would like to listen to whilst you use
              this tool.
            </p>
          </div>
          <div className="mt-20">
            <SoundSelector
              userData={soundOptions}
              iconShow
              checked={soundRadio}
              handleChange={handleClick}
              pause={pause}
              play={play}
              playing={playing}
            />
          </div>
          <div className="text-center mt-[120px] mb-[34px] soundApplyBtn">
            <CustomButton
              name="Apply"
              hanldeClick={soundRadioChange}
              disable={!soundRadio} />
          </div>
          <div>
            <BackButton
              type={false}
              buttonLabel="Button"
              iconName="backButton"
              buttonContent="Back"
            />
          </div>
        </Card>
      </div>
    </PageLayout>
  );
};
