import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { usePopper } from "react-popper";

import Close from "../../assets/images/icons/Close";
import {
  ErrorMessage,
  SelectDropdown,
  SkeletonLoading,
} from "../../components";
import { PageLayout } from "../../components/PageLayout";
import { AuditTrailCatList } from "../../data/userdata";
import Table from "../../components/table/Table";
import { InnerHeaderBlock } from "../../components/innerHeaderBlock/InnerHeaderBlock";
import {
  getDownloadCSV,
  getFeedbackByDateService,
  getQuestionStatsService,
  getUnitListService,
} from "../../services/apiService";
import { setFeedbackViewRoleType } from "../../slices/heapmapFIlterOptionSlice";
import { DatePicker } from "../../components/datepicker/DatePicker";
import {
  IFeedbackViewResponse,
  IUnitList,
  IFeedbackStatPayload,
  ICYPQuestionStats,
} from "../../types/feedback";
import { CSVLink } from "react-csv";

interface FeedbackViewProps {}

export const FeedbackView: React.FC<FeedbackViewProps> = () => {
  const filterOptions = useSelector((state: any) => state.heapMapOptions);
  const { feedbackView, dateRange, roleOption } = filterOptions || {};

  const [cypFeedbackArray, setCypFeedbackArray] = useState<
    IFeedbackViewResponse[]
  >([]);
  const [perPageCount, setPerPageCount] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [feedbackViewLoader, setFeedbackViewLoader] = useState<boolean>(false);
  const [patOption, setpatOption] = useState<string>("both");
  const [selectedUnitOption, setSelectedUnitOption] = useState<string>("");
  const [startDate, setStartDate] = useState(dateRange.startDate);
  const [endDate, setEndDate] = useState(dateRange.endDate);
  const [unitDropDownList, setUnitDropDownList] = useState<IUnitList[]>([]);
  const [questionStats, setQuestionStats] = useState<ICYPQuestionStats[]>([]);
  const questionKeys: any = {
    1: "Care and Treatment",
    2: "Care and Treatment",
    3: "Medication",
    4: "Staff",
    5: "Staff",
    6: "Therapy and Activities",
    7: "Environment",
    8: "Environment",
    9: "Diversity",
    10: "Diversity",
    11: "General feedback",
  };

  const [showHeatMapPopup, setShowHeatMapPopup] = useState<boolean>(false);
  const [selectedQuestion, setSelectedQuestion] = useState({
    id: "",
    question: "",
    info: "",
  });
  const [isFeedbackStatsLoader, setFeedbackStatsLoader] =
    useState<boolean>(false);

  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const [errorState, setErrorState] = useState<any>({
    unitError: {},
    questionStatsErr: {},
    cypFeedbackTableErr: {},
    downloadErr:{}
  });

  const { unit_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const search = useLocation().search;
  // const date: any = new URLSearchParams(search).get("date");

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: "arrow", options: { element: arrowElement } }],
  });

  const getUnits = async () => {
    try {
      const unitListResponse = await getUnitListService();
      setUnitDropDownList(unitListResponse.data);
      if (unitListResponse.data.length > 0) {
        setSelectedUnitOption(unit_id || "");
      }
    } catch (error) {
      setErrorState({
        ...errorState,
        unitError: error,
      });
    }
  };

  const getQuestionStats = async () => {
    setFeedbackStatsLoader(true);
    try {
      if (selectedUnitOption === "") {
        return;
      }
      let reqPayload: IFeedbackStatPayload = {
        start_date: startDate,
        end_date: endDate,
        per_page: perPageCount,
        page: currentPage,
        // sort_by: feedbackView.sortType,
        feedback_for: patOption,
      };
      if (selectedUnitOption !== "allunits") {
        reqPayload.unit_id = selectedUnitOption;
      }
      const tableViewData = await getQuestionStatsService(reqPayload);
      setQuestionStats(tableViewData.data);
      setFeedbackStatsLoader(false);
    } catch (error) {
      setFeedbackStatsLoader(false);
      setErrorState({
        ...errorState,
        questionStatsErr: error,
      });
    }
  };

  const onChangeDate = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const getCypFeedbackList = async () => {
    setFeedbackViewLoader(true);
    try {
      if (selectedUnitOption === "") {
        return;
      }
      let reqPayload: any = {
        start_date: startDate,
        end_date: endDate,
        per_page: perPageCount,
        page: currentPage,
        // sort_by: feedbackView.sortType,
        feedback_for: patOption,
      };
      if (selectedUnitOption !== "allunits") {
        reqPayload.unit_id = selectedUnitOption;
      }
      const tableViewData = await getFeedbackByDateService(reqPayload);

      setCypFeedbackArray(tableViewData.data.feedback);
      setTotalRows(tableViewData.data.count);
      setFeedbackViewLoader(false);
    } catch (error) {
      setFeedbackViewLoader(false);
      setErrorState({
        ...errorState,
        cypFeedbackTableErr: error,
      });
    }
  };
  useEffect(() => {
    setpatOption(roleOption);
    const categoryRole: any =
      AuditTrailCatList.find((item: any) => item.value === roleOption) || {};
    dispatch(setFeedbackViewRoleType(categoryRole));
    getUnits();
    getQuestionStats();
  }, []);

  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      getCypFeedbackList();
      getQuestionStats();
    }
    // eslint-disable-next-line
  }, [
    endDate,
    perPageCount,
    currentPage,
    feedbackView,
    selectedUnitOption,
    patOption,
  ]);

  const renderPopovercontent = (questionObj: any, cypid: string) => {
    if (
      showHeatMapPopup &&
      selectedQuestion &&
      selectedQuestion.id === cypid &&
      selectedQuestion.question === questionObj.key
    ) {
      const answerGroup = () => {
        switch (typeof questionObj.answer) {
          case "string": {
            return (
              <p>
                General feedback : <br />
                <span className="general-feedback-answer">{`${questionObj.answer}`}</span>
              </p>
            );
          }
          default: {
            return (
              <>
                <p>Answer: {`${questionObj.answer}`}/4</p>
                <p className="capitalize">{`${questionObj.key}`}</p>
                <p className="info-text">{`${questionObj.question}`}</p>
              </>
            );
          }
        }
      };
      return (
        <>
          <button
            type="button"
            className="popper-btn"
            ref={setReferenceElement}
          />
          <div
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className="heatmap-popup"
          >
            {answerGroup()}
            <span
              className="heatmap-close"
              onClick={() => setShowHeatMapPopup(!showHeatMapPopup)}
            >
              <Close />
            </span>
            <div
              className="popper-arrow"
              ref={setArrowElement}
              style={styles.arrow}
            />
          </div>
        </>
      );
    } else {
      return null;
    }
  };
  const getColor = (average: number) => {
    if (average <= 1) {
      return "low-heat-map";
    } else if (average <= 2) {
      return "mid-heat-map";
    } else if (average <= 3) {
      return "good-heat-map";
    } else if (average <= 4) {
      return "great-heat-map";
    } else {
      return "feedback-heat-map";
    }
  };
  const getRowCell = (row: any, questionKey: string) => {
    const questionObj = row?.answers
      ? row.answers.find((options: any) => options.key === questionKey)
      : null;
    if (questionObj) {
      const average = questionObj ? questionObj.answer : 0;
      const colorClass = getColor(average);
      const colorIdentification = `square ${colorClass}`;
      // const colorIdentification1 = `square ${average <= 2
      //     ? "low-heat-map"
      //     : average <= 4
      //       ? "mid-heat-map"
      //       : "good-heat-map"
      //   }`;
      return (
        <div className="w-full relative" tabIndex={0}>
          <div className="circle-bock">
            <div
              onClick={() => handleQuestionClick(row.cyp_id, questionObj)}
              className={colorIdentification}
            ></div>
            <div>{renderPopovercontent(questionObj, row.cyp_id)}</div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="w-full relative" tabIndex={0}>
          <div className="circle-bock">
            <div onClick={() => null} className="square no-answer"></div>
          </div>
        </div>
      );
    }
  };
  const handleQuestionClick = (cypId: string, questionObj: any) => {
    if (questionObj) {
      setShowHeatMapPopup(true);
      const obj: any = {
        id: cypId,
        question: questionObj.key,
        info: questionObj.question,
      };
      setSelectedQuestion(obj);
    } else {
      return null;
    }
  };
  const columns = [
    {
      name: "Submission ID",
      sortable: true,
      minWidth: "150px",
      warp: false,
      cell: (row: any) => (
        <div className="flex gap-6">
          <span
            className="click_menu"
            onClick={() => navigate(`/feedback-info/${row.unit_id}/${row.id}`)}
          >
            {`${row.cyp_id}`}
          </span>
        </div>
      ),
      sortField: "cyp_id",
    },
    {
      name: "Date",
      sortable: true,
      minWidth: "100px",
      cell: (row: any) => <div tabIndex={0}>{`${row.date}`}</div>,
      sortField: "date",
    },
    {
      name: "Q1",
      sortable: false,
      minWidth: "60px",
      maxWidth: "61px",
      center: true,
      cell: (row: any) => getRowCell(row, "q1"),
    },
    {
      name: "Q2",
      sortable: false,
      minWidth: "60px",
      maxWidth: "61px",
      center: true,
      cell: (row: any) => getRowCell(row, "q2"),
    },
    {
      name: "Q3",
      sortable: false,
      minWidth: "60px",
      maxWidth: "61px",
      center: true,
      cell: (row: any) => getRowCell(row, "q3"),
    },
    {
      name: "Q4",
      sortable: false,
      minWidth: "60px",
      maxWidth: "61px",
      center: true,
      cell: (row: any) => getRowCell(row, "q4"),
    },
    {
      name: "Q5",
      sortable: false,
      minWidth: "60px",
      maxWidth: "61px",
      center: true,
      cell: (row: any) => getRowCell(row, "q5"),
    },
    {
      name: "Q6",
      sortable: false,
      minWidth: "60px",
      maxWidth: "61px",
      center: true,
      cell: (row: any) => getRowCell(row, "q6"),
    },
    {
      name: "Q7",
      sortable: false,
      minWidth: "60px",
      maxWidth: "61px",
      center: true,
      cell: (row: any) => getRowCell(row, "q7"),
    },
    {
      name: "Q8",
      sortable: false,
      minWidth: "60px",
      maxWidth: "61px",
      center: true,
      cell: (row: any) => getRowCell(row, "q8"),
    },
    {
      name: "Q9",
      sortable: false,
      minWidth: "60px",
      maxWidth: "61px",
      center: true,
      cell: (row: any) => getRowCell(row, "q9"),
    },
    {
      name: "Q10",
      sortable: false,
      minWidth: "25px",
      maxWidth: "61px",
      center: true,
      cell: (row: any) => getRowCell(row, "q10"),
    },
    {
      name: "Q11",
      sortable: false,
      minWidth: "25px",
      maxWidth: "61px",
      center: true,
      cell: (row: any) => getRowCell(row, "q11"),
    },
  ];
  const handleBackBtn = () => {
    // dispatch(resetFeedbackview());
    navigate(-1);
  };
  const selectedUnitName: any =
    unitDropDownList && unitDropDownList.length
      ? unitDropDownList.find((item: any) => item.value === selectedUnitOption)
      : {};
  return (
    <PageLayout size="lg" role="commissioner">
      <div>
        <div className="relative">
          <InnerHeaderBlock
            backtext="Back"
            handleBackBtn={handleBackBtn}
            backLink="/report-dashboard"
            showSignOut
            heading={`${selectedUnitName?.label} overview`}
            contant="This is where you can view individual submissions and see their overall assessments based on their answers."
          />
        </div>
        <section className="select-group-section">
          <div className="select-group-block">
            <SelectDropdown
              addclass="secondary-select"
              type="text"
              name="unit"
              placeholder="Select"
              optionsArray={unitDropDownList}
              onChange={(e: any) => setSelectedUnitOption(e.value)}
              value={selectedUnitOption}
            />
            <div className="rang-date-block ">
              <DatePicker
                id="dob"
                dateFormat="dd/MM/yyyy"
                className="form-control text-field w-full date-field"
                name="dob"
                dropdownMode="select"
                maxDate={new Date()}
                selectsRange={true}
                onChange={onChangeDate}
                placeholderText="Start Date"
                selected={startDate}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                monthsShown={2}
              />
            </div>
            <SelectDropdown
              addclass="secondary-select"
              type="text"
              name="unit"
              placeholder="Select"
              optionsArray={AuditTrailCatList}
              onChange={(e: any) => setpatOption(e.value)}
              value={patOption}
            />
            <div />
          </div>
          {/* <div className="select-group-block">
            <SelectDropdown
              addclass="secondary-select"
              type="text"
              name="unit"
              placeholder="Select"
              optionsArray={AuditTrailCatList}
              onChange={(e: any) => setpatOption(e.value)}
              value={patOption}
            />
          </div> */}
        </section>
        {errorState && errorState.questionStatsErr?.message ? (
          <ErrorMessage message={errorState.questionStatsErr?.message} />
        ) : (
          <section className="table-headmap-info">
            {questionStats && questionStats.length
              ? questionStats.map((item: any, idx: number) => (
                  <div
                    className="table-heatmap-card-info"
                    key={`question-stat-${idx}`}
                  >
                    {isFeedbackStatsLoader ? (
                      <SkeletonLoading />
                    ) : (
                      <p>{`Q${item.question_number} - ${
                        questionKeys[item.question_number]
                      }`}</p>
                    )}
                    {isFeedbackStatsLoader ? (
                      <SkeletonLoading />
                    ) : (
                      <div className="headmap-circle-block">
                        {/* <span
                        className={`circle inline-block ${item.feedback_status === "Low"
                          ? "low-heat-map"
                          : item.feedback_status === "Satisfactory"
                            ? "mid-heat-map"
                            : "good-heat-map"
                          }`}
                      ></span> */}
                        <span
                          className={`circle inline-block ${
                            item.feedback_average === null
                              ? "great-heat-map"
                              : getColor(item.feedback_average)
                          }`}
                        ></span>
                        <p>{`${
                          item.percentage === "NaN" ? "" : item.percentage + "%"
                        }`}</p>
                      </div>
                    )}
                  </div>
                ))
              : null}
          </section>
        )}
        <section className="table-heatmap-view">
          {errorState && errorState.cypFeedbackTableErr?.message ? (
            <ErrorMessage message={errorState.cypFeedbackTableErr?.message} />
          ) : (
            <Table
              className={cypFeedbackArray.length <= 3 ? "table-visible" : ""}
              progressPending={feedbackViewLoader}
              page={currentPage}
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={(count: number) => setPerPageCount(count)}
              onChangePage={(pageNumber: number) => setCurrentPage(pageNumber)}
              columns={columns}
              data={cypFeedbackArray}
            />
          )}
        </section>
      </div>
    </PageLayout>
  );
};
