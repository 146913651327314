import React from "react";
const IconBackground: React.FC = () => (
  <svg
    className=" fill-buttonColor"
    xmlns="http://www.w3.org/2000/svg"
    width="51.673"
    height="52.098"
    viewBox="0 0 51.673 52.098"
  >
    <g id="Ultralight-S" transform="translate(-17.595 86.975)">
      <path
        id="Path_14836"
        data-name="Path 14836"
        d="M40.274-15.877a24.586,24.586,0,0,1-9.958-2.041,26.173,26.173,0,0,1-8.173-5.57,26.293,26.293,0,0,1-5.524-8.278,25.618,25.618,0,0,1-2.024-10.161,25.537,25.537,0,0,1,2.024-10.2A25.9,25.9,0,0,1,22.26-60.4a25.944,25.944,0,0,1,8.524-5.57,27.279,27.279,0,0,1,10.425-2,26.848,26.848,0,0,1,9.611,1.729A25.7,25.7,0,0,1,58.8-61.5a23.044,23.044,0,0,1,5.446,7.1,19.662,19.662,0,0,1,2.024,8.865,16.136,16.136,0,0,1-3.541,9.923q-3.541,4.67-10.313,4.67H47.435A5.964,5.964,0,0,0,43.348-29.3a5.363,5.363,0,0,0-1.827,4.158q0,2.512,1.165,2.787t1.169,3.02a4.073,4.073,0,0,1-.975,2.116A3.062,3.062,0,0,1,40.274-15.877ZM23.623-41.143a2.528,2.528,0,0,0,1.83-.821,2.568,2.568,0,0,0,.814-1.845,2.568,2.568,0,0,0-.814-1.845,2.528,2.528,0,0,0-1.83-.821,2.528,2.528,0,0,0-1.83.821,2.568,2.568,0,0,0-.814,1.845,2.568,2.568,0,0,0,.814,1.845A2.528,2.528,0,0,0,23.623-41.143Zm9.338-11.3a2.528,2.528,0,0,0,1.83-.821,2.568,2.568,0,0,0,.814-1.845,2.568,2.568,0,0,0-.814-1.845,2.528,2.528,0,0,0-1.83-.821,2.528,2.528,0,0,0-1.83.821,2.568,2.568,0,0,0-.814,1.845,2.568,2.568,0,0,0,.814,1.845A2.528,2.528,0,0,0,32.961-52.441Zm14.941,0a2.528,2.528,0,0,0,1.83-.821,2.568,2.568,0,0,0,.814-1.845,2.568,2.568,0,0,0-.814-1.845,2.528,2.528,0,0,0-1.83-.821,2.528,2.528,0,0,0-1.83.821,2.568,2.568,0,0,0-.814,1.845,2.568,2.568,0,0,0,.814,1.845A2.528,2.528,0,0,0,47.9-52.441Zm9.338,11.3a2.528,2.528,0,0,0,1.83-.821,2.568,2.568,0,0,0,.814-1.845,2.568,2.568,0,0,0-.814-1.845,2.528,2.528,0,0,0-1.83-.821,2.528,2.528,0,0,0-1.83.821,2.568,2.568,0,0,0-.814,1.845,2.568,2.568,0,0,0,.814,1.845A2.528,2.528,0,0,0,57.24-41.143Z"
        transform="translate(3 -19)"
      />
    </g>
  </svg>
);
export default IconBackground;
