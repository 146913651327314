import React from 'react'
import '../components/styles/errorMessage.css';

interface ErrorMessageProps {
  message: string;
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ message = "Something went wrong" }) => {
  return (
    <div className='error-block'>
      <h3>Error</h3>
      <p>{message}</p>
    </div>
  );
};