import React, { useEffect, useState, useCallback } from "react";
import "./components/styles/skin-selection.css";

type TTheme = {
  id: number;
  name: string;
  theme: string;
};

interface ThemeProps {}

export const Theme: React.FC<ThemeProps> = () => {
  const [allThemes] = useState<TTheme[]>([
    {
      id: 1,
      name: "Default",
      theme: "theme-a",
    },
    {
      id: 2,
      name: "Dark",
      theme: "theme-b",
    },
    {
      id: 3,
      name: "Calm mood",
      theme: "theme-c",
    },
    {
      id: 5,
      name: "Dyslexia",
      theme: "theme-e",
    },
    {
      id: 4,
      name: "Visibility",
      theme: "theme-d",
    },
    {
      id: 6,
      name: "Autism and Dyslexia",
      theme: "theme-f",
    },
  ]);

  // this tracks the whole theme object
  const [selectedTheme, setSelectedTheme] = useState<TTheme>(getDefaultTheme());

  // todo: move this to a separate util
  function getDefaultTheme(): TTheme {
    // theme id is stored in local storage so a browser refresh can restore it later
    let themeId = allThemes[0]["id"];
    let validThemeIds = allThemes.map((t) => t.id);
    let _lsThemeId = localStorage.getItem("theme");
    if (_lsThemeId) {
      if (validThemeIds.includes(Number(_lsThemeId))) {
        themeId = Number(_lsThemeId);
      }
    }
    return allThemes.find((t) => t.id === themeId) || allThemes[0];
  }

  const clearThemes = useCallback(() => {
    allThemes.forEach((t) => document.body.classList.remove(t.theme));
  }, [allThemes]);

  function setTheme(theme: TTheme) {
    document.body.classList.add(theme["theme"]);
    localStorage.setItem("theme", String(theme["id"]));
  }

  useEffect(() => {
    if (selectedTheme) {
      clearThemes();
      setTheme(selectedTheme);
    }
  }, [selectedTheme, clearThemes]);

  return (
    <>
      <fieldset className="reset-fieldset theme-wrap">
        <legend className="hidden">Themes</legend>
        {allThemes &&
          allThemes.map((item) => (
            <label key={item.id} className="theme-radio">
              <input
                type="radio"
                checked={item["id"] === selectedTheme["id"]}
                id={`theme--${item.id}`}
                name="site-theme"
                value={item.theme}
                onChange={() => setSelectedTheme(item)}
              />
              <span></span>
              <div className="hidden">{selectedTheme["name"]}</div>
            </label>
          ))}
        <p className="theme-name">
          <>
            <strong
              className={` ${selectedTheme["id"] === 1 ? "block" : "hidden"}`}
            >
              Theme : Default
            </strong>
            <strong
              className={` ${selectedTheme["id"] === 2 ? "block" : "hidden"}`}
            >
              Theme : Dark
            </strong>
            <strong
              className={` ${selectedTheme["id"] === 3 ? "block" : "hidden"}`}
            >
              Theme : Calm mood
            </strong>
            <strong
              className={` ${selectedTheme["id"] === 4 ? "block" : "hidden"}`}
            >
              Theme : Visibility
            </strong>
            <strong
              className={` ${selectedTheme["id"] === 5 ? "block" : "hidden"}`}
            >
              Theme : Dyslexia
            </strong>
            <strong
              className={` ${selectedTheme["id"] === 6 ? "block" : "hidden"}`}
            >
              Theme : Autism and Dyslexia
            </strong>
          </>
        </p>
      </fieldset>
    </>
  );
};