import React, { CSSProperties } from 'react';

import './Datepicker.css';

import ReactDatePicker from 'react-datepicker';
// https://reactdatepicker.com/
import 'react-datepicker/dist/react-datepicker.min.css';
import CalenderDate from '../../assets/images/icons/CalenderDate';

interface DatePickerProps {
  selected?: Date | null | undefined;
  startDate?: Date | null | undefined;
  endDate?: Date | null | undefined;
  selectsStart?: boolean | undefined;
  selectsEnd?: boolean | undefined;
  dateFormat?: string | string[] | undefined;
  className?: string | undefined;
  id?: string;
  name?: string | undefined;
  dropdownMode?: 'select' | 'scroll' | undefined;
  showYearDropdown?: boolean | undefined;
  showMonthDropdown?: boolean | undefined;
  maxDate?: Date | null | undefined;
  minDate?: Date | null | undefined;
  showTimeSelect?: boolean;
  showTimeSelectOnly?: boolean;
  timeIntervals?: number | undefined;
  filterTime?: any;
  onChange(
    date: [Date | null, Date | null],
    event: React.SyntheticEvent<any, Event> | undefined
  ): void;

  placeholderText?: string | undefined;
  isIconVisible?: boolean;
  autoComplete?: 'on' | 'off' | undefined;
  label?: string;
  excludeTimes?: any;
  selectsRange?: true | undefined;
  monthsShown?: number | undefined;
}

const styles = {
  dateIcon: {
    right: '10px',
    position: 'absolute',
    top: '0px',
    'margin-top': '-5px',
    'z-index': '10',
    display: 'flex',
    height: '100%',
    'align-items': 'center',
    'pointer-events': 'none',
  } as CSSProperties,
};

export const DatePicker: React.FC<DatePickerProps> = ({
  selected,
  startDate,
  endDate,
  selectsStart,
  selectsEnd,
  dateFormat,
  className,
  id,
  name,
  dropdownMode,
  showYearDropdown,
  showMonthDropdown,
  maxDate,
  showTimeSelect,
  showTimeSelectOnly,
  timeIntervals,
  autoComplete,
  minDate,
  onChange,
  filterTime,
  isIconVisible,
  placeholderText,
  label,
  excludeTimes,
  selectsRange,
  monthsShown,
}) => {
  const ExampleCustomInput: React.FunctionComponent = ({ ...props }) => {
    return (
      <>
        <input
          id=''
          type='text'
          inputMode='none'
          autoComplete={autoComplete}
          className={className}
          {...props}
        />
        <div style={styles.dateIcon}>
          <CalenderDate />
        </div>
      </>
    );
  };

  return (
    <>
      <div className='input-group'>
        <div className='flex'>{label && <label>{label}</label>}</div>
        <ReactDatePicker
          dateFormat={dateFormat}
          className={className}
          id={id}
          name={name}
          dropdownMode={dropdownMode}
          showYearDropdown={showYearDropdown}
          showMonthDropdown={showMonthDropdown}
          maxDate={maxDate}
          autoComplete={autoComplete}
          excludeTimes={excludeTimes}
          minDate={minDate}
          filterTime={filterTime}
          onChange={onChange}
          timeIntervals={timeIntervals}
          showTimeSelectOnly={showTimeSelectOnly}
          showTimeSelect={showTimeSelect}
          placeholderText={placeholderText}
          selected={selected}
          startDate={startDate}
          endDate={endDate}
          selectsStart={selectsStart}
          selectsEnd={selectsEnd}
          customInput={isIconVisible && <ExampleCustomInput />}
          selectsRange={selectsRange}
          monthsShown={monthsShown}
        />
      </div>
    </>
  );
};
