import React from "react";

const IconSoundNotActivated: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.407"
    height="31.955"
    viewBox="0 0 17.407 31.955"
  >
    <g id="Ultralight-S" transform="translate(-33.531 51.207)">
      <path
        id="Path_14880"
        data-name="Path 14880"
        d="M33.531-30.889v-8.682h8.552l8.855-11.636v31.954L42.083-30.889Z"
      />
    </g>
  </svg>
);
export default IconSoundNotActivated;
