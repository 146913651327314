import React from "react";
import "../components/styles/customRadioButton.css";

interface CustomRadioButtonProps {
  type: string;
  name: string;
  data: any;
  checkedOption?: string;
  handleOnChange?: any;
  isCircle?: boolean;
  menuType?: string;
  readOnly?: boolean;
  disabled?: boolean;
}

export const CustomRadioButton: React.FC<CustomRadioButtonProps> = ({
  type = "radio",
  name,
  data,
  checkedOption = "",
  handleOnChange,
  isCircle = false,
  menuType,
  readOnly,
  disabled,
}) => {
  return (
    <div className={isCircle ? "round-block" : "checkbox-block"}>
      {data.map((item: any, idx: number) => {
        const { id, option_value, option_text, option_label } = item;
        return (
          <div className="round-block-warpper" key={`option-item-${id}`}>
            <label
              htmlFor={`${name}_${idx}`}
              className={isCircle ? "round-group" : "checkbox-group"}
            >
              <input
                type={type}
                id={id}
                name={name}
                value={option_value}
                onChange={handleOnChange}
                checked={checkedOption === option_value}
                readOnly={readOnly}
                disabled={disabled}
              />
              <span translate={isCircle ? "no" : "yes"} className="checkmark">
                {menuType === "feedback" ? option_label : option_text}
              </span>
            </label>
            {menuType === "feedback" && (
              <div>
                <p>{option_text}</p>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
