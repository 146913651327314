import React from "react";

const IconSetting: React.FC = () => (
  <svg
    className="fill-buttonColor"
    xmlns="http://www.w3.org/2000/svg"
    width="51"
    height="51"
    viewBox="0 0 51 51"
  >
    <path
      id="Path_14812"
      data-name="Path 14812"
      d="M34.785-18.551l-.993-8.062a32.068,32.068,0,0,1-3.357-1.537A17.607,17.607,0,0,1,27.228-30.2l-7.711,3.148L14.4-35.624l6.564-4.762q-.077-.809-.191-1.8a15.833,15.833,0,0,1-.114-1.794,14.363,14.363,0,0,1,.114-1.685q.114-.953.191-2.054L14.4-52.479l5.116-8.427,7.634,3.078a26.943,26.943,0,0,1,3.243-2.086,26.71,26.71,0,0,1,3.32-1.576l1.07-8.062H45.017l.993,8.132a33.944,33.944,0,0,1,3.28,1.576,24.561,24.561,0,0,1,3.056,2.015l7.938-3.078L65.4-52.479l-6.795,4.984a9.462,9.462,0,0,1,.268,1.832q.04.879.04,1.611,0,.661-.077,1.541t-.154,2.051l6.644,4.836-5.116,8.572-7.865-3.222a27.84,27.84,0,0,1-3.13,2.086,21.1,21.1,0,0,1-3.207,1.505l-.993,8.132ZM39.6-37.383a6.845,6.845,0,0,0,4.922-1.942,6.3,6.3,0,0,0,2.023-4.727,6.3,6.3,0,0,0-2.023-4.727A6.845,6.845,0,0,0,39.6-50.72a6.894,6.894,0,0,0-4.889,1.942,6.252,6.252,0,0,0-2.06,4.727,6.252,6.252,0,0,0,2.06,4.727A6.894,6.894,0,0,0,39.6-37.383Z"
      transform="translate(-14.401 69.552)"
    />
  </svg>
);
export default IconSetting;
