import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  background: {}
};

const backgroundSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    addCustomBackground: (state: any, action: any) => {
      const { payload } = action;
      state.background = payload;
      sessionStorage.setItem('bg', JSON.stringify(state.background));
    }
  }
})

export const { addCustomBackground } = backgroundSlice.actions;
export default backgroundSlice.reducer;