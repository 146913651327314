import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import Iconsvg from "../../components/icon/Icon";
import { addDemographyStepperCount, removeDemographyStepperCount, resetDemographicAnswer } from '../../slices/demographicAnswerSlice';

interface StepperFooterProps {
  totalCount: number;
  handleAction: any;
  screens?:
  | "termsandcondition"
  | "termsandconditionread"
  | "termsandconditionwatch"
  | "demographic"
  | "feedback";
  count: number;
}

export const StepperFooter: React.FC<StepperFooterProps> = ({
  totalCount,
  handleAction,
  screens,
  count,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const answerList = useSelector((state: any) => state.demographicAnswer);
  const { demographyStepperCount } = answerList;

  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (screens && screens === 'demographic' && answerList && answerList.answers && answerList.answers.length) {
      setCurrentStep(demographyStepperCount.length);
    }
  }, [])

  const handleNextStep = () => {
    const value = currentStep + 1;
    setCurrentStep(value);
    handleAction(value);
    if (screens && screens === 'demographic' && totalCount >= value) {
      dispatch(addDemographyStepperCount(value));
    }

    if (screens === "demographic" && value > totalCount) {
      navigate("/dashboard");
    }
  };

  const handlePrevStep = () => {
    const value = currentStep - 1;
    value > 1 ? setCurrentStep(value) : setCurrentStep(1);
    if (screens && screens === 'demographic' && value >= 1) {
      dispatch(removeDemographyStepperCount());
    }
    handleAction(value);
  };

  const backButton = () => {
    dispatch(resetDemographicAnswer());
    return navigate(-1);
  };
  const skipHandler =()=>{
    dispatch(resetDemographicAnswer())
  }
const backButtondemo=()=>{
  return navigate("/terms-and-conditions/read?demographic='1'");
}
  return (
    <>
      <div className="step-footer">
        {/* Back button */}
        <>
          {screens === "termsandconditionread" && currentStep === 1 && (
            <button
              id={`agreement-${currentStep}`}
              className="clear-button-style add-transistion"
              onClick={backButton}
            >
              <Iconsvg name="backButton" />
              Back
            </button>
          )}
          {screens === "termsandconditionread" &&
            currentStep > 1 &&
            currentStep <= 3 && (
              <button
                id={`agreement-${currentStep}`}
                className="clear-button-style add-transistion"
                disabled={currentStep === 1}
                onClick={handlePrevStep}
              >
                <Iconsvg name="backButton" />
                Back
              </button>
            )}
          {screens === "demographic" && currentStep === 1 && (
            <button
              className="clear-button-style add-transistion"
              onClick={backButtondemo}
            >
              <Iconsvg name="backButton" />
              Back
            </button>
          )}
          {screens === "demographic" && currentStep > 1 && currentStep <= totalCount && (
            <button
              className="clear-button-style add-transistion"
              disabled={currentStep === 1}
              onClick={handlePrevStep}
            >
              <Iconsvg name="backButton" />
              Back
            </button>
          )}
        </>
        <>
          {screens === "termsandconditionwatch" && currentStep === 1 && (
            <button
              className="clear-button-style add-transistion"
              onClick={backButton}
            >
              <Iconsvg name="backButton" />
              Back
            </button>
          )}
          {screens === "termsandconditionwatch" && currentStep === 2 && (
            <button
              className="clear-button-style add-transistion"
              onClick={handlePrevStep}
            >
              <Iconsvg name="backButton" />
              Back
            </button>
          )}
        </>
        {/* skip button */}
        <>{screens === "demographic" && <Link to="/dashboard" onClick={skipHandler}>Skip</Link>}</>
        <>
          {screens === "termsandconditionread" && (
            <>
              {count === 4 ? (
                <>
                  <Link to="/">Skip</Link>
                  <div className="w-12"></div>
                </>
              ) : (
                ""
              )}
            </>
          )}
        </>
        {/* Next button */}
        <>
          {/* For terms and conditions read page */}
          {screens === "termsandconditionread" && count <= 2 ? (
            <button
              id={`agreement-${currentStep}`}
              className="clear-button-style add-transistion"
              disabled={currentStep === totalCount}
              onClick={handleNextStep}
            >
              <Iconsvg name="nextButton" />
              Next
            </button>
          ) : (
            ""
          )}
          {/* For terms and conditions watch page */}
          {screens === "termsandconditionwatch" && count <= 1 ? (
            <button
              className="clear-button-style add-transistion"
              disabled={currentStep === totalCount}
              onClick={handleNextStep}
            >
              <Iconsvg name="nextButton" />
              Next
            </button>
          ) : (
            ""
          )}
          {/* For demographic page */}
          {screens === "demographic" && (
            <>
              {count <= totalCount - 1 ? (
                <button
                  className="clear-button-style add-transistion"
                  onClick={handleNextStep}
                >
                  <Iconsvg name="nextButton" />
                  Next
                </button>
              ) : null}

              {count === totalCount ? (
                <>
                  <button
                    className="clear-button-style add-transistion"
                    onClick={handleNextStep}
                  >
                    <Iconsvg name="nextButton" />
                    Next
                  </button>
                </>
              ) : null}
            </>
          )}
        </>
      </div>
    </>
  );
};
