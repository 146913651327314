import React, { useState } from "react";
import { PageLayout } from "../../components/PageLayout";
import { Card } from "../../components/Card";
import "../styles/avatar.css";
import { AvatarSelector } from "../../components/avatarSelector/AvatarSelector";
import { CustomButton } from "../../components";
import AvatarImg from "../../assets/images/Landscape.png";
import AvatarImg2 from "../../assets/images/Abstract colours.png";
import AvatarImg3 from "../../assets/images/Seascape.png";
import AvatarImg4 from "../../assets/images/Sharks.png";
import AvatarImg5 from "../../assets/images/cyp-avatar5.png";
import AvatarImg6 from "../../assets/images/cyp-avatar6.png";
import AvatarImg7 from "../../assets/images/cyp-avatar9.png";
import AvatarImg8 from "../../assets/images/Sunset2.jpeg";
import AvatarImg9 from "../../assets/images/Orange-flowers.jpg";
import { Link, useNavigate } from "react-router-dom";
import { addCustomAvatar } from "../../slices/avatarSlice";
import { useDispatch } from "react-redux";

interface AvatarProps {}

type OptionsProps = {
  label: string;
  value: string | number;
  imageUrl: any;
};

const cardOptions: OptionsProps[] = [
  {
    label: "cyp-avatar1",
    value: "avatar1",
    imageUrl: AvatarImg,
  },
  {
    label: "cyp-avatar2",
    value: "avatar2",
    imageUrl: AvatarImg2,
  },
  {
    label: "cyp-avatar3",
    value: "avatar3",
    imageUrl: AvatarImg3,
  },
  {
    label: "cyp-avatar4",
    value: "avatar4",
    imageUrl: AvatarImg4,
  },
  {
    label: "cyp-avatar5",
    value: "avatar5",
    imageUrl: AvatarImg5,
  },
  {
    label: "cyp-avatar6",
    value: "avatar6",
    imageUrl: AvatarImg6,
  },
  {
    label: "cyp-avatar8",
    value: "avatar8",
    imageUrl: AvatarImg8,
  },
  {
    label: "cyp-avatar7",
    value: "avatar7",
    imageUrl: AvatarImg9,
  },
  {
    label: "cyp-avatar9",
    value: "avatar9",
    imageUrl: AvatarImg7,
  },
];

export const Avatar: React.FC<AvatarProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [avatarSelector, setAvatarSelector] = useState<any>({});

  const avatarSelectorChange = (event: any) => {
    const selectedIndex = cardOptions.findIndex(
      (items) => items.label === event.target.name
    );
    const { value } = event.target;
    setAvatarSelector((prevState: any) => ({
      ...prevState,
      status: value,
      image: cardOptions[selectedIndex].imageUrl,
    }));
  };
  const handleClick = () => {
    if (avatarSelector && Object.keys(avatarSelector).length) {
      dispatch(addCustomAvatar(avatarSelector));
      navigate(-1);
    }
  };

  return (
    <PageLayout>
      <div>
        <h1 className="header-title">Avatar image</h1>
        <Card
          cardTitle="Please choose an avatar"
          type={false}
          removeBottomPadding={true}
        >
          <div className="avatar-container">
            <AvatarSelector
              userdata={cardOptions}
              iconShow
              checkedOption={avatarSelector}
              handleChange={avatarSelectorChange}
            />
          </div>
          <div className="avatar-submit-container">
            <div className="text-neutral text-center">
              <CustomButton
                name="Choose avatar"
                hanldeClick={handleClick}
                disable={avatarSelector && !Object.keys(avatarSelector).length}
              />
            </div>
            <div>
              <Link to="" className="add-transistion" onClick={()=>navigate(-1)}>
                Back
              </Link>
            </div>
          </div>
        </Card>
      </div>
    </PageLayout>
  );
};
