import React from "react";
import "./styles/card.css";

interface CardProps {
  cardTitle: string;
  type: boolean;
  children: any;
  removeBottomPadding?: boolean;
}

export const Card: React.FC<CardProps> = ({
  cardTitle,
  type,
  children,
  removeBottomPadding,
}) => {
  return (
    <div className="card-container">
      <h2 className={`card-title ${type ? "" : "title-alignment"}`}>
        {cardTitle}
      </h2>
      <div
        className={`card-spacing grid ${
          removeBottomPadding ? "" : "bottom-padding"
        }`}
      >
        {children}
      </div>
    </div>
  );
};
