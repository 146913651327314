import React from 'react';
import { useNavigate } from 'react-router-dom';

import './back-button.css';
import Iconsvg from '../icon/Icon';

type Props = {
  buttonLabel: string;
  labelShow?: boolean;
  iconName: any;
  buttonContent?: string;
  type: boolean;
};

const BackButton = (props: Props) => {
  const navigate = useNavigate();
  const Back = () => {
    return navigate(-1);
  };
  const Next = () => {
    return navigate(1);
  };
  const { buttonLabel, labelShow, iconName, buttonContent, type } = props;
  return (
    <div className='back-button'>
      <button type='button' onClick={type ? Next : Back}>
        <Iconsvg name={iconName} />
        {labelShow && <span> {buttonLabel}</span>}
        <div className='h-2'></div>
        <div className='button-text text-primaryContent'>{buttonContent}</div>
      </button>
    </div>
  );
};

export default BackButton;
