import React from "react";
import "./avatarOverlay.css";
import Avatar from "../../assets/images/Avatar";
import { useNavigate } from "react-router-dom";

interface AvatarOverlayProps {
  imageUrl?: string;
}
export const AvatarOverlay: React.FC<AvatarOverlayProps> = ({ imageUrl }) => {
  const navigate: any = useNavigate();
  const handleClick = () => {
    navigate("/avatar");
  };
  return (
    <div className=" relative">
      <div className="avathar-img-block">
        <div className="avatar-block">
          <div className="avathar-img cursor-pointer" onClick={handleClick}>
            {imageUrl ? <img src={imageUrl} alt="Avatar image" /> : <Avatar />}
          </div>
        </div>
      </div>
    </div>
  );
};
