import React, { Suspense, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import Loading from '../components/Loading';
import { About } from '../pages/feedback/About';
import { Avatar } from '../pages/feedback/Avatar';
import { Dashboard } from '../pages/feedback/Dashboard';
import { DemographicQuestions } from '../pages/feedback/DemographicQuestions';
import { FeedbackQuestionnaire } from '../pages/feedback/FeedbackQuestionnaire';
import { NotFound } from '../pages/NotFound';
import { Settings } from '../pages/feedback/Settings';
import { LandingPage } from '../pages/feedback/LandingPage';
import { Background } from '../pages/feedback/Background';
import { Sounds } from '../pages/feedback/Sounds';
import { SuccessPage } from '../pages/feedback/SuccessPage';
import { QrGenerationPage } from '../pages/taskforce/QrGeneration';
import { LandingPageAdmin } from '../pages/report/landingPage';
import { TermsAndConditionsRead } from '../pages/feedback/TermsAndConditionsRead';
import SmoothScroll from '../components/SmoothScroll';
import { AuditTrail } from '../pages/report/AuditTrail';
import { AddQRCode } from '../pages/taskforce/AddQRCode';
import { FeedbackView } from '../pages/report/FeedbackView';
import { FeedbackOverView } from '../pages/report/FeedbackViewOverview';
import { FeedbackInfo } from '../pages/report/FeedbackInfo';
import { ReportDashboard } from '../pages/report/ReportDashboard';
import { ReportTermsAndConditions } from '../pages/report/TermsAndConditions';
import { ReportAbout } from '../pages/report/ReportAbout';
import Unit from '../pages/taskforce/Unit';
import AddUnitForm from '../pages/taskforce/AddUnitForm';
import { PrintPDF } from '../pages/feedback/PrintPDF';
import { ReportAdmin } from './ReportAdmin';
import { LoginResponse } from '../types';
import { PrintQRCode } from '../pages/report/PrintQRCode';
import { FeedbackAuthLayout } from './FeedbackAuthLayout';
import { FeedbackNotAvailable } from '../pages/feedback/FeedbackNotAvailable';
import { PilotCheckInReport } from './PilotCheckInReport';
import { ReportPilot } from '../pages/report/ReportPilot';
import { FeedbackPreview } from '../pages/feedback/FeedbackPreview';
import { SubmitFeedback } from '../pages/feedback/SubmitFeedback';

// Initialize Google Tag Manager.
if (process.env.REACT_APP_GA_TAGMANAGER) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GA_TAGMANAGER,
    dataLayerName: 'PageDataLayer',
  }
  TagManager.initialize(tagManagerArgs)
}

const RootLayout = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname && process.env.REACT_APP_GA_TAGMANAGER) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'pageview',
          pageUrl: location.pathname,
        },
      })
    }
  }, [location])
  return <Outlet />
}

const FeedbackTermsCheck = () => {
  const feedbackTerms: string | null = sessionStorage.getItem('terms');
  if (feedbackTerms) {
    return <Outlet />;
  }
  return <Navigate to='/terms-and-conditions/read' replace />;
};

const AuthTaskforceLayout = () => {
  const userLoginData: LoginResponse = useSelector(
    (state: any) => state.adminLogin
  );
  const { isUserAdminAccess, isTaskForceGroupUser } = userLoginData;
  if (isTaskForceGroupUser || isUserAdminAccess) {
    return <Outlet />;
  }
  return <Navigate to='/report-dashboard' replace />;
};

const AuthCommissionerLayout = () => {
  const userLoginData: LoginResponse = useSelector(
    (state: any) => state.adminLogin
  );
  const { isTaskForceGroupUser, isUserAdminAccess } = userLoginData;
  if (isTaskForceGroupUser && !isUserAdminAccess) {
    return <Navigate to='/report-dashboard' replace />;
  }
  return <Outlet />;
};

export const AppRoutes = () => {

  return (
    <React.Fragment>
      <Router>
        <SmoothScroll>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route element={<RootLayout />}>
                <Route path='/' element={<LandingPage />} />
                <Route element={<FeedbackAuthLayout />}>
                  <Route path='/terms-and-conditions/read' element={<TermsAndConditionsRead />} />
                  <Route path='/avatar' element={<Avatar />} />
                  {/* Below route works if cyp accept the terms and conditions */}
                  <Route element={<FeedbackTermsCheck />}>
                    <Route path='/dashboard' element={<Dashboard />} />
                    <Route path='/demographic' element={<DemographicQuestions />} />
                    <Route path='/feedback' element={<FeedbackQuestionnaire />} />
                    <Route path='/submit' element={<SubmitFeedback />} />
                    <Route path='/success' element={<SuccessPage />} />
                    <Route path='/background' element={<Background />} />
                    <Route path='/pdf' element={<PrintPDF />} />
                  </Route>
                </Route>
                <Route path='/about' element={<About />} />
                <Route path='/settings' element={<Settings />} />
                <Route path='/sounds' element={<Sounds />} />
                <Route path='/qr/:id' element={<LandingPage />} />

                {/*
                  - Below routes are for Report module
                  - The report module verifies the status of the pilot before login.
                  - It navigates to the pilot closed page if the pilot state is false.
                */}
                <Route element={<PilotCheckInReport />}>
                  <Route element={<ReportAdmin />}>
                    <Route path='/report-dashboard' element={<ReportDashboard />} />

                    {/* Below routes for commissioner */}
                    <Route element={<AuthCommissionerLayout />}>
                      <Route path='/audit-trail' element={<AuditTrail />} />
                      <Route
                        path='/feedback-overview'
                        element={<FeedbackOverView />}
                      />
                      <Route
                        path='/feedback-info/:unit_id/:feedback_id'
                        element={<FeedbackInfo />}
                      />
                      <Route
                        path='/feedback-view/:unit_id'
                        element={<FeedbackView />}
                      />
                    </Route>

                    {/* Below routes only for task force team */}
                    <Route element={<AuthTaskforceLayout />}>
                      <Route path='/add-qr' element={<AddQRCode />} />
                      <Route path='/qr' element={<QrGenerationPage />} />
                      <Route path='/unit' element={<Unit />} />
                      <Route path='/add-unit' element={<AddUnitForm />} />
                      <Route path='/unit/:unit_id' element={<AddUnitForm />} />
                      <Route path='/print-qr' element={<PrintQRCode />} />
                    </Route>
                    <Route path='/admin/terms-and-conditions' element={<ReportTermsAndConditions />} />
                  </Route>
                  <Route path='/admin' element={<LandingPageAdmin />} />
                </Route>
                <Route path='/report-about' element={<ReportAbout />} />
                <Route path='/pilot-closed' element={<FeedbackNotAvailable />} />
                <Route path='/report-pilot-closed' element={<ReportPilot />} />
                <Route path='*' element={<NotFound />} />
              </Route>
            </Routes>
          </Suspense>
        </SmoothScroll>
      </Router>
    </React.Fragment>
  );
};
