import React from "react";

import { PageLayout } from "../../components/PageLayout";
import { Card } from "../../components/Card";
import BackButton from "../../components/backButton/BackButton";
import { feedBackContent } from "../../content/feedBack/content";
import { useSelector } from "react-redux";
import { patientTypes } from "../../data/userdata";

export const About: React.FC = () => {
  const roleData = useSelector((state: any) => state.roleInfo);
  console.log(roleData, "test");
  const aboutContent =
    roleData.userRole === patientTypes.parent
      ? feedBackContent.parent.about
      : feedBackContent.child.about;
  return (
    <PageLayout>
      <div>
        <h1 className="mb-24 text-center">Home</h1>
        <Card cardTitle="About" type={true} removeBottomPadding={true}>
          <div className="card-content mb-5">
            <div>
              {roleData.userRole === patientTypes.parent ? (
                <p>
                  Safe2Share is a digital platform where you can give anonymous
                  feedback about the service your significant other or person
                  you are caring for is receiving. The purpose being:
                </p>
              ) : (
                <p>
                  Safe2Share is a digital platform where you can give anonymous
                  feedback about the service you are receiving. The purpose
                  being:
                </p>
              )}
              {roleData.userRole === patientTypes.parent ? (
                <ul>
                  <li>Enhancing service quality and safety.</li>
                  <li>Amplifying user voices and empowerment.</li>
                  <li>Facilitating early issue detection and resolution.</li>
                  <li>
                    Fostering collaboration and better communication across SHSC
                    teams.
                  </li>
                </ul>
              ) : (
                <ul>
                  <li>Improving service quality and safety.</li>
                  <li>
                    Boosting service user opinions and giving them more power.
                  </li>
                  <li>Helping find and fix problems sooner.</li>
                  <li>
                    Encouraging teamwork and improving communication among SHSC
                    teams.
                  </li>
                </ul>
              )}
              {roleData.userRole === patientTypes.parent ? (
                <>
                  <p>
                    This will help us see where things are going well or not so
                    well and help us make improvements and provide better
                    services for adults.
                  </p>
                  <p>
                    Safe2Share is not a mechanism to provide complaints. If you
                    would like to make a complaint regarding your care at SHSC,
                    there are three ways:
                  </p>
                </>
              ) : (
                <>
                  <p>
                    This will help us see where things are going well or not so
                    well and help us make improvements and provide better
                    services for adults.
                  </p>
                  <p>There is also a family/carer version of Safe2Share.</p>
                  <p>
                    Safe2Share is not a mechanism to provide complaints. If you
                    would like to make a complaint regarding your care at SHSC,
                    there are three ways:
                  </p>
                </>
              )}
              <p>
                <strong>By writing:</strong> Complaints Team, Sheffield Health
                and Social Care NHS Foundation Trust, Centre Court, Atlas Way,
                Sheffield, S4 7QQ
                <br />
                <strong> By emailing:</strong>{" "}
                <a className="ml-2" href="mailto:complaints@shsc.nhs.uk">
                  complaints@shsc.nhs.uk
                </a>
                <br /> <strong>By calling:</strong> 0114 2718956
              </p>
            </div>
          </div>
          <div>
            <BackButton
              type={false}
              buttonLabel="Button"
              iconName="backButton"
              buttonContent="Back"
            />
          </div>
        </Card>
      </div>
    </PageLayout>
  );
};
