import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../middlewares/axios-interceptor";
import { LoginResponse } from "../types";

const userLoggedInData: any = localStorage.getItem('userLoginData');
const parsedJson = JSON.parse(userLoggedInData) || {};

const initialState: LoginResponse = {
  isUserLoggedIn: parsedJson.accessToken && parsedJson.refreshToken ? true : false,
  accessToken: parsedJson.accessToken || "",
  refreshToken: parsedJson.refreshToken || "",
  isLoader: false,
  error: "",
  terms_and_condition: parsedJson.terms_and_condition || false,
  role: parsedJson.user_role || "",
  isTaskForceGroupUser: false,
  isUserAdminAccess: false
};

export const userLogin = createAsyncThunk(
  "retrieve/access-token",
  async (payload: any, { rejectWithValue }) => {
    try {
      const URL = `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}/users`;
      const response: any = await axios.post(URL, payload);
      if (response) {
        localStorage.setItem('userLoginData', JSON.stringify(response));
        return response;
      }
    } catch (error: any) {
      throw rejectWithValue(error.message)
    }
  }
);

// Then, handle actions in your reducers:
const adminLoginSlice = createSlice({
  name: 'logins',
  initialState,
  reducers: {
    resetLogin: (state: any) => {
      localStorage.removeItem('userLoginData');
      return {
        ...state,
        isUserLoggedIn: false,
        accessToken: "",
        refreshToken: "",
        isLoader: false,
        error: "",
        terms_and_condition: false,
        role: "",
      };
    },
    setTermAndCondition: (state: any, action: any) => {
      if (action.payload) {
        const localStorageItem: any = localStorage.getItem('userLoginData') || {};
        const updateAdminObj: any = JSON.parse(localStorageItem)
        updateAdminObj["terms_and_condition"] = action.payload;
        localStorage.setItem('userLoginData', JSON.stringify(updateAdminObj));
        return {
          ...state,
          terms_and_condition: action.payload,
        };
      } else {
        return { ...state }
      }
    },
    storeUserAccessLevel: (state: any, action: any) => {
      if (action.payload) {
        const { payload } = action;
        return {
          ...state,
          isTaskForceGroupUser: payload.is_task_force_group_user,
          isUserAdminAccess: payload.user_have_admin_access
        }
      } else {
        return { ...state }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state, action) => {
        return {
          ...state,
          isLoader: true
        }
      })
      .addCase(userLogin.fulfilled, (state: any, action) => {
        const { payload } = action;
        if (payload) {
          return {
            ...state,
            accessToken: payload?.accessToken,
            refreshToken: payload?.refreshToken,
            isUserLoggedIn: true,
            isLoader: false,
            terms_and_condition: payload?.terms_and_condition,
            role: payload?.user_role
          }
        } else {
          return { ...state, isLoader: true };
        }
      })
      .addCase(userLogin.rejected, (state: any, action) => {
        return {
          ...state,
          isLoader: false,
          error: action.payload
        }
      })
  },
});

export const { resetLogin, setTermAndCondition, storeUserAccessLevel } = adminLoginSlice.actions;
const { reducer } = adminLoginSlice;
export default reducer;