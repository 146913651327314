import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  answers: [],
  demographyStepperCount: [1]
};

const demographicAnswerSlice = createSlice({
  name: 'demographicAnswer',
  initialState,
  reducers: {
    addToAnswer: (state, action) => {
      const { answers } = state;
      answers.push(action.payload);
      sessionStorage.setItem('demographic', JSON.stringify(answers));
    },
    updateToAnwer: (state, action) => {
      const param = action.payload;
      const existingAnswers = JSON.parse(JSON.stringify(state.answers));
      const updateValue = existingAnswers.map((opt: any) => {
        let objSke = { ...opt }
        if (objSke.question === param.question) {
          objSke.option = param.option;
        }
        return objSke;
      });
      sessionStorage.setItem('demographic', JSON.stringify(updateValue));
      state.answers = updateValue;
    },
    getDemographyAnswersFromStorage: (state, action) => {
      if (action && action.payload) {
        const demoAnswersList = JSON.parse(action.payload);
        return {
          ...state,
          answers: demoAnswersList
        }
      }
    },
    addDemographyStepperCount: (state, action) => {
      const { demographyStepperCount } = state;
      if (action && action.payload && !demographyStepperCount.includes(action.payload)) {
        demographyStepperCount.push(action.payload);
        sessionStorage.setItem('dgStepper', JSON.stringify(demographyStepperCount));
      }
    },
    removeDemographyStepperCount: (state) => {
      state.demographyStepperCount.pop();
      sessionStorage.setItem('dgStepper', JSON.stringify(state.demographyStepperCount));
    },
    updateDemographyStepperCount: (state, action) => {
      if (action && action.payload) {
        const counts = JSON.parse(action.payload);
        return {
          ...state,
          demographyStepperCount: counts
        }
      }
    },
    resetDemographicAnswer: (state) => {
      state.answers = [];
      state.demographyStepperCount = [1];
      sessionStorage.removeItem('demographic');
      sessionStorage.removeItem('dgStepper');
    }
  }
})

export const {
  addToAnswer,
  updateToAnwer,
  resetDemographicAnswer,
  getDemographyAnswersFromStorage,
  addDemographyStepperCount,
  removeDemographyStepperCount,
  updateDemographyStepperCount
} = demographicAnswerSlice.actions;
export default demographicAnswerSlice.reducer;