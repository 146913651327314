import React from "react";
import "./styles/customButton.css";

interface CustomButtonProps {
  name: string;
  addClass?: string;
  hanldeClick?: React.MouseEventHandler<HTMLButtonElement>;
  disable?: boolean;
  type?: any;
  id?: string;
}

export const CustomButton: React.FC<CustomButtonProps> = ({
  name,
  addClass,
  hanldeClick,
  disable,
  type = "button",
  id = ''
}) => {
  const btnDisable = disable ? "btn-disable" : '';
  return (
    <>
      <button
        id={id}
        className={`button-section add-transistion ${addClass || ''} ${btnDisable}`}
        onClick={hanldeClick}
        disabled={disable}
        type={type}
      >
        {name}
      </button>
    </>
  );
};
