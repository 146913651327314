export const feedBackContent = {
  appName: "Safe2Share",
  pilotTitle: "This pilot has ended",
  child: {
    dashboard: [
      {
        name: "Safe2Share is a digital platform where you can give anonymous feedback about the service you are receiving. The purpose being……",
      },
    ],
    about: [
      {
        name: "Safe2Share is a digital platform where you can give anonymous feedback about the hospital unit where you are staying.",
      },
      {
        name: "Your answers go to the ‘commissioners’ – the people who make decisions about mental health services in your area - so that they can see where things are going well or not so well. This will help them make improvements and provide better services for children and young people.",
      },
      {
        name: "Your answers won’t be shared with the staff in your unit",
      },
      {
        name: "There is also a parent/carer version of Safe2Share.",
      }
    ],
    termsAndCondition: {
      stepperOne: "<p>Safe2Share is a digital platform where you can give anonymous feedback about the hospital unit where you are staying.</p><p>Your answers go to the ‘commissioners’ – the people who make decisions about mental health services in your area - so that they can see where things are going well or not so well. This will help them make improvements and provide better services for children and young people.</p><p>Your answers won’t be shared with the staff in your unit. <br/>There is also a parent/carer version of Safe2Share.</p>",
      stepperTwo: "<p>You will be asked a set of 10 questions that you can choose to answer or skip. You can complete the survey as often as you like whilst you are staying on the unit. If you fill out the survey more than once, we will save your answers from each response. Customise your platform by adding sound, choosing an avatar and changing the background.</p><p>The feedback you provide in this app will not be monitored on a daily basis. Therefore, any concerns raised will not be responded to immediately. As this application will not identify you as an individual on the unit, your responses to questions will not be linked to you. Therefore, it is important if you are experiencing difficulties, you inform your parent/carer and /or staff on the unit, in order for them to provide you with support.</p>",
      stepperThree: "<p>The answers you give are anonymous – you do not need to share your name. It is a safe space to share your honest thoughts about how you are finding your stay here (‘here’ means the unit where you are currently staying).</p><p>What you say is confidential and will not affect your care. As stated before (on the <strong>About</strong> page), your answers will only be shared with commissioners.</p><p>Please tick the box below to show you understand the above before you give feedback</p>",
    },
    feedbackSuccessMsg: "<p>Thank you for sharing your responses. You have successfully submitted your feedback form.</p><p>If you are happy to do so, please provide us with feedback on how you found using the Safe2Share platform by clicking the link below.<br/><a class='external-link-blk' href='https://mindwaveventures.typeform.com/to/h0Vlq1ln' target='_blank'>https://mindwaveventures.typeform.com/to/h0Vlq1ln</a></p>",
    noQuestionMessage: "<p class='fb-notfound-text'>The Safe2Share platform was being trialled on this unit as part of a pilot - in other words, we wanted to ask people to try using the platform, so that we could get feedback on what they liked and didn't like about it.</p><br/><p class='fb-notfound-text'>This pilot has now come to a close. If you used the Safe2Share platform during the pilot phase, and you would like to give us some feedback on how you found the platform, we would love you to complete our survey at this link:<br/><a class='external-link-blk' href='https://mindwaveventures.typeform.com/to/h0Vlq1ln' target='_blank'>https://mindwaveventures.typeform.com/to/h0Vlq1ln</a><br/>(or alternatively you can ask someone on the unit to help you access the survey). All feedback we gather from the pilot will be used to further improve the Safe2Share platform and hopefully implement it more widely in NHS Inpatient Mental Health and Learning Disability Services units in the future.</p><br/><p class='fb-notfound-text'>If you are feeling immediately unsafe on this unit please alert a member of staff to your situation. If you feel unsafe to do this, please request to contact your parent/carer, Matron/Head of Nursing or local advocacy service, who will be able to support you with your situation. These contact details should be made available to you on request.</p>",
  },
  parent: {
    dashboard: [
      {
        name: "Safe2Share is a digital platform where you can give anonymous feedback about the service your significant other or person you are caring for is receiving. The purpose being……",
      },
    ],
    about: [
      {
        name: "Safe2Share is a digital platform where you can give anonymous feedback about the hospital unit where you are staying.",
      },
      {
        name: "Your answers go to the ‘commissioners’ – the people who make decisions about mental health services in your area - so that they can see where things are going well or not so well. This will help them make improvements and provide better services for children and young people.",
      },
      {
        name: "Your answers won’t be shared with the staff in your unit",
      },
      {
        name: "There is also a Children and Young People's version of Safe2Share.",
      }
    ],
    termsAndCondition: {
      stepperOne: "<p>Safe2Share is a digital platform where you can give anonymous feedback about the hospital unit where your child is staying.</p><p>Your answers go to the ‘commissioners’ – the people who make decisions about mental health services in your area - so that they can see where things are going well or not so well. This will help them make improvements and provide better services for children and young people.</p><p>Your answers won’t be shared with the staff in your child's unit. <br/>There is also a Children and Young People's version of Safe2Share.</p>",
      stepperTwo: "<p>You will be asked a set of 10 questions that you can choose to answer or skip. You can complete the survey as often as you like whilst your child stays on the unit. If you fill out the survey more than once, we will save your answers from each response.</p> <p>The feedback you provide in this app will not be monitored on a daily basis. Therefore, any concerns raised will not be responded to immediately. As this application will not identify you nor your child as an individual on the unit, any responses to questions will not be linked to an individual. Therefore, it is important that if you or your child are experiencing difficulties, you inform staff on the unit, in order for them to provide support.</p>",
      stepperThree: "<p>The answers you give are anonymous – you do not need to share your name. It is a safe space to share your honest thoughts about how you are finding your child's stay here (‘here’ means the unit where your child is staying).</p><p>What you say is confidential and will not affect your child's care. As stated before (on the <strong>About</strong> page), your answers will only be shared with commissioners.</p><p>Please tick the box below to show you understand the above before you give feedback</p>",
    },
    feedbackSuccessMsg: "<p>Thank you for sharing your responses. You have successfully submitted your feedback form.</p><p>If you are happy to do so, please provide us with feedback on how you found using the Safe2Share platform by clicking the link below.<br/><a class='external-link-blk' href='https://mindwaveventures.typeform.com/to/RBEq34hI' target='_blank'>https://mindwaveventures.typeform.com/to/RBEq34hI</a></p>",
    noQuestionMessage: "<p class='fb-notfound-text'>The Safe2Share platform was being trialled on this unit as part of a pilot - in other words, we wanted to ask people to try using the platform, so that we could get feedback on what they liked and didn't like about it.</p><br/><p class='fb-notfound-text'>This pilot has now come to a close. If you used the Safe2Share platform during the pilot phase, and you would like to give us some feedback on how you found the platform, we would love you to complete our survey at this link:<br/><a class='external-link-blk' href='https://mindwaveventures.typeform.com/to/RBEq34hI' target='_blank'>https://mindwaveventures.typeform.com/to/RBEq34hI</a><br/>(or alternatively you can ask someone on the unit to help you access the survey). All feedback we gather from the pilot will be used to further improve the Safe2Share platform and hopefully implement it more widely in NHS inpatient mental health units in the future.</p><br/><p class='fb-notfound-text'>If you feel your child is immediately unsafe on this unit please alert a member of staff to your situation. Or if you feel you require independent support to do this, please request to contact the Matron/Head of Nursing or local advocacy service, who will be able to support you with your child's situation.</p>",
  },
};