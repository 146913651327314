import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Card } from "../Card";
import Iconsvg from "../icon/Icon";
import "./stepper.css";
import { CustomButton } from "../CustomButton";
import { setAnswers } from "../../slices/submitAnswerSlice";
import { patientTypes } from "../../data/userdata";
import {
  addAnswer,
  updateAnswer,
  resetFeedbackAnswer,
  addFeedbackStepperCount,
  removeFeedbackStepperCount,
  resetFbStepperCount,
  setCurrentStep,
  setFeedbackPreview,
} from "../../slices/feedbackAnswerSlice";
import { resetDemographicAnswer } from "../../slices/demographicAnswerSlice";
import { SelectDropdown, CustomRadioButton } from "../../components";
import Loading from "../Loading";
import { color } from "highcharts";

interface StepperFeedbackProps {
  closeicon?: boolean;
  setClose?: any;
  activeStep?: any;
  showSubmit?: any;
  setSubmit?: any;
  editCount?: any;
}

export const StepperFeedback: React.FC<StepperFeedbackProps> = ({
  closeicon,
  setClose,
  activeStep,
  showSubmit,
  setSubmit,
  editCount,
}) => {
  const [selectOption, setSelectOptions] = React.useState(null);
  const [text, setText] = useState("");
  const [wordCount, setWordCount] = useState<number>(0);
  const [error, setError] = useState<string>("");
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const location = useLocation();

  const demographicAnswerList = useSelector(
    (state: any) => state.demographicAnswer
  );
  const feedbackQuestionList = useSelector(
    (state: any) => state.feedbackQuestion
  );
  const feedbackAnswerList = useSelector((state: any) => state.feedbackAnswer);
  const roleData = useSelector((state: any) => state.roleInfo);
  const { feedbackAnswers, fbStepperCount, currentStep } = feedbackAnswerList;

  const activeRef = useRef<null | HTMLLIElement>(null);
  const fbStepperCounts: any = sessionStorage.getItem("fbStepper");
  const fdQuestionTotal: any = sessionStorage.getItem("fbQuestionCount");

  useEffect(() => {
    activeRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [currentStep]);

  // Set stepper value if already filled the feedback question
  useEffect(() => {
    const { pathname, state } = location;
    if (
      pathname &&
      state?.previousPathname &&
      pathname !== state?.previousPathname &&
      fbStepperCount &&
      feedbackAnswers.length &&
      !editCount
    ) {
      // console.log("It has already filled the some of question");
      dispatch(setCurrentStep(fbStepperCount.length));
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (
      feedbackAnswers.length &&
      fbStepperCount &&
      fbStepperCount.length &&
      !editCount
    ) {
      dispatch(setCurrentStep(fbStepperCount.length));
    }

    if (editCount) {
      dispatch(setCurrentStep(1));
      dispatch(resetFbStepperCount());
    }
  }, []);

  // Submission page card is displayed if the user reloads the website.
  useEffect(() => {
    if (fbStepperCounts && fdQuestionTotal && !feedbackQuestionList.isLoader) {
      const stepperList = JSON.parse(fbStepperCounts);
      const questionTotal = Number(fdQuestionTotal);
      if (stepperList && stepperList.length === questionTotal + 1) {
      }
    }
  }, [feedbackQuestionList.isLoader]);

  const handleNextStep = () => {
    if (closeicon === true) {
      setClose(!color);
    }
    const value = currentStep + 1;
    dispatch(addFeedbackStepperCount(value));
    if (value > feedbackQuestionList.feedbackQues.length) {
      dispatch(setFeedbackPreview(true));
    }
    dispatch(setCurrentStep(value));
  };

  const handlePrevStep = () => {
    activeStep(currentStep);
    if (currentStep > 1) {
      dispatch(setCurrentStep(currentStep - 1));
    } else {
      dispatch(setCurrentStep(1));
    }
    dispatch(removeFeedbackStepperCount());
  };

  const handleOnChange = (event: any, selectedFquestion: any) => {
    const { value } = event?.target;
    prepareAnswers(value, selectedFquestion);
  };

  useEffect(() => {
    const words = text
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0);
    setWordCount(words.length);
  }, [text]);

  const maxWords: number = 250;

  const textareaHandler = (event: any, selectedFquestion: any) => {
    let words = event.target.value
      .split(/\s+/)
      .filter((word: any) => word.length > 0);
    const textAreaValue = event.target.value;
    if (words.length > maxWords) {
      setError(`Max words: ${maxWords}`);
    } else {
      setText(textAreaValue);
      const param = {
        question: selectedFquestion.id,
        answer: textAreaValue,
        type: selectedFquestion.type,
      };
      const idx = feedbackAnswers.findIndex(
        (item: any) => item.question === selectedFquestion.id
      );
      idx !== -1 ? dispatch(updateAnswer(param)) : dispatch(addAnswer(param));
      setError("");
    }
  };
  const prepareAnswers = (value: any, selectedFquestion?: any) => {
    const { id } = selectedFquestion;
    const param = { question: selectedFquestion.id, option: value };
    const idx = feedbackAnswers.findIndex((item: any) => item.question === id);
    idx !== -1 ? dispatch(updateAnswer(param)) : dispatch(addAnswer(param));
  };

  const handleSubmit = () => {
    const resp = [...demographicAnswerList.answers, ...feedbackAnswers];
    const param = { id: roleData.roleId, answersList: resp };
    dispatch(setAnswers(JSON.stringify(param)));
    navigate("/success");
    dispatch(resetFeedbackAnswer());
    dispatch(resetDemographicAnswer());
  };

  const goBackFromSubmit = () => {
    setCurrentStep((currentStep: any) => currentStep - 1);
    setSubmit(false);
    dispatch(setFeedbackPreview(true));
    dispatch(removeFeedbackStepperCount());
  };

  if (feedbackQuestionList.error && !feedbackQuestionList.feedbackQues.length) {
    return (
      <>
        <Card type={true} cardTitle={"Error"}>
          <div className="flex justify-center">
            <p>{feedbackQuestionList.error}</p>
          </div>
        </Card>
      </>
    );
  }

  const getRenderSubmitContent = () => {
    if (
      roleData &&
      roleData.userRole &&
      roleData.userRole === patientTypes.parent
    ) {
      return (
        <>
          <p>
            If you feel your child is immediately unsafe on this unit please
            alert a member of staff to your situation. If you feel you require
            independent support to do this, please request to contact the unit,
            Matron/Head of Nursing or local advocacy service, who will be able
            to support you with your child’s situation.
          </p>
        </>
      );
    } else {
      return (
        <>
          <p className="text-base">
            If you are feeling immediately unsafe on this unit please alert a
            member of staff to your situation, if you feel unsafe to do this,
            please request to contact your parent/carer, Matron/Head of Nursing
            or local advocacy service, who will be able to support you with your
            situation. These contact details should be made available to you on
            request.
          </p>
          <br />
          <p className="text-base">
            There is no need to share the answers you have given in Safe2Share,
            but they will be able to offer you support.
          </p>
          <br />
          <p className="text-base">
            When you click ‘submit’ you will not be able to edit your answers.
            Are you happy and ready to submit all your answers now?
          </p>
        </>
      );
    }
  };
  return (
    <>
      {/* Steps counter */}
      <div className="stepper-spacer">
        {feedbackQuestionList.feedbackQues && (
          <div
            className={`stepper-wrap ${
              feedbackQuestionList.feedbackQues.length > 4
                ? "justify-start"
                : "justify-center"
            }`}
          >
            {/* Render steps */}
            <ul>
              {feedbackQuestionList.feedbackQues.map(
                (step: any, index: number) => {
                  const isActive = currentStep >= index + 1 ? "active" : "";
                  if (currentStep === index + 1) {
                    return (
                      <React.Fragment key={`feedback-question-${step?.id}`}>
                        <li
                          key={`feedback-question-item-${step?.id}`}
                          className={`${isActive}`}
                          ref={activeRef}
                          translate="no"
                        >
                          {index + 1}
                        </li>
                        {index <
                          feedbackQuestionList.feedbackQues.length - 1 && (
                          <span></span>
                        )}
                      </React.Fragment>
                    );
                  }
                  return (
                    <React.Fragment
                      key={`not-active-feedback-ques-${step?.id}`}
                    >
                      <li
                        key={`not-active-${step?.id}`}
                        className={`${isActive}`}
                        translate="no"
                      >
                        {index + 1}
                      </li>
                      {index < feedbackQuestionList.feedbackQues.length - 1 ? (
                        <span />
                      ) : null}
                    </React.Fragment>
                  );
                }
              )}
            </ul>
          </div>
        )}
      </div>
      {/* Render content */}
      <Card
        type={true}
        cardTitle={
          feedbackQuestionList.isLoader
            ? ""
            : currentStep === 11
            ? "Final comments"
            : `Question ${currentStep}`
        }
      >
        <form>
          {feedbackQuestionList.isLoader ? (
            <div className="flex justify-center">
              <Loading />
            </div>
          ) : feedbackQuestionList.feedbackQues &&
            feedbackQuestionList.feedbackQues.length ? (
            feedbackQuestionList.feedbackQues.map(
              (fQuestion: any, idx: number) => {
                const { question, Option, type, id, topic, placeholder } =
                  fQuestion;
                const answer = feedbackAnswers.find(
                  (item: any) => item.question === id
                );
                return (
                  <div key={`feedback-question-item-${id}`}>
                    {currentStep === idx + 1 && (
                      <div key={`feed-question-item-${id}`}>
                        <div className="question-title">{topic}</div>
                        <label className="question-sub-title">
                          {currentStep === 11 ? (
                            ""
                          ) : (
                            <span>{currentStep}.</span>
                          )}
                          {question}
                        </label>
                        <div className="h-6"></div>
                        <CustomRadioButton
                          type="radio"
                          name="feedback"
                          data={Option.map((opt: any) => ({
                            ...opt,
                            option_value: opt.id,
                            option_label: opt.option_value,
                          }))}
                          handleOnChange={(event: any) =>
                            handleOnChange(event, fQuestion)
                          }
                          checkedOption={
                            feedbackAnswers &&
                            feedbackAnswers.length > 0 &&
                            feedbackAnswers.filter(
                              (opt: any) => opt.question === fQuestion.id
                            ).length > 0
                              ? feedbackAnswers.filter(
                                  (opt: any) => opt.question === fQuestion.id
                                )[0]["option"]
                              : ""
                          }
                          isCircle={true}
                          menuType="feedback"
                        />
                        {type === "textarea" && currentStep === idx + 1 && (
                          <div>
                            <textarea
                              className="text-area-container"
                              id="myTextarea"
                              rows={10}
                              cols={30}
                              value={text || answer?.answer}
                              onChange={(e: any) => {
                                textareaHandler(e, fQuestion);
                              }}
                              placeholder={`Example: ${placeholder}`}
                            />
                            <div className="text-below-textarea">
                              {error && (
                                <div className="error-message">{error}</div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {type === "dropdown" && currentStep === idx + 1 && (
                      <div className="card-content">
                        <label>{question}</label>
                        <div className="h-4"></div>
                        <div className="grid grid-cols-2 max_md:grid-cols-1">
                          <SelectDropdown
                            type="text"
                            name="gender"
                            placeholder="Choose from dropdown"
                            optionsArray={Option.map((item: any) => {
                              return {
                                label: item.option_text,
                                value: item.id,
                              };
                            })}
                            onChange={(e: any) => {
                              setSelectOptions(e.value);
                              handleOnChange(e.value, fQuestion);
                            }}
                            value={selectOption}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                );
              }
            )
          ) : (
            <div className="flex justify-center align-middle">
              <p>No feedback question found</p>
            </div>
          )}
        </form>
        {/* Render submit information */}
        {showSubmit && (
          <div>
            {getRenderSubmitContent()}
            <div className="h-4" />
            <div className="flex justify-center">
              <CustomButton
                id="feedback-submit"
                name="Submit"
                hanldeClick={handleSubmit}
              />
            </div>
            <button
              className="clear-button-style add-transistion absolute bottom-6"
              onClick={goBackFromSubmit}
            >
              <Iconsvg name="backButton" />
              Back
            </button>
          </div>
        )}
        {/* Render footer */}
        {!showSubmit && (
          <div className="step-footer flex justify-between">
            <button
              className="clear-button-style add-transistion"
              disabled={currentStep === 1}
              onClick={handlePrevStep}
            >
              <Iconsvg
                name="backButton"
                id={currentStep === 1 ? "" : "fb-back-" + currentStep}
              />
              Back
            </button>
            {currentStep <= feedbackQuestionList?.feedbackQues?.length ? (
              <button
                className="clear-button-style add-transistion"
                disabled={
                  feedbackAnswers &&
                  feedbackQuestionList?.feedbackQues &&
                  feedbackAnswers.length === 0 &&
                  currentStep === feedbackQuestionList.feedbackQues.length
                }
                onClick={() =>
                  currentStep === feedbackQuestionList.feedbackQues.length
                    ? dispatch(setFeedbackPreview(true))
                    : handleNextStep()
                }
              >
                <Iconsvg id={`fb-next-${currentStep}`} name="nextButton" />
                Next
              </button>
            ) : null}
          </div>
        )}
      </Card>
    </>
  );
};
