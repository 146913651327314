import React from "react";

const IconGiveFeedback: React.FC = () => (
  <svg
    className="fill-buttonColor"
    xmlns="http://www.w3.org/2000/svg"
    width="51.311"
    height="46.814"
    viewBox="0 0 51.311 46.814"
  >
    <g id="Ultralight-S" transform="translate(-13.018 69.552)">
      <path
        id="Path_14788"
        data-name="Path 14788"
        d="M39.339-40.509H54.45v-2.313H41.7Zm-14.969,0h3.468L44.2-56.26a.979.979,0,0,0,.446-.721,1.192,1.192,0,0,0-.446-.794L42.213-59.8a1.011,1.011,0,0,0-.736-.43,1.251,1.251,0,0,0-.814.43L24.371-43.831ZM13.019-22.737V-64.929a4.451,4.451,0,0,1,1.327-3.322,4.642,4.642,0,0,1,3.39-1.3H59.613a4.642,4.642,0,0,1,3.39,1.3,4.451,4.451,0,0,1,1.327,3.322v29.476A4.455,4.455,0,0,1,63-32.128a4.642,4.642,0,0,1-3.39,1.3H21.274Z"
        transform="translate(0)"
      />
    </g>
  </svg>
);
export default IconGiveFeedback;
