import React, { useEffect, useState } from "react";

import "./language.css";

declare global {
  interface Window {
    google: any;
  }
}

interface LanguageTranslateProps {
  role?: string;
}

const LanguageTranslate: React.FC<LanguageTranslateProps> = ({ role }) => {
  const [recheckGoogle, setRecheckGoogle] = useState(0);
  const [googleTranslateReady, setGoogleTranslateReady] = useState(false);

  useEffect(() => {
    const googleTranslateELement = document.querySelector('.goog-te-combo');
    // console.log("googleTranslateELement...",googleTranslateELement)
    if (googleTranslateELement) {
      // reload page when user change the language
      googleTranslateELement.addEventListener('change', () => {
        setTimeout(() => {
          window.location.reload()
        }, 1000);
      });
    }
    if (recheckGoogle && !googleTranslateReady) {
      const interval = setInterval(() => {
        if (window.google) {
          // console.log('Google instance found during recheck')
          new window.google.translate.TranslateElement(
            {
              pageLanguage: "en",
              layout:
                window.google.translate.TranslateElement.InlineLayout.VERTICAL,
            },
            "google_translate_element"
          );
          setGoogleTranslateReady(true);
        } else {
          setRecheckGoogle((prev) => prev + 1);
        }
      }, 1000); // check every 1 second

      return () => {
        setRecheckGoogle(0);
        setGoogleTranslateReady(true);
        clearInterval(interval);
      };
    }
  }, [recheckGoogle, googleTranslateReady]);

  useEffect(() => {
    function setGoogleTranslateScript() {
      const el = document.getElementById("google-translate-script");
      if (el) {
        console.log("Google translate element is present.");
        return;
      }

      const script = document.createElement("script");
      script.id = "google-translate-script";
      script.src =
        "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      script.async = true;

      document.body.appendChild(script);
      setRecheckGoogle((prev) => prev + 1);
    }

    function removeScript() {
      const el = document.getElementById("google-translate-script");
      if (el) {
        document.body.removeChild(el);
      }
    }

    setGoogleTranslateScript();
    return () => {
      removeScript();
    };
  }, []);

  return (
    <>
      <div className={`lang-flex ${role ? 'translation-section' : ''}`}>
        <div id="google_translate_element"></div>
      </div>
    </>
  );
};

export default LanguageTranslate;
