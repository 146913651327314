import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Footer } from "./Footer";
import { Header } from "./Header";
import SunsetImg from "./../assets/images/flowers-background.jpg";
import fruits from "./../assets/images/Fruits.jpeg";
import sunset from "./../assets/images/Sunset.jpeg";
import beach from "./../assets/images/Beach.jpeg";
import Mushrooms from "./../assets/images/Mushrooms.jpeg";
import { AvatarOverlay } from "./avatarOverlay/AvatarOverlay";
import {} from "../store";
import { useLocation } from "react-router-dom";

interface PageLayoutProps {
  children?: React.ReactNode;
  role?: string;
  size?: "sm" | "md" | "lg" | "xlg";
  pageName?: string;
}

const backgroundCustomColor: any = {
  lilac: "#AC91C3E5",
  mint: "#D3EBE3",
  blue: "#C4D4EA",
  default: "#F0F0F0",
};

const backgroundCustomImage: any = {
  flowers: SunsetImg,
  fruits: fruits,
  sunset: sunset,
  mushrooms: Mushrooms,
  beach: beach,
};

export const PageLayout: React.FC<PageLayoutProps> = ({
  children,
  role,
  size,
  pageName,
}) => {
  const location = useLocation();
  const headerRef = useRef<any>(null);
  const footerRef = useRef<any>(null);

  const [headerHeight, setHeaderHeight] = useState("");
  const [footerHeight, setFooterHeight] = useState("");

  const bgData = useSelector((state: any) => state.customBackground);
  const avatarData = useSelector((state: any) => state.avatar);
  const roleData = useSelector((state: any) => state.roleInfo);

  const { background } = bgData || {};

  const handleResize = () => {
    setHeaderHeight(headerRef?.current?.clientHeight);
    setFooterHeight(footerRef?.current?.clientHeight);
  };

  // Get dynamic height
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  }, [headerHeight, footerHeight]);

  // Set background image or color if user set custom background
  const styleParam =
    background && background.isBgImg
      ? {
          backgroundImage: `url(${backgroundCustomImage[background.bgValue]})`,
          backgroundSize: "cover",
          overflow: "hidden",
        }
      : {
          backgroundColor: backgroundCustomColor[background.bgValue],
        };

  const totalHeight: any = headerHeight + footerHeight || 450;
  const customHeight = `calc(100vh - ${totalHeight.toString()}px)`;
  let navBodyHeight = window.innerHeight;
  navBodyHeight = navBodyHeight - totalHeight;

  if (pageName === "question-not-available") {
    return (
      <section
        className={`${
          location.pathname.includes("pdf") ||
          location.pathname.includes("print-qr")
            ? "pdf-page"
            : ""
        }`}
        style={styleParam}
      >
        <header ref={headerRef}>
          <Header role={role} isNoHeaderOption={true} />
        </header>
        <main
          className="px-5"
          style={{ ...styleParam, ...{ minHeight: navBodyHeight } }}
        >
          <div
            className={`content-container ${size ? size : ""}`}
            style={{ minHeight: customHeight }}
          >
            {children}
          </div>
        </main>
        <footer className="footer-section" ref={footerRef}>
          <Footer role={role} isNoFooterSection={true} />
        </footer>
      </section>
    );
  }

  if (pageName) {
    return (
      <section
        className={`${
          location.pathname.includes("pdf") ||
          location.pathname.includes("print-qr")
            ? "pdf-page"
            : ""
        }`}
        style={styleParam}
      >
        <header ref={headerRef}>
          <Header role={role} />
        </header>
        <main
          className="px-5"
          style={{ ...styleParam, ...{ minHeight: navBodyHeight } }}
        >
          <div
            className={`content-container ${size ? size : ""}`}
            style={{ minHeight: customHeight }}
          >
            {children}
          </div>
        </main>
        <footer className="footer-section" ref={footerRef}>
          <Footer role={role} />
        </footer>
      </section>
    );
  }
  return (
    <section
      className={`${
        location.pathname.includes("pdf") ||
        location.pathname.includes("print-qr")
          ? "pdf-page"
          : ""
      }`}
      style={styleParam}
    >
      <header ref={headerRef}>
        <Header role={role} />
      </header>
      {role ? null : roleData &&
        roleData.userRole &&
        roleData.userRole === "Parent" ? null : (
        <AvatarOverlay
          imageUrl={
            avatarData?.avatar?.status !== "avatar9"
              ? avatarData?.avatar?.image
              : ""
          }
        />
      )}
      <main
        className="px-5"
        style={{ ...styleParam, ...{ minHeight: navBodyHeight } }}
      >
        <div
          className={`content-container ${size ? size : ""}`}
          style={{ minHeight: customHeight }}
        >
          {children}
        </div>
      </main>
      <footer className="footer-section" ref={footerRef}>
        <Footer role={role} />
      </footer>
    </section>
  );
};
