import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "../../components/Card";
import Iconsvg from "../../components/icon/Icon";
import { PageLayout } from "../../components/PageLayout";
import { StepperFeedback } from "../../components/stepper/StepperFeedback";
import Print from "../../assets/images/icons/Print";
import { useDispatch, useSelector } from "react-redux";
import { getFeedbackQues } from "../../slices/feedbackQuestionSlice";
import { FeedbackInfo } from "../../assets/images/icons/FeedbackInfo";
import Close from "../../assets/images/icons/Close";
import { FeedbackPreview } from "./FeedbackPreview";
import { patientTypes } from "../../data/userdata";

interface FeedbackQuestionnaireProps {}

export const FeedbackQuestionnaire: React.FC<
  FeedbackQuestionnaireProps
> = () => {
  const [counter, setCounter] = useState(false);
  const [close, setClose] = useState(true);
  const [showSubmit, setSubmit] = useState(false);
  const [editCount, setEditCount] = useState(false);
  const dispatch = useDispatch<any>();
  const roleData = useSelector((state: any) => state.roleInfo);
  const feedbackAnswerList = useSelector((state: any) => state.feedbackAnswer);
  const { feedbackAnswers, hasFeedbackPreview } = feedbackAnswerList;

  useEffect(() => {
    if (feedbackAnswers && feedbackAnswers.length) {
      setCounter(true);
    }
  }, [feedbackAnswers, counter]);

  useEffect(() => {
    if (roleData.roleId) {
      const param: any = { id: roleData.roleId, type: "feedback" };
      dispatch(getFeedbackQues(param));
    }
  }, [dispatch, roleData]);

  const handleStepCounter = (count: number) => {
    console.log("Count feedbackQuestion :>>", count);
  };

  return (
    <PageLayout>
      {hasFeedbackPreview ? (
        <FeedbackPreview
          activeStep={handleStepCounter}
          setEditCount={setEditCount}
        />
      ) : (
        <>
          <h1 className="page-head max_md:mt-8">Feedback questions</h1>
          {close && (
            <div className="feedback-header">
              <button
                type="button"
                className="feedback-btn"
                onClick={() => setClose(!close)}
              >
                <Close />
              </button>
              <div className="headerText head-container">
                <FeedbackInfo />
                <div className="head-text">Before you start</div>
              </div>
              {roleData.userRole === patientTypes.parent ? (
                <p>
                  You will be asked a set of 10 questions that you can choose to
                  answer or skip.
                  <br />
                  <div className="h-4" />
                  Any questions you view as non-applicable can be skipped.
                  <br />
                  <div className="h-4" />
                  You can complete the survey as often as you like whilst your
                  significant other or person you are caring for is staying on
                  the unit. If you fill out the survey more than once, we will
                  save your answers from each response.
                  <br />
                  <div className="h-4" />
                  Click on next and you will be taken to the next page.
                </p>
              ) : (
                <p>
                  You will be asked a set of 10 questions that you can choose to
                  answer or skip.
                  <br />
                  <div className="h-4" />
                  Any questions that you feel do not apply to you can be
                  skipped.
                  <br />
                  <div className="h-4" />
                  You can complete the survey as often as you like whilst you
                  are staying on the unit. If you fill out the survey more than
                  once, we will save your answers from each response.
                  <br />
                  <div className="h-4" />
                  You can customise your platform by adding sound, choosing an
                  avatar, and changing the background.
                  <br />
                  <div className="h-4" />
                  Click on next and you will be taken to the next page.
                </p>
              )}
            </div>
          )}
          <StepperFeedback
            activeStep={handleStepCounter}
            // setClose={setClose}
            setClose={() => {}}
            closeicon={close}
            setSubmit={setSubmit}
            showSubmit={showSubmit}
            editCount={editCount}
          />
        </>
      )}
      {/* {counter ? <StepperFeedback></StepperFeedback> : null} */}
    </PageLayout>
  );
};
