import React, { useEffect, useState } from "react";
import { PageLayout } from "../../components/PageLayout";
import { ErrorMessage, QRItem, SelectDropdown } from "../../components";
import axios from "../../middlewares/axios-interceptor";
import { useNavigate } from "react-router-dom";
import { InnerHeaderBlock } from "../../components/innerHeaderBlock/InnerHeaderBlock";
import Loading from '../../components/Loading';
import { IUnitList } from '../../types/feedback';
import { getQuestionSetService, getUnitListService } from '../../services/apiService';
import { AuditTrailCatList } from '../../data/userdata';

interface QRGenerationPageProps { }

export const QrGenerationPage: React.FC<QRGenerationPageProps> = () => {

  const [questionSetData, setQuestionSetData] = useState([]);
  const [unitDropDownList, setUnitDropDownList] = useState<IUnitList[]>([]);
  const [unitOption, setUnitOption] = useState('');
  const [patOption, setpatOption] = useState<string>('both');
  const [loader, setLoader] = useState<boolean>(false);
  const [errorState, setErrorState] = useState<any>({
    qrcodeError: {},
    unitError: {},
    cypOptionError: {},
  });
  const navigate = useNavigate();

  const getQuestionSet = async () => {
    setLoader(true);
    try {
      const queryPayload = {
        unit_id:unitOption,
        question_set_for:patOption,
      }
      const response: any = await getQuestionSetService(queryPayload)
      setQuestionSetData(response.data);
      setLoader(false);
    } catch (error: any) {
      setLoader(false);
      setErrorState({
        ...errorState,
        qrcodeError: error
      })
    }
  };

  // const generateNewQuestionSet = async () => {
  //   navigate("/add-qr");
  // };

  const getUnits = async () => {
    try {
      const unitListResponse = await getUnitListService();
      setUnitDropDownList(unitListResponse.data);
      setUnitOption(unitListResponse.data[0].value);
    } catch (error) {
      setErrorState({
        ...errorState,
        unitError: error
      })
    }
  };

  useEffect(() => {
    getUnits();
    getQuestionSet();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getQuestionSet();
  }, [unitOption,patOption])

  const handleBackBtn = () => {
    navigate('/report-dashboard');
  };
  return (
    <PageLayout size='lg' role='commissioner'>
      <InnerHeaderBlock
        backtext="Back"
        backLink='/report-dashboard'
        showSignOut
        handleBackBtn={handleBackBtn}
      />
      <div className="qrcode-block">
        <div className="title-section">
          <h1 className="">QR codes</h1>
          <p>As a Task force team. You can view, print and download the QR codes the team have been created for all the units involved.</p>
        </div>
        <div className="h-4"></div>
        {/* <div className="text-neutral text-center flex justify-end">
          {questionSetData.length === 0 && <CustomButton name="Generate QR" hanldeClick={generateNewQuestionSet} />}
        </div> */}
        <div className="h-4"></div>
        <section className="select-group-section">
          <div className="select-group-block"></div>
          <div className="select-group-block">
            <SelectDropdown
              addclass="secondary-select"
              type="text"
              name="unit"
              placeholder="Select"
              optionsArray={unitDropDownList}
              onChange={(e: any) => setUnitOption(e.value)}
              value={unitOption}
            />
            <SelectDropdown
              addclass="secondary-select"
              type="text"
              name="gender"
              placeholder="Select"
              optionsArray={AuditTrailCatList}
              onChange={(e: any) => setpatOption(e.value)}
              value={patOption}
            />
          </div>
        </section>
        <div className="h-4"></div>

        {/* QRCode card list */}
        {errorState && errorState?.qrcodeError?.message ?
          <ErrorMessage message={errorState.qrcodeError?.message} /> :
          loader ?
            <div className="no-data-block">
              <Loading addclass="inner-loader" />
            </div> :
            questionSetData && questionSetData.length ?
              questionSetData.map((qrItem: any, id: number) => {
                return (
                  <div key={`qr-item-section-${id}`}>
                    <QRItem data={qrItem} />
                  </div>
                )
              }) :
              <div className="no-data-block">
                <p>No qr available.</p>
              </div>}
      </div>
    </PageLayout>
  );
};
