import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { PageLayout } from "../../components/PageLayout";
import { Card } from "../../components/Card";
import {
  StepperFooter,
  SelectDropdown,
} from "../../components";
import {
  addToAnswer,
  updateToAnwer,
} from "../../slices/demographicAnswerSlice";
import { getDemographicQues } from "../../slices/demographicSlice";
import Loading from "../../components/Loading";

interface DemographicQuestionsProps { }

export const DemographicQuestions: React.FC<
  DemographicQuestionsProps
> = ({ }) => {
  const [stepCount, setStepCount] = useState<number | any>(1);
  const [selectOption, setSelectOptions] = React.useState<any>({});

  const dispatch = useDispatch<any>();
  const questionList = useSelector((state: any) => state.demographicQuestion);

  const answerList = useSelector((state: any) => state.demographicAnswer);
  const roleData = useSelector((state: any) => state.roleInfo);
  const { demographicQues, isLoader } = questionList;
  const { demographyStepperCount, answers } = answerList;

  useEffect(() => {
    if (roleData && roleData.roleId) {
      const param: any = { id: roleData.roleId, type: "demography" };
      dispatch(getDemographicQues(param));
    }
  }, [dispatch, roleData]);

  useEffect(() => {
    if (answers && answers.length) {
      setStepCount(demographyStepperCount.length)
    }
    // eslint-disable-next-line
  }, [answers])

  const getCurrentStep = (count: number) => {
    setStepCount(count);
  };

  const prepareAnswers = (optionId: any, selectedDgQuestion: any) => {
    const param = { question: selectedDgQuestion.id, option: optionId };
    const findIndex = answerList.answers.findIndex(
      (item: any) => item.question === selectedDgQuestion.id
    );
    if (findIndex !== -1) {
      dispatch(updateToAnwer(param));
    } else {
      dispatch(addToAnswer(param));
    }
  };

  // If face any error on api call and shows this message
  if (questionList.error && !questionList.demographicQues.length) {
    return (
      <PageLayout>
        <Card type={true} cardTitle={"Error"}>
          {questionList.error && !questionList.demographicQues.length && (
            <div className="flex justify-center">
              <p>{questionList.error}</p>
            </div>
          )}
        </Card>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <h1 className="page-head">Demographic Questions</h1>
      <Card type={true} cardTitle={"Some information about you"}>
        {isLoader ? (
          <div className="loader-block">
            <Loading />
          </div>
        ) : demographicQues && demographicQues.length ?
          <React.Fragment>
            {stepCount === 1 ?
            <p className='mb-6 text-base'>This information helps us know if we’re hearing from a range of people and to understand if some groups are having similar experiences compared to other groups.</p> : null}
            {demographicQues.map((dgQuestion: any) => {
              const { id, Option, question,placeholder} = dgQuestion;
              return (
                <div key={`demographic-question-item-${id}`}>
                   <div className="card-content">
                      <label>{question}</label>
                      <div className="h-2"></div>
                      <div className="dorpDown-containter">
                        <SelectDropdown
                          type="text"
                          name={id}
                          placeholder={placeholder}
                          optionsArray={Option.map((item: any) => {
                            return {
                              label: item.option_text,
                              value: item.id,
                            };
                          })}
                          onChange={(e: any) => {
                            setSelectOptions((prev: any) => ({...prev, [id]: e.value}))
                            prepareAnswers(e.value, dgQuestion);
                          }}
                          value={Object.keys(selectOption).length ? selectOption[id] : ''}
                        />
                      </div>
                    </div>
                </div>
              );
            })}
          </React.Fragment> : (
            <div>
              <p>No demographic question found</p>
            </div>
          )}

        <div className="stepper-content-section">
          {demographicQues && demographicQues.length > 0 && (
            <StepperFooter
              totalCount={1}
              handleAction={getCurrentStep}
              screens="demographic"
              count={stepCount}
            />
          )}
        </div>
      </Card>
    </PageLayout>
  );
};
