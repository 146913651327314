import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

// importing reducers
import demographicReducer from "../slices/demographicSlice";
import demographicAnswerReducer from "../slices/demographicAnswerSlice";
import feedbackReducer from "../slices/feedbackQuestionSlice";
import feedbackAnswerSlice from "../slices/feedbackAnswerSlice";
import submitAnswerReducer from "../slices/submitAnswerSlice";
import adminLoginReducer from "../slices/adminLoginSlice";
import roleReducer from "../slices/roleSlice";
import backgroundReducer from "../slices/backgroundSlice";
import commonReducer from "../slices/commonSlice";
import avatarReducer from "../slices/avatarSlice";
import optionReducer from "../slices/heapmapFIlterOptionSlice";

// here we will be adding reducers
const reducer = combineReducers({
  demographicQuestion: demographicReducer,
  demographicAnswer: demographicAnswerReducer,
  feedbackQuestion: feedbackReducer,
  feedbackAnswer: feedbackAnswerSlice,
  setAnswersList: submitAnswerReducer,
  adminLogin: adminLoginReducer,
  roleInfo: roleReducer,
  customBackground: backgroundReducer,
  common: commonReducer,
  avatar: avatarReducer,
  heapMapOptions: optionReducer
});

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
  devTools: false
});
export default store;
