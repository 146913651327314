export const AuditTrailDataView = [
  { value: "tableview", label: "Table View" },
  { value: "graphview", label: "Map View" },
];

export const AuditTrailUnit = [
  { value: "allunits", label: "All Units" },
  { value: "Elysium", label: "Elysium" },
  { value: "unit1", label: "Unit 1" },
  { value: "unit2", label: "Unit 2" },
  { value: "unit3", label: "Unit 3" },
  { value: "unit4", label: "Unit 4" },
];

export const AuditTrailCatList = [
  { value: "both", label: "Both" },
  { value: "child", label: "Service User" },
  { value: "parent", label: "Carers and Support Network" },
];

export const AuditTrailOrder = [
  { value: "desc", label: "Most recent" },
  { value: "asc", label: "Least recent" },
];

export const FeedbackTableData = [
  {
    id: 1,
    unit: "Elysium",
    date: "17/11/2022",
    submissioncount: "27",
    average: "Submissions",
    view: true,
  },
  {
    id: 2,
    unit: "Ely",
    date: "18/11/2022",
    submissioncount: "7",
    average: "Good",
    view: true,
  },
  {
    id: 3,
    unit: "sium",
    date: "19/11/2022",
    submissioncount: "34",
    average: "Low",
    view: true,
  },
  {
    id: 4,
    unit: "Unit-1",
    date: "20/11/2022",
    submissioncount: "34",
    average: "Low",
    view: true,
  },
  {
    id: 5,
    unit: "Unit-2",
    date: "21/11/2022",
    submissioncount: "10",
    average: "Good",
    view: true,
  },
  {
    id: 6,
    unit: "Unit-3",
    date: "22/11/2022",
    submissioncount: "3",
    average: "Good",
    view: true,
  },
  {
    id: 7,
    unit: "Elysium",
    date: "17/11/2022",
    submissioncount: "27",
    average: "Submissions",
    view: true,
  },
  {
    id: 8,
    unit: "Ely",
    date: "18/11/2022",
    submissioncount: "7",
    average: "Good",
    view: true,
  },
  {
    id: 9,
    unit: "sium",
    date: "19/11/2022",
    submissioncount: "34",
    average: "Low",
    view: true,
  },
  {
    id: 10,
    unit: "Unit-1",
    date: "20/11/2022",
    submissioncount: "24",
    average: "Submissions",
    view: true,
  },
  {
    id: 11,
    unit: "Unit-2",
    date: "21/11/2022",
    submissioncount: "30",
    average: "Low",
    view: true,
  },
  {
    id: 12,
    unit: "Unit-3",
    date: "22/11/2022",
    submissioncount: "18",
    average: "Submissions",
    view: true,
  },
];

export const cpyData = [
  { label: "Child", value: "child" },
  { label: "Parent", value: "parent" },
];

export const unitList = [
  { label: "Unit 1", value: "unit-1" },
  { label: "Unit 2", value: "unit-2" },
  { label: "Unit 3", value: "unit-3" },
  { label: "Unit 4", value: "unit-4" },
];

export const commissionerTypes = {
  commissioner: "Commissioner",
  regional: "Regional commissioner",
  senior: "Senior commissioner",
  taskForce: "Task force team",
};

export const patientTypes = {
  cyp: "CYP",
  parent: "Parent",
};
