import React from "react";

const NewLogo = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100%"
      viewBox="0 0 1531 990"
      enable-background="new 0 0 1531 990"
    >
      <path
        fill="#242021"
        opacity="0"
        stroke="none"
        d="
M1001.000000,991.000000 
   C667.333313,991.000000 334.166656,991.000000 1.000000,991.000000 
   C1.000000,661.000000 1.000000,331.000000 1.000000,1.000000 
   C511.333344,1.000000 1021.666687,1.000000 1532.000000,1.000000 
   C1532.000000,331.000000 1532.000000,661.000000 1532.000000,991.000000 
   C1355.166626,991.000000 1178.333374,991.000000 1001.000000,991.000000 
M1319.500000,70.846123 
   C1145.411133,70.846123 971.322327,70.846123 797.339783,70.846123 
   C797.339783,159.190964 797.339783,246.898010 797.339783,334.593018 
   C1016.016052,334.593018 1234.273193,334.593018 1452.728516,334.593018 
   C1452.728516,246.590027 1452.728516,158.871643 1452.728516,70.846123 
   C1408.448364,70.846123 1364.474121,70.846123 1319.500000,70.846123 
M914.210205,494.551819 
   C914.210205,491.103394 914.210205,487.654968 914.210205,484.214233 
   C931.933655,484.214233 948.971497,484.214233 966.455872,484.214233 
   C966.455872,503.243683 966.455872,521.838928 966.455872,540.499451 
   C975.301453,540.499451 983.688843,540.499451 992.213745,540.499451 
   C992.213745,497.097076 992.213745,454.035767 992.213745,410.849731 
   C983.488159,410.849731 975.082642,410.849731 966.071838,410.849731 
   C966.071838,428.374573 966.071838,445.588745 966.071838,462.753662 
   C948.542419,462.753662 931.624939,462.753662 914.081177,462.753662 
   C914.081177,445.266449 914.081177,428.068054 914.081177,410.910919 
   C905.054260,410.910919 896.661865,410.910919 888.401489,410.910919 
   C888.401489,454.361389 888.401489,497.425903 888.401489,540.594849 
   C897.059387,540.594849 905.323486,540.594849 914.210205,540.594849 
   C914.210205,525.414429 914.210205,510.475586 914.210205,494.551819 
M776.917297,536.492920 
   C779.030396,534.740234 781.143433,532.987610 783.981079,530.633972 
   C783.981079,534.428223 783.981079,537.320679 783.981079,540.454651 
   C792.313538,540.454651 800.159668,540.454651 808.181396,540.454651 
   C808.181396,493.829041 808.181396,447.593140 808.181396,401.143158 
   C799.892883,401.143158 791.818237,401.143158 783.232849,401.143158 
   C783.232849,410.170959 783.232849,418.950317 783.232849,427.729675 
   C783.232849,436.601837 783.232849,445.473969 783.232849,455.890076 
   C781.320740,453.739838 780.534912,452.700470 779.596741,451.824188 
   C761.152344,434.598206 729.613342,440.732086 719.519104,463.823242 
   C716.964111,469.667908 715.565491,476.179382 714.620361,482.530975 
   C712.935852,493.851379 713.829285,505.184692 717.416748,516.087769 
   C725.724670,541.337708 752.502869,550.718994 776.917297,536.492920 
M368.989899,729.629700 
   C385.630676,735.114868 400.838409,733.148865 414.533417,719.770874 
   C414.533417,723.878662 414.533417,726.800720 414.533417,729.630249 
   C422.881134,729.630249 430.768341,729.630249 438.627563,729.630249 
   C438.627563,683.002563 438.627563,636.784485 438.627563,590.343506 
   C430.262207,590.343506 422.199982,590.343506 413.568878,590.343506 
   C413.568878,608.291199 413.568878,625.977295 413.568878,643.663391 
   C412.994720,644.046204 412.420532,644.429016 411.846375,644.811829 
   C411.482513,643.883606 411.342407,642.757385 410.721954,642.056152 
   C396.139587,625.575867 357.287018,626.985657 347.909912,657.805176 
   C344.032166,670.550110 343.269684,683.363342 345.512878,696.293884 
   C348.002228,710.643799 353.963226,722.948669 368.989899,729.629700 
M213.490326,497.499817 
   C213.490326,511.789154 213.490326,526.078491 213.490326,540.518677 
   C222.107849,540.518677 230.155197,540.518677 237.840866,540.518677 
   C237.840866,516.534912 239.066971,492.837341 237.454666,469.334442 
   C236.154373,450.379852 220.162247,439.948395 201.052490,442.507141 
   C191.124603,443.836456 182.908890,448.362976 175.537720,457.302551 
   C175.537720,437.561188 175.537720,419.377167 175.537720,401.236633 
   C166.801956,401.236633 158.732101,401.236633 150.624374,401.236633 
   C150.624374,447.838898 150.624374,494.092041 150.624374,540.506470 
   C158.970139,540.506470 167.003906,540.506470 175.464020,540.506470 
   C175.464020,538.370911 175.453552,536.560242 175.465652,534.749695 
   C175.568207,519.419495 175.311111,504.073547 175.974380,488.767548 
   C176.211838,483.287811 178.057144,477.636597 180.266144,472.539307 
   C183.695999,464.624847 192.199905,460.659271 200.491364,462.078674 
   C207.713989,463.315094 212.107285,468.592377 212.972046,478.022156 
   C213.533615,484.145721 213.343643,490.338257 213.490326,497.499817 
M1440.198730,481.519104 
   C1440.113647,478.857422 1440.182983,476.180359 1439.919434,473.536438 
   C1437.239014,446.640411 1413.109131,433.866943 1389.231079,446.886536 
   C1384.980957,449.203888 1381.398438,452.745300 1377.001709,456.108368 
   C1377.001709,437.653168 1377.001709,419.478699 1377.001709,401.210022 
   C1368.364258,401.210022 1360.305420,401.210022 1352.180176,401.210022 
   C1352.180176,447.757507 1352.180176,493.986176 1352.180176,540.510742 
   C1360.482666,540.510742 1368.527710,540.510742 1377.000000,540.510742 
   C1377.000000,538.553467 1376.999878,536.922607 1377.000000,535.291748 
   C1377.001953,521.466675 1376.918823,507.640869 1377.035034,493.816742 
   C1377.114624,484.347656 1378.446777,475.100525 1385.114868,467.726807 
   C1389.992798,462.332703 1396.351440,460.861542 1403.351318,462.459503 
   C1410.169922,464.016083 1413.009033,469.148041 1414.071167,475.426758 
   C1414.705444,479.175720 1414.968018,483.026123 1414.990845,486.833344 
   C1415.088745,503.156342 1415.035767,519.480225 1415.037720,535.803833 
   C1415.037842,537.374878 1415.037720,538.945923 1415.037720,540.589722 
   C1423.783447,540.589722 1431.711548,540.589722 1440.198486,540.589722 
   C1440.198486,521.053833 1440.198486,501.783478 1440.198730,481.519104 
M517.027039,727.489319 
   C535.251160,732.654968 553.558350,734.766968 572.121338,729.169250 
   C595.366760,722.159485 601.668213,701.141357 597.372986,683.132324 
   C594.071838,669.291260 583.659424,662.522217 571.664978,657.323181 
   C563.891113,653.953735 555.824646,651.102051 548.451904,647.005859 
   C539.992310,642.305847 538.855225,630.317017 546.352539,624.160828 
   C549.951233,621.205872 555.228699,618.880920 559.780090,618.819885 
   C567.295166,618.719055 574.848877,620.616577 582.361755,621.824524 
   C584.378418,622.148743 586.306274,623.024963 588.632019,623.760742 
   C589.426941,616.146179 590.146790,609.250244 590.898560,602.048462 
   C573.782532,597.522522 557.028198,595.479187 540.211731,600.620361 
   C524.788147,605.335754 516.089844,616.180054 514.282471,632.145142 
   C512.449951,648.332336 518.369080,660.889771 533.080505,668.619995 
   C537.926147,671.166138 543.087036,673.108093 548.079041,675.380676 
   C553.220947,677.721558 558.648010,679.626892 563.393982,682.608459 
   C574.218323,689.408630 573.649536,702.068420 562.422485,708.162415 
   C559.839966,709.564209 556.860413,710.496582 553.952454,710.989197 
   C543.778442,712.712708 534.099670,710.608948 524.754456,706.568604 
   C522.847961,705.744324 520.946106,704.909485 518.450134,703.821533 
   C517.745178,712.045715 517.109192,719.465759 517.027039,727.489319 
M116.759659,532.302307 
   C133.315628,516.811829 133.884140,490.156128 117.058624,476.747986 
   C110.451050,471.482483 101.940437,468.578491 94.229561,464.733276 
   C88.881355,462.066254 83.187767,460.018005 78.070122,456.982483 
   C72.573524,453.722198 71.008751,448.479126 72.599411,442.260010 
   C74.696457,434.061096 81.204109,428.719391 90.901497,429.178619 
   C100.227577,429.620300 109.480949,431.597137 119.093742,432.945404 
   C119.698265,428.046631 120.323959,422.102509 121.208572,416.197144 
   C121.616699,413.472687 120.833115,412.114502 118.050758,411.808380 
   C107.337875,410.629761 96.646797,408.619568 85.918350,408.409027 
   C76.750191,408.229095 68.003807,411.254028 59.961155,416.240204 
   C41.097267,427.935242 36.955940,465.917725 62.855949,478.729279 
   C72.979683,483.737030 83.489311,487.963623 93.623466,492.951721 
   C98.885338,495.541687 102.028404,500.199219 101.652725,506.292969 
   C101.268311,512.528687 97.477966,516.544800 91.978020,519.187561 
   C82.125183,523.922058 72.192947,522.640991 62.290318,519.586121 
   C57.931046,518.241333 53.717571,516.423889 49.140476,514.709656 
   C48.316658,522.651733 47.534218,530.194946 46.758858,537.669922 
   C60.401028,542.025574 73.703506,543.682312 87.321159,542.633667 
   C97.788330,541.827576 107.705193,539.351868 116.759659,532.302307 
M1157.754761,731.133118 
   C1162.737671,730.197327 1167.720703,729.261597 1172.662842,728.333435 
   C1172.225220,720.636719 1171.833862,713.752563 1171.481445,707.552612 
   C1163.370483,708.924561 1155.936768,710.655151 1148.407471,711.356506 
   C1133.025391,712.789368 1119.219604,709.125610 1108.983154,696.657104 
   C1100.604370,686.451294 1098.041382,674.328613 1098.914429,661.498474 
   C1100.715210,635.032043 1118.818726,616.846130 1144.724121,618.661316 
   C1153.322754,619.263794 1161.789551,621.747070 1170.603394,623.425049 
   C1171.014893,618.766479 1171.148071,613.101135 1172.125610,607.585388 
   C1172.836182,603.576111 1171.127686,602.348328 1167.836426,601.385315 
   C1152.291504,596.837219 1136.575684,596.450500 1120.891846,600.022339 
   C1098.244385,605.180054 1082.309082,618.355835 1075.196289,640.876038 
   C1070.116211,656.960144 1069.723877,673.420898 1074.335449,689.649963 
   C1078.998901,706.060913 1089.305420,718.071655 1105.046143,725.032959 
   C1121.664062,732.382324 1139.058105,733.775085 1157.754761,731.133118 
M697.075439,721.586609 
   C706.631775,713.877991 711.853394,703.658020 713.557739,691.731201 
   C717.756592,662.348755 702.867920,637.017578 672.644043,632.385925 
   C650.707092,629.024170 630.936462,637.609253 620.525208,655.051514 
   C610.275635,672.222900 611.614319,698.318176 623.556885,714.151123 
   C639.314697,735.042236 673.605225,738.766418 697.075439,721.586609 
M1094.420898,536.892578 
   C1095.429443,535.749695 1097.248901,534.647888 1097.309326,533.456909 
   C1097.609375,527.534058 1097.446411,521.587769 1097.446411,515.104492 
   C1093.045044,517.065857 1089.357544,518.877808 1085.546265,520.374634 
   C1075.555298,524.298584 1065.314941,525.893494 1054.783936,522.771545 
   C1045.285034,519.955505 1038.672607,510.824249 1038.918945,500.372528 
   C1060.740356,500.372528 1082.590576,500.372528 1105.300781,500.372528 
   C1104.182617,491.644165 1103.720947,483.636658 1102.055054,475.888031 
   C1097.608765,455.205994 1086.068115,445.006195 1065.189453,442.451874 
   C1042.670410,439.696899 1024.337280,450.932495 1017.367188,472.139038 
   C1013.041748,485.299164 1012.887146,498.739258 1016.839355,512.004333 
   C1021.277771,526.901184 1031.363159,536.628845 1046.287109,540.542786 
   C1062.328979,544.749756 1078.297241,543.965820 1094.420898,536.892578 
M622.499634,500.537659 
   C631.231323,500.537659 639.963013,500.537659 649.222595,500.537659 
   C648.703369,493.754547 648.547668,487.921783 647.765320,482.174377 
   C644.754639,460.057068 634.227905,447.574615 615.370239,443.386414 
   C591.866699,438.166382 570.758057,448.247803 562.854614,468.888153 
   C557.193237,483.673309 556.774414,498.814301 561.789001,513.899719 
   C565.067505,523.762634 571.149719,531.727295 580.511169,536.355713 
   C600.004517,545.993286 619.758606,544.942627 639.353210,536.415405 
   C640.508667,535.912598 641.748718,534.093811 641.805664,532.835693 
   C642.066650,527.065735 641.920410,521.277344 641.920410,514.926941 
   C638.153198,516.689636 634.947021,518.349121 631.620789,519.716736 
   C620.898926,524.125183 609.906738,526.244934 598.525879,522.525330 
   C589.154785,519.462524 582.780457,510.340637 583.191589,500.537628 
   C595.943420,500.537628 608.721497,500.537628 622.499634,500.537659 
M343.159760,500.543671 
   C344.781860,500.471649 346.403961,500.399658 349.204224,500.275330 
   C348.032104,491.488068 347.525421,483.026337 345.690857,474.862976 
   C341.246735,455.087616 329.635437,445.020386 309.514862,442.497314 
   C288.922394,439.915009 271.090302,449.096161 263.422760,466.591522 
   C256.740601,481.838623 256.101013,497.527771 261.171326,513.380859 
   C263.888550,521.876648 269.001892,528.648682 276.187866,534.009766 
   C292.446899,546.139709 323.206299,544.932861 339.310211,536.312012 
   C340.152344,535.861145 341.077881,534.627930 341.101013,533.736572 
   C341.258087,527.678894 341.183167,521.615173 341.183167,515.078125 
   C336.855743,517.063354 333.333160,518.924255 329.637085,520.328674 
   C318.981720,524.377258 308.113129,526.322876 297.023468,522.115234 
   C288.108459,518.732727 281.912323,509.272522 282.944946,500.511414 
   C302.667328,500.511414 322.448090,500.511414 343.159760,500.543671 
M1446.018188,680.572327 
   C1445.320923,675.295959 1444.999023,669.937073 1443.859619,664.757996 
   C1439.634521,645.551331 1427.990356,634.863831 1408.893188,632.238708 
   C1388.766724,629.472168 1372.002808,636.315430 1363.584229,652.264832 
   C1359.876099,659.290283 1357.485352,667.503357 1356.581909,675.415222 
   C1354.650024,692.333618 1357.313599,708.397949 1370.735107,720.772339 
   C1387.774292,736.482117 1420.901855,734.554993 1437.359619,725.706787 
   C1438.142700,725.285767 1439.160767,724.333435 1439.177612,723.607788 
   C1439.322021,717.392395 1439.260498,711.172180 1439.260498,704.321716 
   C1436.618774,705.645325 1434.864380,706.559814 1433.080322,707.412170 
   C1424.103882,711.700195 1414.782959,714.424316 1404.687866,713.901306 
   C1390.744019,713.178833 1381.114014,703.863953 1380.454956,689.861816 
   C1402.221680,689.861816 1423.971069,689.861816 1446.062012,689.861816 
   C1446.062012,686.755798 1446.062012,684.144592 1446.018188,680.572327 
M941.172302,645.409302 
   C937.070068,642.132812 933.450745,637.613159 928.780518,635.799072 
   C910.423828,628.668823 892.163940,631.416687 874.281799,638.289062 
   C873.481873,638.596497 872.627563,639.990967 872.606445,640.897949 
   C872.460510,647.146057 872.529663,653.399048 872.529663,660.709595 
   C875.353333,659.094238 876.901489,658.251465 878.407898,657.339661 
   C887.548950,651.806702 897.461975,649.533142 908.067505,650.560852 
   C919.632385,651.681396 924.927673,658.329285 923.891479,670.850647 
   C918.006287,670.850647 912.048828,670.779541 906.093689,670.866821 
   C896.364929,671.009399 886.815674,672.179932 877.943787,676.556458 
   C860.393250,685.214050 856.357910,709.583069 870.223267,723.032410 
   C883.832825,736.233398 908.969360,735.326538 921.644287,721.174438 
   C922.766113,719.921936 923.868896,718.652344 925.473022,716.831665 
   C925.929565,721.770203 926.302063,725.799438 926.661804,729.691040 
   C934.406067,729.691040 941.480164,729.691040 948.406433,729.691040 
   C947.947449,707.394165 947.850891,685.433472 946.808350,663.517822 
   C946.527893,657.621643 943.371338,651.862305 941.172302,645.409302 
M1147.382446,482.846222 
   C1144.472168,483.544098 1141.489014,484.025391 1138.663330,484.975220 
   C1121.105103,490.877441 1112.845947,509.547882 1120.408691,526.043762 
   C1125.559937,537.279480 1141.306641,544.649963 1155.669800,542.677917 
   C1166.034668,541.254761 1174.348267,536.387634 1181.009644,527.276855 
   C1181.590820,532.326660 1182.070312,536.493042 1182.531982,540.504395 
   C1190.202148,540.504395 1197.275879,540.504395 1203.076172,540.504395 
   C1203.076172,519.167480 1203.271362,498.198120 1202.934814,477.237335 
   C1202.853760,472.188416 1201.470581,467.016785 1199.877808,462.167053 
   C1196.975708,453.330566 1190.515015,447.697723 1181.726562,445.027802 
   C1164.392700,439.761810 1147.473755,442.114777 1130.915161,448.519287 
   C1129.806396,448.948151 1128.367432,450.496002 1128.374390,451.514557 
   C1128.416748,457.733276 1128.832764,463.949432 1129.153076,470.782043 
   C1131.373413,469.498291 1132.916382,468.604553 1134.460938,467.713501 
   C1143.655640,462.408875 1153.471436,459.915771 1164.126709,461.011993 
   C1175.238647,462.155151 1181.096924,469.639343 1179.594971,480.420227 
   C1169.083252,481.195526 1158.660522,481.964294 1147.382446,482.846222 
M1198.478516,728.090759 
   C1216.926392,736.413330 1232.898193,732.991394 1246.603271,717.063171 
   C1246.998047,721.834167 1247.319824,725.722473 1247.641113,729.605469 
   C1255.379517,729.605469 1262.583496,729.605469 1270.018433,729.605469 
   C1269.720459,724.188171 1269.324585,719.215027 1269.195312,714.234985 
   C1268.755005,697.265442 1268.800659,680.272888 1267.911011,663.328552 
   C1267.089355,647.679504 1258.985474,637.391968 1245.290283,633.941040 
   C1228.608398,629.737427 1212.244385,631.639221 1196.361572,637.952759 
   C1195.260620,638.390442 1193.894897,639.914307 1193.854492,640.978760 
   C1193.617310,647.217224 1193.738037,653.469299 1193.738037,659.378906 
   C1194.884644,659.738892 1195.085693,659.874573 1195.181763,659.821228 
   C1196.480347,659.099548 1197.772461,658.365234 1199.050537,657.607727 
   C1209.446411,651.447083 1220.551880,648.744507 1232.553345,651.138611 
   C1241.696533,652.962585 1246.791504,661.016174 1244.993652,670.749390 
   C1241.583374,670.749390 1238.093994,670.544312 1234.636597,670.789856 
   C1226.023682,671.401428 1217.248901,671.379944 1208.862061,673.145813 
   C1195.773193,675.901550 1186.052490,683.101379 1183.723145,697.452515 
   C1181.527466,710.979431 1185.870117,720.336731 1198.478516,728.090759 
M162.184586,732.382263 
   C173.539856,731.525818 182.777817,726.682190 190.067520,716.546814 
   C190.574921,721.661621 190.973907,725.683716 191.361206,729.587830 
   C199.060211,729.587830 206.253937,729.587830 213.586288,729.587830 
   C213.416672,726.473267 213.199341,723.825989 213.139862,721.175171 
   C212.721771,702.535889 212.700180,683.879028 211.834320,665.261108 
   C211.023453,647.825500 202.705597,637.014832 187.952774,633.642273 
   C171.585434,629.900635 155.533096,631.753113 139.970154,637.898254 
   C138.899200,638.321167 137.329834,639.503052 137.338211,640.318115 
   C137.405121,646.820862 137.797089,653.320312 138.115402,660.398499 
   C140.318024,659.052612 141.701477,658.162964 143.125626,657.344238 
   C153.589584,651.328125 164.712006,648.653259 176.692001,651.255920 
   C185.635620,653.198853 190.451004,661.225891 188.409683,670.874207 
   C182.372070,670.874207 176.145004,670.226440 170.109894,671.026550 
   C161.122864,672.218018 151.852814,673.322632 143.466446,676.486938 
   C132.578522,680.595215 127.488289,689.895508 126.878738,701.600769 
   C126.257431,713.531921 131.214508,722.650024 141.715530,727.653687 
   C147.657822,730.485168 154.753433,730.896179 162.184586,732.382263 
M299.853241,718.481689 
   C299.853241,722.229309 299.853241,725.976990 299.853241,729.606079 
   C308.683502,729.606079 316.731018,729.606079 324.874451,729.606079 
   C324.874451,707.708740 325.025208,686.232300 324.749725,664.761353 
   C324.693359,660.365662 323.437439,655.858948 322.040192,651.632935 
   C319.041443,642.563354 313.079926,635.770935 303.685791,633.455139 
   C288.408325,629.689087 274.227020,631.586182 262.972260,644.034302 
   C262.426727,644.637695 261.704529,645.081360 260.409454,646.129822 
   C260.409454,641.653809 260.409454,638.036499 260.409454,634.281860 
   C252.321045,634.281860 244.626846,634.281860 237.108368,634.281860 
   C237.108368,666.373901 237.108368,698.071167 237.108368,729.734497 
   C245.476837,729.734497 253.396103,729.734497 261.841370,729.734497 
   C261.841370,727.823364 261.841675,726.202881 261.841309,724.582397 
   C261.838226,710.759583 261.807922,696.936768 261.841980,683.114014 
   C261.862366,674.841553 263.091431,666.849182 267.728973,659.726685 
   C271.979980,653.197815 279.745636,650.116760 287.796875,651.829651 
   C295.197968,653.404358 297.731995,659.093445 298.930298,665.595642 
   C299.589508,669.172607 299.795898,672.872498 299.821747,676.519775 
   C299.918518,690.175293 299.856598,703.831970 299.853241,718.481689 
M486.428070,418.023041 
   C489.702057,418.883698 492.976044,419.744324 496.582764,420.692444 
   C497.211670,413.889069 497.817902,407.331421 498.511993,399.822998 
   C491.058563,399.345520 484.113281,398.222046 477.250244,398.598511 
   C461.730408,399.449860 451.522614,409.668457 449.953857,425.238342 
   C449.488159,429.860199 449.674194,434.546814 449.534027,439.202911 
   C449.481018,440.963806 449.350250,442.722321 449.235321,444.843750 
   C442.879059,444.843750 437.107391,444.843750 431.230957,444.843750 
   C431.230957,451.004730 431.230957,456.764832 431.230957,463.082703 
   C437.369720,463.082703 443.275482,463.082703 449.602112,463.082703 
   C449.602112,489.221832 449.602112,514.804504 449.602112,540.515686 
   C458.092255,540.515686 466.137268,540.515686 474.713135,540.515686 
   C474.713135,514.645691 474.713135,489.097961 474.713135,463.076111 
   C482.152771,463.076111 489.069244,463.076111 495.994568,463.076111 
   C495.994568,456.697266 495.994568,450.805939 495.994568,444.400452 
   C488.796692,444.400452 481.904968,444.400452 474.962341,444.400452 
   C474.962341,438.752167 474.583923,433.587433 475.056519,428.501801 
   C475.670990,421.889984 478.896362,419.030853 486.428070,418.023041 
M377.020203,496.500000 
   C377.020203,511.097626 377.020203,525.695251 377.020203,540.476318 
   C385.604370,540.476318 393.643829,540.476318 402.214355,540.476318 
   C402.214355,514.569702 402.214355,489.020630 402.214355,463.025452 
   C409.652557,463.025452 416.568115,463.025452 423.609833,463.025452 
   C423.609833,456.684021 423.609833,450.800903 423.609833,444.478699 
   C416.363159,444.478699 409.440186,444.478699 401.845093,444.478699 
   C402.167450,438.409729 402.002014,432.736450 402.876740,427.228394 
   C404.029327,419.971008 409.762512,416.642822 416.902222,418.477509 
   C419.200928,419.068176 421.397308,420.056976 423.920837,420.964447 
   C424.332336,417.233429 424.732452,413.939850 425.049377,410.638275 
   C425.373688,407.260101 425.616547,403.874084 425.947418,399.855225 
   C418.694244,399.343506 412.235199,398.407318 405.796387,398.530304 
   C390.914520,398.814636 380.238251,408.090973 377.872772,422.753021 
   C377.005402,428.129333 377.273315,433.688660 377.020996,439.164398 
   C376.940643,440.908539 376.852570,442.652313 376.748199,444.804443 
   C370.427734,444.804443 364.673553,444.804443 358.941345,444.804443 
   C358.941345,451.119171 358.941345,456.978333 358.941345,463.233978 
   C365.097412,463.233978 370.858856,463.233978 377.020203,463.233978 
   C377.020203,474.259766 377.020203,484.879883 377.020203,496.500000 
M232.319778,840.102051 
   C225.894150,827.509644 219.521118,814.889832 212.976440,802.359619 
   C212.158234,800.793152 210.498657,798.654236 209.116440,798.567261 
   C202.697632,798.163086 196.239197,798.388367 189.782959,798.388367 
   C189.782959,827.756104 189.782959,856.451660 189.782959,885.189819 
   C195.428146,885.189819 200.677292,885.189819 206.555908,885.189819 
   C206.555908,864.287048 206.555908,843.679810 206.555908,823.072510 
   C207.889648,823.579590 208.282730,824.243164 208.633759,824.928406 
   C218.347214,843.888123 228.028458,862.864441 237.822342,881.782532 
   C238.563034,883.213196 240.046631,885.211060 241.275543,885.277893 
   C247.722977,885.628540 254.199646,885.441895 260.778564,885.441895 
   C260.778564,856.260681 260.778564,827.535339 260.778564,798.855225 
   C255.103699,798.855225 249.741287,798.855225 243.891144,798.855225 
   C243.891144,819.828857 243.891144,840.340210 243.891144,860.851562 
   C243.584061,860.919739 243.276978,860.987976 242.969879,861.056152 
   C239.537354,854.291077 236.104813,847.526001 232.319778,840.102051 
M1315.351929,518.955322 
   C1314.569336,516.431763 1313.140259,513.916016 1313.109619,511.383423 
   C1312.919312,495.559998 1313.038452,479.732758 1313.087524,463.906921 
   C1313.088257,463.667694 1313.503418,463.429749 1314.062866,462.828033 
   C1320.660400,462.828033 1327.593628,462.828033 1334.718262,462.828033 
   C1334.718262,456.690979 1334.718262,450.919556 1334.718262,444.672913 
   C1327.495605,444.672913 1320.414795,444.672913 1312.725830,444.672913 
   C1312.725830,435.372589 1312.725830,426.531281 1312.725830,417.278931 
   C1305.079346,419.728790 1298.170654,422.123871 1291.145996,424.108612 
   C1288.099121,424.969513 1287.337402,426.590454 1287.437134,429.466888 
   C1287.608398,434.406586 1287.484375,439.356537 1287.484375,444.751190 
   C1281.074707,444.751190 1275.322388,444.751190 1269.527588,444.751190 
   C1269.527588,451.095093 1269.527588,456.969910 1269.527588,463.285461 
   C1275.679688,463.285461 1281.441162,463.285461 1287.496948,463.285461 
   C1287.496948,465.390198 1287.497437,467.042725 1287.496826,468.695251 
   C1287.490845,482.693451 1287.346924,496.693329 1287.519043,510.689331 
   C1287.677490,523.581726 1291.078613,535.018127 1304.308105,540.399475 
   C1314.923340,544.717407 1325.770752,543.123352 1336.765259,540.712585 
   C1336.422974,533.953064 1336.106689,527.708435 1335.788208,521.418640 
   C1328.718628,522.758179 1321.814575,525.859497 1315.351929,518.955322 
M728.715393,669.207397 
   C728.326599,672.004272 727.671387,674.793640 727.592590,677.599243 
   C727.108826,694.827698 729.905396,711.141479 744.328308,722.562256 
   C760.659363,735.494019 779.389099,733.745544 798.184570,729.634155 
   C798.869629,729.484314 799.839417,728.285828 799.814636,727.607605 
   C799.587830,721.405823 799.169861,715.210999 798.805603,708.977844 
   C793.312439,710.263855 788.521423,711.698608 783.625854,712.470337 
   C771.143127,714.438110 761.083923,708.815613 756.529968,697.107727 
   C752.781982,687.472229 752.694031,677.493835 756.156555,667.771484 
   C760.107544,656.677368 768.504761,651.042358 780.319763,651.528625 
   C785.500000,651.741821 790.635681,653.040161 796.236938,653.915283 
   C796.898743,647.928467 797.605225,641.537842 798.440552,633.981445 
   C790.265808,633.254456 782.558044,632.067444 774.837280,631.975037 
   C750.203125,631.680298 734.523132,644.293884 728.715393,669.207397 
M297.738922,854.499268 
   C297.738922,852.231628 297.738922,849.963989 297.738922,847.716309 
   C309.522522,847.716309 320.617981,847.716309 332.253540,847.716309 
   C332.253540,860.315308 332.253540,872.695190 332.253540,885.164185 
   C338.387238,885.164185 343.973907,885.164185 349.737091,885.164185 
   C349.737091,856.261353 349.737091,827.673950 349.737091,798.773071 
   C343.824860,798.773071 338.118011,798.773071 332.046021,798.773071 
   C332.046021,810.425354 332.046021,821.699341 332.046021,833.199219 
   C320.488464,833.199219 309.299744,833.199219 297.519287,833.199219 
   C297.519287,821.480835 297.519287,810.103943 297.519287,798.836975 
   C291.377106,798.836975 285.838623,798.836975 280.341125,798.836975 
   C280.341125,827.856323 280.341125,856.427856 280.341125,885.176270 
   C286.200684,885.176270 291.782867,885.176270 297.739044,885.176270 
   C297.739044,875.089050 297.739044,865.296692 297.738922,854.499268 
M997.552124,722.368713 
   C997.552124,678.350525 997.552124,634.332336 997.552124,590.363098 
   C988.717407,590.363098 980.795837,590.363098 972.677856,590.363098 
   C972.677856,637.001099 972.677856,683.343079 972.677856,729.865051 
   C981.004944,729.865051 988.957336,729.865051 997.551147,729.865051 
   C997.551147,727.569824 997.551147,725.448914 997.552124,722.368713 
M1228.061157,475.500000 
   C1228.061157,497.094788 1228.061157,518.689575 1228.061157,540.536133 
   C1236.676636,540.536133 1244.727051,540.536133 1252.847168,540.536133 
   C1252.847168,493.878876 1252.847168,447.524658 1252.847168,401.268066 
   C1244.458130,401.268066 1236.399292,401.268066 1228.061157,401.268066 
   C1228.061157,425.927856 1228.061157,450.213928 1228.061157,475.500000 
M668.778320,500.499908 
   C668.778320,513.780579 668.778320,527.061218 668.778320,540.550781 
   C677.394653,540.550781 685.457764,540.550781 693.627319,540.550781 
   C693.627319,493.877716 693.627319,447.501068 693.627319,401.229797 
   C685.191162,401.229797 677.105469,401.229797 668.778320,401.229797 
   C668.778320,434.208435 668.778320,466.854156 668.778320,500.499908 
M784.497681,819.879028 
   C770.845398,820.257507 762.328064,827.032776 759.223755,840.245789 
   C756.973022,849.825623 757.118103,859.433838 759.988770,868.873291 
   C763.472595,880.328430 772.191833,886.672119 784.055054,886.989319 
   C791.711548,887.194031 798.233276,884.642029 804.000732,879.159729 
   C804.264221,881.481995 804.465149,883.252747 804.683228,885.175171 
   C810.143677,885.175171 815.387756,885.175171 820.460083,885.175171 
   C820.460083,853.998901 820.460083,823.123901 820.460083,792.417297 
   C814.788269,792.417297 809.531189,792.417297 803.838501,792.417297 
   C803.838501,804.144409 803.838501,815.530334 803.838501,828.027954 
   C798.136292,822.679810 792.427063,819.619019 784.497681,819.879028 
M1293.540894,650.503113 
   C1293.540894,676.915283 1293.540894,703.327393 1293.540894,729.621033 
   C1302.370605,729.621033 1310.431519,729.621033 1318.781006,729.621033 
   C1318.781006,716.235107 1318.373413,703.242676 1318.934570,690.292175 
   C1319.269043,682.571838 1320.341675,674.687988 1322.507935,667.295288 
   C1324.850708,659.299438 1330.979126,655.848999 1339.405396,655.997375 
   C1342.842407,656.057922 1346.270142,656.652466 1349.841431,657.018250 
   C1349.841431,648.903809 1349.841431,640.811890 1349.841431,632.484314 
   C1332.787476,629.373108 1322.977539,638.173279 1315.584961,651.983398 
   C1315.584961,646.049561 1315.584961,640.115723 1315.584961,634.223450 
   C1308.059570,634.223450 1300.995361,634.223450 1294.042114,634.223450 
   C1293.832642,634.889160 1293.651733,635.200684 1293.648315,635.514038 
   C1293.598633,640.177795 1293.572510,644.841736 1293.540894,650.503113 
M387.931610,828.523743 
   C384.332184,823.615051 384.013062,818.701843 388.239655,815.231079 
   C390.971924,812.987427 395.003754,811.204834 398.453369,811.204529 
   C404.442230,811.204041 410.431244,812.744019 416.722839,813.683105 
   C417.150116,809.405273 417.606506,804.835632 418.067780,800.217102 
   C407.845856,796.864441 397.807159,795.868591 387.670807,798.048767 
   C375.584534,800.648499 368.454468,808.395325 367.208038,819.587463 
   C365.794708,832.278320 370.354980,840.244385 382.410339,845.850159 
   C387.534790,848.232910 392.815277,850.281616 397.925934,852.691895 
   C402.062775,854.642822 405.251343,857.594421 404.856140,862.651550 
   C404.449188,867.858826 400.762085,870.441528 396.273407,871.936340 
   C394.260529,872.606689 391.959534,873.214417 389.935730,872.901917 
   C383.313293,871.879456 376.758179,870.421204 369.732666,869.043274 
   C369.320435,873.413147 368.846619,878.436096 368.377716,883.406616 
   C380.798004,887.266357 392.740082,888.548889 404.825684,885.051636 
   C416.015900,881.813416 422.128357,874.605225 423.092499,863.909546 
   C424.184723,851.793762 419.793091,843.886475 408.790710,838.459778 
   C405.814148,836.991577 402.632690,835.943787 399.621094,834.540344 
   C395.866669,832.790833 392.190643,830.873047 387.931610,828.523743 
M547.115845,823.530396 
   C528.374329,832.592651 525.683105,858.408691 535.297485,872.879639 
   C544.923096,887.367554 566.386414,891.515259 582.076111,882.278198 
   C596.045837,874.053772 601.506470,853.184265 593.756165,837.638977 
   C585.626892,821.333313 566.064514,815.173706 547.115845,823.530396 
M1051.139526,842.438660 
   C1045.569214,826.255676 1032.472290,818.252014 1014.514099,820.056580 
   C1000.713074,821.443298 990.185913,830.420898 986.819519,843.674622 
   C983.009033,858.676514 987.769104,873.438049 998.901917,881.142944 
   C1010.227600,888.981445 1028.211792,888.982361 1039.471191,881.145264 
   C1050.723389,873.313293 1055.289551,858.794373 1051.139526,842.438660 
M1124.543823,865.499451 
   C1124.406250,856.683960 1124.848755,847.811768 1123.994263,839.066284 
   C1122.733276,826.157776 1114.705078,819.722717 1101.739258,819.854492 
   C1094.046875,819.932617 1087.599487,822.685791 1082.065674,829.100952 
   C1081.838989,825.999939 1081.676758,823.779785 1081.501831,821.385498 
   C1076.149536,821.385498 1071.093018,821.385498 1065.983643,821.385498 
   C1065.983643,842.869507 1065.983643,864.067627 1065.983643,885.096680 
   C1071.653564,885.096680 1076.906860,885.096680 1082.413086,885.096680 
   C1082.413086,874.920471 1081.992188,865.091309 1082.577393,855.322388 
   C1082.906738,849.823608 1084.182007,844.120361 1086.335449,839.064636 
   C1088.542236,833.883423 1094.654175,831.814880 1099.943848,833.275269 
   C1105.009766,834.673950 1107.087891,838.655273 1107.211426,843.481018 
   C1107.522095,855.624451 1107.544556,867.775330 1107.676270,879.923218 
   C1107.695312,881.686218 1107.678955,883.449585 1107.678955,885.185974 
   C1113.643433,885.185974 1118.905029,885.185974 1124.543823,885.185974 
   C1124.543823,878.751831 1124.543823,872.625366 1124.543823,865.499451 
M538.585449,511.500305 
   C538.585449,489.392914 538.585449,467.285522 538.585449,445.034851 
   C529.889954,445.034851 521.705688,445.034851 513.736938,445.034851 
   C513.736938,477.178925 513.736938,508.896942 513.736938,540.599243 
   C522.120605,540.599243 530.059265,540.599243 538.585388,540.599243 
   C538.585388,531.067383 538.585388,521.783875 538.585449,511.500305 
M863.989990,832.210388 
   C872.858093,834.266846 875.405762,837.372437 874.702637,845.790771 
   C871.113953,845.790771 867.438416,845.442200 863.855408,845.869629 
   C857.954895,846.573547 851.908264,847.134766 846.296997,848.926514 
   C838.035889,851.564392 833.537659,858.724243 833.498840,867.251282 
   C833.462402,875.277466 837.820312,881.564819 845.768433,884.953247 
   C855.285095,889.010498 865.676941,887.062317 872.980347,879.882019 
   C873.721252,879.153625 874.590210,878.555542 875.665710,877.680603 
   C876.100403,880.605469 876.453491,882.981445 876.782654,885.196289 
   C881.955261,885.196289 886.554382,885.196289 890.783569,885.196289 
   C890.783569,878.933044 890.871765,872.966431 890.763123,867.003296 
   C890.593506,857.689941 890.701538,848.344849 889.931519,839.076355 
   C889.119934,829.308411 883.261047,822.946777 874.035400,820.956665 
   C863.691345,818.725159 853.489380,819.641907 843.636047,823.483337 
   C842.416809,823.958740 840.953308,825.572815 840.835449,826.779602 
   C840.470947,830.510864 840.702393,834.300293 840.702393,837.107300 
   C848.549438,835.376648 855.848083,833.766968 863.989990,832.210388 
M686.031555,883.979492 
   C691.493591,884.662170 696.955566,885.344849 702.275452,886.009766 
   C702.605835,884.389099 702.729431,884.063904 702.730225,883.738525 
   C702.754089,873.915039 702.434570,864.075073 702.919861,854.274963 
   C703.160767,849.409973 704.290649,844.386902 706.078613,839.853516 
   C708.152222,834.596008 713.695312,832.244995 719.299438,833.116882 
   C724.942871,833.994873 726.794861,838.147888 727.451355,843.059692 
   C727.868652,846.181274 728.004395,849.358398 728.023743,852.512085 
   C728.090088,863.310730 728.049194,874.109985 728.049194,885.129272 
   C733.801086,885.129272 739.205322,885.129272 744.752747,885.129272 
   C744.752747,870.146118 745.229492,855.490234 744.616821,840.880005 
   C744.020264,826.654114 735.157898,819.412476 720.905884,819.870422 
   C713.550659,820.106812 707.688232,823.346863 702.185974,829.007324 
   C701.901733,825.868469 701.702576,823.669617 701.498291,821.414062 
   C696.185181,821.414062 691.285583,821.414062 685.993164,821.414062 
   C685.993164,842.165100 685.993164,862.614075 686.031555,883.979492 
M652.083496,830.558716 
   C652.082825,838.058655 652.270691,845.565430 651.999695,853.055603 
   C651.869263,856.662415 651.342651,860.399292 650.174255,863.794739 
   C647.583496,871.323853 642.133179,874.708496 635.427063,873.623352 
   C630.009216,872.746643 627.100464,868.305420 627.008789,860.102295 
   C626.878540,848.437134 626.976318,836.769531 626.945557,825.103027 
   C626.942200,823.845459 626.732544,822.588501 626.623962,821.390625 
   C620.961243,821.390625 615.695312,821.390625 609.886353,821.390625 
   C609.886353,832.779480 609.789612,843.899109 609.929382,855.015747 
   C609.994080,860.162476 610.040710,865.388977 610.940979,870.428284 
   C612.565430,879.521423 618.915466,885.533752 627.576111,886.731689 
   C635.549194,887.834656 642.777039,885.894104 649.040161,880.703125 
   C650.124207,879.804688 651.238892,878.943237 652.774719,877.717224 
   C652.920349,880.632080 653.031921,882.864136 653.144897,885.124512 
   C658.561157,885.124512 663.639526,885.124512 668.642578,885.124512 
   C668.642578,863.686829 668.642578,842.602234 668.642578,821.417847 
   C663.070496,821.417847 657.827148,821.417847 652.083496,821.417847 
   C652.083496,824.328125 652.083496,826.953796 652.083496,830.558716 
M1341.048340,824.712708 
   C1340.359985,823.519165 1339.734619,821.340942 1338.972900,821.292175 
   C1334.077148,820.978943 1329.151611,821.132141 1324.027710,821.132141 
   C1324.027710,832.767883 1324.504150,843.606812 1323.818481,854.371704 
   C1323.496094,859.431213 1321.654907,864.743469 1319.189453,869.218018 
   C1316.751831,873.642273 1311.714111,874.490601 1306.800903,873.448364 
   C1302.008179,872.431641 1299.989624,868.731995 1299.574829,864.355103 
   C1299.074097,859.071533 1299.081055,853.731750 1299.035522,848.414917 
   C1298.959106,839.483154 1299.014648,830.550232 1299.014648,821.427124 
   C1293.011963,821.427124 1287.629028,821.427124 1282.129517,821.427124 
   C1282.129517,836.525146 1281.610352,851.334167 1282.292603,866.087646 
   C1282.846313,878.059998 1290.097046,885.652161 1300.227905,886.812317 
   C1308.042358,887.707214 1315.053955,885.696899 1321.175171,880.696167 
   C1322.244263,879.822693 1323.367065,879.014954 1325.091797,877.698486 
   C1325.091797,880.772766 1325.091797,882.903870 1325.091797,885.236023 
   C1330.474243,885.236023 1335.572632,885.236023 1341.048828,885.236023 
   C1341.048828,865.223083 1341.048828,845.447571 1341.048340,824.712708 
M517.134033,812.350281 
   C518.607788,812.278198 520.081543,812.206116 521.677612,812.128052 
   C521.677612,807.577881 521.677612,803.312134 521.677612,798.765015 
   C505.601440,798.765015 489.744293,798.765015 473.860535,798.765015 
   C473.860535,827.810303 473.860535,856.489929 473.860535,885.210083 
   C479.669830,885.210083 485.113037,885.210083 491.038605,885.210083 
   C491.038605,872.542053 491.038605,860.132568 491.038605,847.309326 
   C500.953217,847.309326 510.386017,847.309326 519.957886,847.309326 
   C519.957886,842.587219 519.957886,838.292419 519.957886,833.357727 
   C510.267731,833.357727 500.736725,833.357727 491.191132,833.357727 
   C491.191132,826.115051 491.191132,819.514404 491.191132,812.340515 
   C499.662781,812.340515 507.933411,812.340515 517.134033,812.350281 
M816.985413,687.500000 
   C816.985413,701.584778 816.985413,715.669556 816.985413,729.593872 
   C825.788757,729.593872 833.843750,729.593872 841.802063,729.593872 
   C841.802063,697.561157 841.802063,665.968811 841.802063,634.250916 
   C833.448486,634.250916 825.403320,634.250916 816.985413,634.250916 
   C816.985413,651.903137 816.985413,669.201538 816.985413,687.500000 
M1213.500244,798.392273 
   C1198.592773,798.392273 1183.685181,798.392273 1168.764404,798.392273 
   C1168.764404,803.494324 1168.764404,807.736450 1168.764404,812.497559 
   C1176.972290,812.497559 1184.876831,812.497559 1193.367188,812.497559 
   C1193.367188,837.044739 1193.367188,861.070251 1193.367188,885.153076 
   C1199.560059,885.153076 1205.142090,885.153076 1211.265503,885.153076 
   C1211.265503,860.779846 1211.265503,836.735229 1211.265503,812.227783 
   C1219.746460,812.227783 1227.688965,812.227783 1235.632935,812.227783 
   C1235.632935,807.487854 1235.632935,803.225342 1235.632935,798.392334 
   C1228.415283,798.392334 1221.457642,798.392334 1213.500244,798.392273 
M1377.620850,819.876587 
   C1372.692627,821.173828 1367.416138,821.752197 1362.915527,823.932190 
   C1355.692871,827.430786 1353.299194,834.115540 1353.917114,841.925476 
   C1354.495972,849.239807 1358.551392,853.817810 1365.304199,856.191528 
   C1369.223755,857.569275 1373.213257,858.753418 1377.107056,860.197693 
   C1380.440552,861.434021 1383.058960,863.521301 1382.683350,867.519409 
   C1382.290771,871.697510 1379.227905,873.393494 1375.556641,874.058228 
   C1373.615356,874.409790 1371.522339,874.625610 1369.607666,874.285034 
   C1364.937988,873.454407 1360.332153,872.263733 1355.351074,871.139099 
   C1355.075806,875.379578 1354.800171,879.626831 1354.506348,884.154236 
   C1365.740845,887.053833 1376.605347,888.796875 1387.544067,884.381958 
   C1396.315552,880.841553 1400.409546,874.791992 1400.352783,865.578674 
   C1400.298950,856.841614 1396.469849,851.667236 1387.335571,848.201355 
   C1383.448975,846.726624 1379.317261,845.783081 1375.640137,843.920654 
   C1373.900513,843.039673 1371.836548,840.477295 1371.963501,838.834473 
   C1372.114136,836.882202 1374.112915,834.521179 1375.957275,833.406860 
   C1377.782349,832.304077 1380.449463,832.087402 1382.681519,832.273193 
   C1387.084961,832.639771 1391.443115,833.553162 1396.266724,834.310608 
   C1396.585938,830.200439 1396.890381,826.280090 1397.261108,821.507141 
   C1390.744507,820.935730 1384.661987,820.402344 1377.620850,819.876587 
M1418.732666,853.501587 
   C1418.763306,858.657837 1418.567383,863.827637 1418.873413,868.967468 
   C1419.448486,878.632690 1425.065063,885.364868 1434.198242,886.554749 
   C1439.657227,887.265991 1445.284668,886.685181 1450.831055,886.685181 
   C1450.831055,881.192139 1450.831055,877.127747 1450.831055,872.818787 
   C1448.307739,873.150940 1446.214966,873.578125 1444.107056,873.673584 
   C1439.130371,873.898682 1436.065063,871.917236 1435.885986,867.065002 
   C1435.483521,856.166809 1435.759766,845.243591 1435.759766,833.794495 
   C1440.495483,833.794495 1445.077271,833.794495 1449.929443,833.794495 
   C1449.929443,829.378845 1449.929443,825.502319 1449.929443,820.960938 
   C1444.946777,820.960938 1440.255371,820.960938 1435.110962,820.960938 
   C1435.110962,814.736755 1435.110962,809.175476 1435.110962,803.243652 
   C1429.397949,805.056763 1424.232178,806.696167 1418.576416,808.491089 
   C1418.576416,812.640076 1418.576416,816.715515 1418.576416,821.216675 
   C1414.260376,821.216675 1410.500977,821.216675 1406.532593,821.216675 
   C1406.532593,825.554993 1406.532593,829.446411 1406.532593,833.753235 
   C1410.595947,833.753235 1414.361084,833.753235 1418.732788,833.753235 
   C1418.732788,840.271118 1418.732788,846.387024 1418.732666,853.501587 
M901.844971,833.807556 
   C905.133728,833.807556 908.422485,833.807556 912.220642,833.807556 
   C912.220642,835.882019 912.210327,837.499756 912.222290,839.117371 
   C912.296204,849.110962 911.998535,859.125671 912.558594,869.092102 
   C913.115906,879.010559 918.763428,885.346313 928.008118,886.551697 
   C933.469421,887.263733 939.099365,886.681885 944.493774,886.681885 
   C944.493774,881.171021 944.493774,877.106995 944.493774,872.816528 
   C941.866821,873.175781 939.757446,873.585083 937.630005,873.730286 
   C933.070618,874.041382 929.761230,871.734192 929.598389,867.368469 
   C929.185425,856.298340 929.461304,845.202454 929.461304,833.554382 
   C934.409912,833.554382 938.988708,833.554382 943.395630,833.554382 
   C943.395630,829.090942 943.395630,825.206970 943.395630,820.951660 
   C938.496887,820.951660 934.053589,820.951660 929.124695,820.951660 
   C929.124695,817.784607 929.124695,814.998291 929.124695,812.211914 
   C929.124695,809.316589 929.124695,806.421265 929.124695,803.144836 
   C926.124878,804.081970 923.913940,804.772095 921.703308,805.463196 
   C912.378662,808.378662 912.384155,808.378967 912.220398,817.934937 
   C912.204224,818.879517 912.069397,819.822205 911.951355,821.218323 
   C907.890930,821.218323 904.135010,821.218323 899.391052,821.218323 
   C899.949768,825.734558 900.437988,829.680359 901.844971,833.807556 
M1250.922852,862.553284 
   C1251.174561,858.406799 1251.156860,854.222839 1251.728638,850.120972 
   C1253.396484,838.158142 1258.695190,834.488464 1270.636230,836.678955 
   C1270.902588,836.727844 1271.219971,836.498047 1271.871582,836.276489 
   C1271.871582,832.092346 1271.722412,827.784485 1271.922485,823.492920 
   C1272.063110,820.474548 1270.608765,819.944397 1268.021240,819.840027 
   C1260.724609,819.545471 1255.720703,823.138123 1251.875854,828.874329 
   C1251.073975,830.070557 1250.409546,831.358948 1249.681763,832.604858 
   C1249.351929,832.467407 1249.021973,832.329895 1248.692139,832.192383 
   C1248.692139,828.607117 1248.692139,825.021851 1248.692139,821.383118 
   C1243.692505,821.383118 1239.111572,821.383118 1234.363525,821.383118 
   C1234.363525,842.811523 1234.363525,863.967163 1234.363525,885.192505 
   C1240.004150,885.192505 1245.265137,885.192505 1250.919067,885.192505 
   C1250.919067,877.778748 1250.919067,870.658447 1250.922852,862.553284 
M955.506714,868.488770 
   C955.506714,874.058044 955.506714,879.627380 955.506714,885.076172 
   C961.604614,885.076172 966.844421,885.076172 972.012207,885.076172 
   C972.012207,863.638062 972.012207,842.579712 972.012207,821.410156 
   C966.427490,821.410156 961.191833,821.410156 955.507141,821.410156 
   C955.507141,836.989197 955.507141,852.288086 955.506714,868.488770 
M513.307068,416.461365 
   C513.307068,419.897552 513.307068,423.333710 513.307068,426.949036 
   C521.954529,426.949036 530.015991,426.949036 538.270508,426.949036 
   C538.270508,418.970276 538.270508,411.256500 538.270508,403.362915 
   C529.922546,403.362915 521.892151,403.362915 513.306763,403.362915 
   C513.306763,407.587738 513.306763,411.535370 513.307068,416.461365 
M838.380615,592.787476 
   C831.305786,592.787476 824.230957,592.787476 817.357910,592.787476 
   C817.357910,601.222290 817.357910,608.787109 817.357910,616.392273 
   C825.691833,616.392273 833.622375,616.392273 842.093628,616.392273 
   C842.093628,608.999207 842.167725,601.879761 841.974365,594.767639 
   C841.955811,594.086853 840.269958,593.451355 838.380615,592.787476 
M955.499756,801.532959 
   C955.499756,804.254272 955.499756,806.975525 955.499756,809.600708 
   C961.577209,809.600708 966.782654,809.600708 972.169678,809.600708 
   C972.169678,804.205078 972.169678,799.158936 972.169678,793.925049 
   C966.596924,793.925049 961.247070,793.925049 955.500610,793.925049 
   C955.500610,796.356201 955.500610,798.486694 955.499756,801.532959 
z"
      />
      <path
      className="nhs-logo-background"
        opacity="1.000000"
        stroke="none"
        d="
M1320.000000,70.846123 
   C1364.474121,70.846123 1408.448364,70.846123 1452.728516,70.846123 
   C1452.728516,158.871643 1452.728516,246.590027 1452.728516,334.593018 
   C1234.273193,334.593018 1016.016052,334.593018 797.339783,334.593018 
   C797.339783,246.898010 797.339783,159.190964 797.339783,70.846123 
   C971.322327,70.846123 1145.411133,70.846123 1320.000000,70.846123 
M828.831726,247.325195 
   C824.478638,267.614960 820.125549,287.904724 815.766479,308.222473 
   C816.835815,308.501709 817.310425,308.733154 817.785461,308.734070 
   C833.950012,308.765167 850.115173,308.705414 866.278564,308.847565 
   C869.348938,308.874542 869.649048,307.221741 870.114929,304.892639 
   C878.505859,262.940247 886.961670,221.000778 895.430298,179.063919 
   C896.564331,173.448425 897.843262,167.862213 899.055115,162.262436 
   C902.834167,171.589600 905.752014,180.929398 908.590515,190.293289 
   C920.179321,228.524094 931.775146,266.752869 943.255249,305.016357 
   C944.129761,307.931335 945.437622,308.855133 948.471313,308.835052 
   C969.468384,308.695953 990.467102,308.695099 1011.464294,308.825256 
   C1014.632874,308.844910 1015.574036,307.785889 1016.220764,304.732697 
   C1029.742554,240.896301 1043.416992,177.092194 1057.043579,113.277969 
   C1058.218018,107.778130 1059.269775,102.252098 1060.386108,96.700897 
   C1059.482422,96.450516 1059.019043,96.210640 1058.555176,96.209747 
   C1042.390381,96.178505 1026.225098,96.231194 1010.061279,96.102303 
   C1007.083618,96.078568 1006.588074,97.660255 1006.116028,100.002617 
   C997.960449,140.470337 989.751953,180.927353 981.534607,221.382584 
   C980.074890,228.568756 978.524048,235.736420 977.015320,242.912628 
   C975.814453,242.104034 975.465637,241.367584 975.236450,240.595627 
   C961.447815,194.167709 947.595947,147.758194 934.011902,101.270462 
   C932.772827,97.029991 930.924561,96.030060 926.829407,96.076172 
   C908.166931,96.286308 889.500732,96.171120 870.835876,96.172791 
   C867.698303,96.173073 864.560730,96.172829 861.327087,96.172829 
   C858.439819,109.481552 855.633911,122.319725 852.871277,135.167191 
   C844.891968,172.274490 836.929993,209.385529 828.831726,247.325195 
M1206.324341,98.836769 
   C1201.219604,123.441780 1196.050293,148.033783 1191.082520,172.666412 
   C1190.374023,176.179413 1189.223877,177.597168 1185.333984,177.553650 
   C1165.672119,177.333527 1146.006470,177.463715 1126.342163,177.440720 
   C1125.110718,177.439270 1123.879639,177.179825 1122.312500,177.002380 
   C1127.895020,149.879013 1133.379639,123.231300 1138.912354,96.349464 
   C1119.713135,96.349464 1101.083252,96.349464 1081.949463,96.349464 
   C1067.189087,167.037231 1052.445801,237.643463 1037.668701,308.411133 
   C1057.137573,308.411133 1075.881714,308.411133 1094.670654,308.411133 
   C1100.728516,279.404175 1106.794678,250.744064 1112.604858,222.032166 
   C1113.306519,218.565002 1114.368652,217.472763 1117.982422,217.510986 
   C1137.644653,217.719040 1157.310059,217.614792 1176.974365,217.634247 
   C1178.262207,217.635513 1179.549805,217.772064 1181.409668,217.878403 
   C1175.107300,248.265427 1168.878296,278.298706 1162.608032,308.530975 
   C1181.934570,308.530975 1200.543213,308.530975 1219.559448,308.530975 
   C1234.307007,237.903091 1249.023193,167.425003 1263.745483,96.918404 
   C1262.954346,96.590294 1262.515991,96.250206 1262.077026,96.249268 
   C1244.579712,96.211845 1227.082397,96.174957 1209.585693,96.272438 
   C1208.577393,96.278061 1207.575562,97.409798 1206.324341,98.836769 
M1338.079346,138.394196 
   C1353.476562,133.108902 1369.311035,133.451630 1385.025269,135.761719 
   C1394.938965,137.219116 1404.599609,140.397415 1414.929443,142.940582 
   C1419.253052,129.664490 1423.746338,115.867172 1428.659424,100.780960 
   C1417.982056,98.620575 1408.174561,95.938713 1398.189453,94.753754 
   C1373.619873,91.837997 1348.952881,90.893608 1324.667114,96.945808 
   C1294.707764,104.411949 1272.871948,120.482239 1268.472290,153.383377 
   C1265.388916,176.442123 1271.562134,193.579620 1292.613159,205.848801 
   C1298.767700,209.435883 1305.461548,212.107513 1311.948120,215.112442 
   C1321.591553,219.579681 1331.842529,223.047592 1340.804688,228.602737 
   C1355.418091,237.660782 1354.026978,256.031158 1338.805908,264.042358 
   C1327.011108,270.250305 1314.157104,270.661102 1301.299194,270.307739 
   C1284.357178,269.842133 1267.954590,266.649933 1252.357666,258.670563 
   C1247.902100,273.068787 1243.560181,287.100037 1239.144409,301.369659 
   C1278.282837,313.140930 1317.002441,316.976837 1356.020020,305.539490 
   C1383.854980,297.380157 1404.031006,280.973389 1408.445557,250.389481 
   C1412.101074,225.065933 1405.555542,205.579391 1383.405151,191.977936 
   C1376.357544,187.650345 1368.539673,184.558441 1361.008911,181.041138 
   C1352.578369,177.103531 1343.589111,174.117630 1335.708740,169.300919 
   C1322.105957,160.986633 1323.206665,146.201996 1338.079346,138.394196 
z"
      />
      <path
      className="shsc-logo-bg"
        opacity="1.000000"
        stroke="none"
        d="
M914.210205,495.044281 
   C914.210205,510.475586 914.210205,525.414429 914.210205,540.594849 
   C905.323486,540.594849 897.059387,540.594849 888.401489,540.594849 
   C888.401489,497.425903 888.401489,454.361389 888.401489,410.910919 
   C896.661865,410.910919 905.054260,410.910919 914.081177,410.910919 
   C914.081177,428.068054 914.081177,445.266449 914.081177,462.753662 
   C931.624939,462.753662 948.542419,462.753662 966.071838,462.753662 
   C966.071838,445.588745 966.071838,428.374573 966.071838,410.849731 
   C975.082642,410.849731 983.488159,410.849731 992.213745,410.849731 
   C992.213745,454.035767 992.213745,497.097076 992.213745,540.499451 
   C983.688843,540.499451 975.301453,540.499451 966.455872,540.499451 
   C966.455872,521.838928 966.455872,503.243683 966.455872,484.214233 
   C948.971497,484.214233 931.933655,484.214233 914.210205,484.214233 
   C914.210205,487.654968 914.210205,491.103394 914.210205,495.044281 
z"
      />
      <path
        className="shsc-logo-bg"
        opacity="1.000000"
        stroke="none"
        d="
M776.614746,536.699341 
   C752.502869,550.718994 725.724670,541.337708 717.416748,516.087769 
   C713.829285,505.184692 712.935852,493.851379 714.620361,482.530975 
   C715.565491,476.179382 716.964111,469.667908 719.519104,463.823242 
   C729.613342,440.732086 761.152344,434.598206 779.596741,451.824188 
   C780.534912,452.700470 781.320740,453.739838 783.232849,455.890076 
   C783.232849,445.473969 783.232849,436.601837 783.232849,427.729675 
   C783.232849,418.950317 783.232849,410.170959 783.232849,401.143158 
   C791.818237,401.143158 799.892883,401.143158 808.181396,401.143158 
   C808.181396,447.593140 808.181396,493.829041 808.181396,540.454651 
   C800.159668,540.454651 792.313538,540.454651 783.981079,540.454651 
   C783.981079,537.320679 783.981079,534.428223 783.981079,530.633972 
   C781.143433,532.987610 779.030396,534.740234 776.614746,536.699341 
M780.344238,477.336578 
   C779.499756,475.530426 778.822998,473.620575 777.781433,471.936279 
   C773.607483,465.186157 767.657410,461.400208 759.540344,461.947815 
   C751.749146,462.473419 746.695312,466.907959 743.790649,473.936981 
   C738.927551,485.704926 738.933716,497.637512 743.191956,509.517761 
   C746.501038,518.749817 752.813904,523.335815 761.319824,523.278992 
   C769.992798,523.221069 776.500671,518.332397 779.974304,509.303589 
   C783.951355,498.966431 784.196655,488.550964 780.344238,477.336578 
z"
      />
      <path
        className="shsc-logo-bg"
        opacity="1.000000"
        stroke="none"
        d="
M368.644836,729.457153 
   C353.963226,722.948669 348.002228,710.643799 345.512878,696.293884 
   C343.269684,683.363342 344.032166,670.550110 347.909912,657.805176 
   C357.287018,626.985657 396.139587,625.575867 410.721954,642.056152 
   C411.342407,642.757385 411.482513,643.883606 411.846375,644.811829 
   C412.420532,644.429016 412.994720,644.046204 413.568878,643.663391 
   C413.568878,625.977295 413.568878,608.291199 413.568878,590.343506 
   C422.199982,590.343506 430.262207,590.343506 438.627563,590.343506 
   C438.627563,636.784485 438.627563,683.002563 438.627563,729.630249 
   C430.768341,729.630249 422.881134,729.630249 414.533417,729.630249 
   C414.533417,726.800720 414.533417,723.878662 414.533417,719.770874 
   C400.838409,733.148865 385.630676,735.114868 368.644836,729.457153 
M396.252533,652.022583 
   C385.014618,650.010681 377.359802,654.181702 373.536835,664.864990 
   C369.283569,676.750854 369.413391,688.719788 374.191101,700.449646 
   C377.185608,707.801514 382.573639,712.456360 390.790649,712.765564 
   C399.093994,713.077942 405.275818,709.225098 408.883392,701.832031 
   C414.914062,689.473206 415.059113,676.815430 409.635803,664.211121 
   C407.182037,658.508362 403.129272,654.200439 396.252533,652.022583 
z"
      />
      <path
         className="shsc-logo-bg"
        opacity="1.000000"
        stroke="none"
        d="
M213.490356,496.999847 
   C213.343643,490.338257 213.533615,484.145721 212.972046,478.022156 
   C212.107285,468.592377 207.713989,463.315094 200.491364,462.078674 
   C192.199905,460.659271 183.695999,464.624847 180.266144,472.539307 
   C178.057144,477.636597 176.211838,483.287811 175.974380,488.767548 
   C175.311111,504.073547 175.568207,519.419495 175.465652,534.749695 
   C175.453552,536.560242 175.464020,538.370911 175.464020,540.506470 
   C167.003906,540.506470 158.970139,540.506470 150.624374,540.506470 
   C150.624374,494.092041 150.624374,447.838898 150.624374,401.236633 
   C158.732101,401.236633 166.801956,401.236633 175.537720,401.236633 
   C175.537720,419.377167 175.537720,437.561188 175.537720,457.302551 
   C182.908890,448.362976 191.124603,443.836456 201.052490,442.507141 
   C220.162247,439.948395 236.154373,450.379852 237.454666,469.334442 
   C239.066971,492.837341 237.840866,516.534912 237.840866,540.518677 
   C230.155197,540.518677 222.107849,540.518677 213.490326,540.518677 
   C213.490326,526.078491 213.490326,511.789154 213.490356,496.999847 
z"
      />
      <path
        className="shsc-logo-bg"
        opacity="1.000000"
        stroke="none"
        d="
M1440.198608,482.016113 
   C1440.198486,501.783478 1440.198486,521.053833 1440.198486,540.589722 
   C1431.711548,540.589722 1423.783447,540.589722 1415.037720,540.589722 
   C1415.037720,538.945923 1415.037842,537.374878 1415.037720,535.803833 
   C1415.035767,519.480225 1415.088745,503.156342 1414.990845,486.833344 
   C1414.968018,483.026123 1414.705444,479.175720 1414.071167,475.426758 
   C1413.009033,469.148041 1410.169922,464.016083 1403.351318,462.459503 
   C1396.351440,460.861542 1389.992798,462.332703 1385.114868,467.726807 
   C1378.446777,475.100525 1377.114624,484.347656 1377.035034,493.816742 
   C1376.918823,507.640869 1377.001953,521.466675 1377.000000,535.291748 
   C1376.999878,536.922607 1377.000000,538.553467 1377.000000,540.510742 
   C1368.527710,540.510742 1360.482666,540.510742 1352.180176,540.510742 
   C1352.180176,493.986176 1352.180176,447.757507 1352.180176,401.210022 
   C1360.305420,401.210022 1368.364258,401.210022 1377.001709,401.210022 
   C1377.001709,419.478699 1377.001709,437.653168 1377.001709,456.108368 
   C1381.398438,452.745300 1384.980957,449.203888 1389.231079,446.886536 
   C1413.109131,433.866943 1437.239014,446.640411 1439.919434,473.536438 
   C1440.182983,476.180359 1440.113647,478.857422 1440.198608,482.016113 
z"
      />
      <path
         className="shsc-logo-bg"
        opacity="1.000000"
        stroke="none"
        d="
M516.750122,727.187500 
   C517.109192,719.465759 517.745178,712.045715 518.450134,703.821533 
   C520.946106,704.909485 522.847961,705.744324 524.754456,706.568604 
   C534.099670,710.608948 543.778442,712.712708 553.952454,710.989197 
   C556.860413,710.496582 559.839966,709.564209 562.422485,708.162415 
   C573.649536,702.068420 574.218323,689.408630 563.393982,682.608459 
   C558.648010,679.626892 553.220947,677.721558 548.079041,675.380676 
   C543.087036,673.108093 537.926147,671.166138 533.080505,668.619995 
   C518.369080,660.889771 512.449951,648.332336 514.282471,632.145142 
   C516.089844,616.180054 524.788147,605.335754 540.211731,600.620361 
   C557.028198,595.479187 573.782532,597.522522 590.898560,602.048462 
   C590.146790,609.250244 589.426941,616.146179 588.632019,623.760742 
   C586.306274,623.024963 584.378418,622.148743 582.361755,621.824524 
   C574.848877,620.616577 567.295166,618.719055 559.780090,618.819885 
   C555.228699,618.880920 549.951233,621.205872 546.352539,624.160828 
   C538.855225,630.317017 539.992310,642.305847 548.451904,647.005859 
   C555.824646,651.102051 563.891113,653.953735 571.664978,657.323181 
   C583.659424,662.522217 594.071838,669.291260 597.372986,683.132324 
   C601.668213,701.141357 595.366760,722.159485 572.121338,729.169250 
   C553.558350,734.766968 535.251160,732.654968 516.750122,727.187500 
z"
      />
      <path
         className="shsc-logo-bg"
        opacity="1.000000"
        stroke="none"
        d="
M116.475113,532.518555 
   C107.705193,539.351868 97.788330,541.827576 87.321159,542.633667 
   C73.703506,543.682312 60.401028,542.025574 46.758858,537.669922 
   C47.534218,530.194946 48.316658,522.651733 49.140476,514.709656 
   C53.717571,516.423889 57.931046,518.241333 62.290318,519.586121 
   C72.192947,522.640991 82.125183,523.922058 91.978020,519.187561 
   C97.477966,516.544800 101.268311,512.528687 101.652725,506.292969 
   C102.028404,500.199219 98.885338,495.541687 93.623466,492.951721 
   C83.489311,487.963623 72.979683,483.737030 62.855949,478.729279 
   C36.955940,465.917725 41.097267,427.935242 59.961155,416.240204 
   C68.003807,411.254028 76.750191,408.229095 85.918350,408.409027 
   C96.646797,408.619568 107.337875,410.629761 118.050758,411.808380 
   C120.833115,412.114502 121.616699,413.472687 121.208572,416.197144 
   C120.323959,422.102509 119.698265,428.046631 119.093742,432.945404 
   C109.480949,431.597137 100.227577,429.620300 90.901497,429.178619 
   C81.204109,428.719391 74.696457,434.061096 72.599411,442.260010 
   C71.008751,448.479126 72.573524,453.722198 78.070122,456.982483 
   C83.187767,460.018005 88.881355,462.066254 94.229561,464.733276 
   C101.940437,468.578491 110.451050,471.482483 117.058624,476.747986 
   C133.884140,490.156128 133.315628,516.811829 116.475113,532.518555 
z"
      />
      <path
         className="shsc-logo-bg"
        opacity="1.000000"
        stroke="none"
        d="
M1157.323486,731.219177 
   C1139.058105,733.775085 1121.664062,732.382324 1105.046143,725.032959 
   C1089.305420,718.071655 1078.998901,706.060913 1074.335449,689.649963 
   C1069.723877,673.420898 1070.116211,656.960144 1075.196289,640.876038 
   C1082.309082,618.355835 1098.244385,605.180054 1120.891846,600.022339 
   C1136.575684,596.450500 1152.291504,596.837219 1167.836426,601.385315 
   C1171.127686,602.348328 1172.836182,603.576111 1172.125610,607.585388 
   C1171.148071,613.101135 1171.014893,618.766479 1170.603394,623.425049 
   C1161.789551,621.747070 1153.322754,619.263794 1144.724121,618.661316 
   C1118.818726,616.846130 1100.715210,635.032043 1098.914429,661.498474 
   C1098.041382,674.328613 1100.604370,686.451294 1108.983154,696.657104 
   C1119.219604,709.125610 1133.025391,712.789368 1148.407471,711.356506 
   C1155.936768,710.655151 1163.370483,708.924561 1171.481445,707.552612 
   C1171.833862,713.752563 1172.225220,720.636719 1172.662842,728.333435 
   C1167.720703,729.261597 1162.737671,730.197327 1157.323486,731.219177 
z"
      />
      <path
         className="shsc-logo-bg"
        opacity="1.000000"
        stroke="none"
        d="
M696.815186,721.833008 
   C673.605225,738.766418 639.314697,735.042236 623.556885,714.151123 
   C611.614319,698.318176 610.275635,672.222900 620.525208,655.051514 
   C630.936462,637.609253 650.707092,629.024170 672.644043,632.385925 
   C702.867920,637.017578 717.756592,662.348755 713.557739,691.731201 
   C711.853394,703.658020 706.631775,713.877991 696.815186,721.833008 
M680.472290,705.930176 
   C689.216858,693.473816 690.187744,679.893494 685.247314,665.991089 
   C681.683777,655.963501 673.728149,651.279358 663.099915,651.429443 
   C653.744751,651.561584 646.400635,656.888428 642.579773,666.240234 
   C638.460449,676.322632 639.206665,686.470337 642.414490,696.530640 
   C647.808105,713.445618 666.724487,718.388306 680.472290,705.930176 
z"
      />
      <path
         className="shsc-logo-bg"
        opacity="1.000000"
        stroke="none"
        d="
M1094.056519,537.023193 
   C1078.297241,543.965820 1062.328979,544.749756 1046.287109,540.542786 
   C1031.363159,536.628845 1021.277771,526.901184 1016.839355,512.004333 
   C1012.887146,498.739258 1013.041748,485.299164 1017.367188,472.139038 
   C1024.337280,450.932495 1042.670410,439.696899 1065.189453,442.451874 
   C1086.068115,445.006195 1097.608765,455.205994 1102.055054,475.888031 
   C1103.720947,483.636658 1104.182617,491.644165 1105.300781,500.372528 
   C1082.590576,500.372528 1060.740356,500.372528 1038.918945,500.372528 
   C1038.672607,510.824249 1045.285034,519.955505 1054.783936,522.771545 
   C1065.314941,525.893494 1075.555298,524.298584 1085.546265,520.374634 
   C1089.357544,518.877808 1093.045044,517.065857 1097.446411,515.104492 
   C1097.446411,521.587769 1097.609375,527.534058 1097.309326,533.456909 
   C1097.248901,534.647888 1095.429443,535.749695 1094.056519,537.023193 
M1063.499146,483.152954 
   C1069.058594,483.152954 1074.618042,483.152954 1080.188599,483.152954 
   C1079.373169,467.461548 1071.092163,459.653656 1057.418579,460.923309 
   C1046.342285,461.951752 1037.791992,472.150360 1039.158447,483.152374 
   C1046.903320,483.152374 1054.701294,483.152374 1063.499146,483.152954 
z"
      />
      <path
         className="shsc-logo-bg"
        opacity="1.000000"
        stroke="none"
        d="
M621.999634,500.537659 
   C608.721497,500.537628 595.943420,500.537628 583.191589,500.537628 
   C582.780457,510.340637 589.154785,519.462524 598.525879,522.525330 
   C609.906738,526.244934 620.898926,524.125183 631.620789,519.716736 
   C634.947021,518.349121 638.153198,516.689636 641.920410,514.926941 
   C641.920410,521.277344 642.066650,527.065735 641.805664,532.835693 
   C641.748718,534.093811 640.508667,535.912598 639.353210,536.415405 
   C619.758606,544.942627 600.004517,545.993286 580.511169,536.355713 
   C571.149719,531.727295 565.067505,523.762634 561.789001,513.899719 
   C556.774414,498.814301 557.193237,483.673309 562.854614,468.888153 
   C570.758057,448.247803 591.866699,438.166382 615.370239,443.386414 
   C634.227905,447.574615 644.754639,460.057068 647.765320,482.174377 
   C648.547668,487.921783 648.703369,493.754547 649.222595,500.537659 
   C639.963013,500.537659 631.231323,500.537659 621.999634,500.537659 
M606.243103,460.807648 
   C594.360352,459.329529 582.823486,468.577484 583.198547,482.886505 
   C596.907593,482.886505 610.619019,482.886505 624.322327,482.886505 
   C624.609436,470.586945 618.411255,462.755280 606.243103,460.807648 
z"
      />
      <path
         className="shsc-logo-bg"
        opacity="1.000000"
        stroke="none"
        d="
M342.694305,500.527527 
   C322.448090,500.511414 302.667328,500.511414 282.944946,500.511414 
   C281.912323,509.272522 288.108459,518.732727 297.023468,522.115234 
   C308.113129,526.322876 318.981720,524.377258 329.637085,520.328674 
   C333.333160,518.924255 336.855743,517.063354 341.183167,515.078125 
   C341.183167,521.615173 341.258087,527.678894 341.101013,533.736572 
   C341.077881,534.627930 340.152344,535.861145 339.310211,536.312012 
   C323.206299,544.932861 292.446899,546.139709 276.187866,534.009766 
   C269.001892,528.648682 263.888550,521.876648 261.171326,513.380859 
   C256.101013,497.527771 256.740601,481.838623 263.422760,466.591522 
   C271.090302,449.096161 288.922394,439.915009 309.514862,442.497314 
   C329.635437,445.020386 341.246735,455.087616 345.690857,474.862976 
   C347.525421,483.026337 348.032104,491.488068 349.204224,500.275330 
   C346.403961,500.399658 344.781860,500.471649 342.694305,500.527527 
M286.743683,469.344543 
   C285.003204,473.648315 283.262726,477.952087 281.161438,483.148041 
   C295.336517,483.148041 307.952728,483.155945 320.568909,483.134491 
   C321.696167,483.132568 322.823120,482.940247 323.919098,482.839203 
   C324.282837,473.386566 319.429382,465.075378 311.929260,462.052124 
   C303.468109,458.641541 294.020477,461.190857 286.743683,469.344543 
z"
      />
      <path
         className="shsc-logo-bg"
        opacity="1.000000"
        stroke="none"
        d="
M1446.040039,681.052856 
   C1446.062012,684.144592 1446.062012,686.755798 1446.062012,689.861816 
   C1423.971069,689.861816 1402.221680,689.861816 1380.454956,689.861816 
   C1381.114014,703.863953 1390.744019,713.178833 1404.687866,713.901306 
   C1414.782959,714.424316 1424.103882,711.700195 1433.080322,707.412170 
   C1434.864380,706.559814 1436.618774,705.645325 1439.260498,704.321716 
   C1439.260498,711.172180 1439.322021,717.392395 1439.177612,723.607788 
   C1439.160767,724.333435 1438.142700,725.285767 1437.359619,725.706787 
   C1420.901855,734.554993 1387.774292,736.482117 1370.735107,720.772339 
   C1357.313599,708.397949 1354.650024,692.333618 1356.581909,675.415222 
   C1357.485352,667.503357 1359.876099,659.290283 1363.584229,652.264832 
   C1372.002808,636.315430 1388.766724,629.472168 1408.893188,632.238708 
   C1427.990356,634.863831 1439.634521,645.551331 1443.859619,664.757996 
   C1444.999023,669.937073 1445.320923,675.295959 1446.040039,681.052856 
M1398.275757,650.587952 
   C1387.071533,652.865601 1381.146240,659.958618 1380.537476,672.059082 
   C1394.351074,672.059082 1408.150024,672.059082 1421.965942,672.059082 
   C1421.201294,656.862183 1413.553223,649.766235 1398.275757,650.587952 
z"
      />
      <path
         className="shsc-logo-bg"
        opacity="1.000000"
        stroke="none"
        d="
M941.353699,645.724731 
   C943.371338,651.862305 946.527893,657.621643 946.808350,663.517822 
   C947.850891,685.433472 947.947449,707.394165 948.406433,729.691040 
   C941.480164,729.691040 934.406067,729.691040 926.661804,729.691040 
   C926.302063,725.799438 925.929565,721.770203 925.473022,716.831665 
   C923.868896,718.652344 922.766113,719.921936 921.644287,721.174438 
   C908.969360,735.326538 883.832825,736.233398 870.223267,723.032410 
   C856.357910,709.583069 860.393250,685.214050 877.943787,676.556458 
   C886.815674,672.179932 896.364929,671.009399 906.093689,670.866821 
   C912.048828,670.779541 918.006287,670.850647 923.891479,670.850647 
   C924.927673,658.329285 919.632385,651.681396 908.067505,650.560852 
   C897.461975,649.533142 887.548950,651.806702 878.407898,657.339661 
   C876.901489,658.251465 875.353333,659.094238 872.529663,660.709595 
   C872.529663,653.399048 872.460510,647.146057 872.606445,640.897949 
   C872.627563,639.990967 873.481873,638.596497 874.281799,638.289062 
   C892.163940,631.416687 910.423828,628.668823 928.780518,635.799072 
   C933.450745,637.613159 937.070068,642.132812 941.353699,645.724731 
M904.088806,713.858337 
   C917.992493,711.441589 926.117737,700.004517 923.726562,685.812683 
   C920.501587,685.812683 917.150085,685.410034 913.932922,685.896057 
   C907.105286,686.927490 900.118286,687.742432 893.630676,689.932068 
   C888.010376,691.829041 885.312317,698.233643 886.131470,703.821167 
   C886.881775,708.938232 890.389526,712.228027 896.780823,713.474854 
   C898.876160,713.883545 901.073792,713.767944 904.088806,713.858337 
z"
      />
      <path
         className="shsc-logo-bg"
        opacity="1.000000"
        stroke="none"
        d="
M1147.810059,482.789642 
   C1158.660522,481.964294 1169.083252,481.195526 1179.594971,480.420227 
   C1181.096924,469.639343 1175.238647,462.155151 1164.126709,461.011993 
   C1153.471436,459.915771 1143.655640,462.408875 1134.460938,467.713501 
   C1132.916382,468.604553 1131.373413,469.498291 1129.153076,470.782043 
   C1128.832764,463.949432 1128.416748,457.733276 1128.374390,451.514557 
   C1128.367432,450.496002 1129.806396,448.948151 1130.915161,448.519287 
   C1147.473755,442.114777 1164.392700,439.761810 1181.726562,445.027802 
   C1190.515015,447.697723 1196.975708,453.330566 1199.877808,462.167053 
   C1201.470581,467.016785 1202.853760,472.188416 1202.934814,477.237335 
   C1203.271362,498.198120 1203.076172,519.167480 1203.076172,540.504395 
   C1197.275879,540.504395 1190.202148,540.504395 1182.531982,540.504395 
   C1182.070312,536.493042 1181.590820,532.326660 1181.009644,527.276855 
   C1174.348267,536.387634 1166.034668,541.254761 1155.669800,542.677917 
   C1141.306641,544.649963 1125.559937,537.279480 1120.408691,526.043762 
   C1112.845947,509.547882 1121.105103,490.877441 1138.663330,484.975220 
   C1141.489014,484.025391 1144.472168,483.544098 1147.810059,482.789642 
M1172.472168,496.308899 
   C1170.978271,496.311432 1169.425537,496.047668 1168.000366,496.361847 
   C1161.286743,497.841858 1154.296509,498.721558 1148.014160,501.299713 
   C1142.886230,503.404144 1140.918335,510.126831 1142.087891,515.434326 
   C1143.104858,520.049255 1147.029175,523.115540 1153.098633,524.037720 
   C1169.622559,526.548218 1181.517944,514.428955 1179.681152,496.273468 
   C1177.639038,496.273468 1175.528687,496.273468 1172.472168,496.308899 
z"
      />
      <path
         className="shsc-logo-bg"
        opacity="1.000000"
        stroke="none"
        d="
M1198.140381,727.938721 
   C1185.870117,720.336731 1181.527466,710.979431 1183.723145,697.452515 
   C1186.052490,683.101379 1195.773193,675.901550 1208.862061,673.145813 
   C1217.248901,671.379944 1226.023682,671.401428 1234.636597,670.789856 
   C1238.093994,670.544312 1241.583374,670.749390 1244.993652,670.749390 
   C1246.791504,661.016174 1241.696533,652.962585 1232.553345,651.138611 
   C1220.551880,648.744507 1209.446411,651.447083 1199.050537,657.607727 
   C1197.772461,658.365234 1196.480347,659.099548 1195.181763,659.821228 
   C1195.085693,659.874573 1194.884644,659.738892 1193.738037,659.378906 
   C1193.738037,653.469299 1193.617310,647.217224 1193.854492,640.978760 
   C1193.894897,639.914307 1195.260620,638.390442 1196.361572,637.952759 
   C1212.244385,631.639221 1228.608398,629.737427 1245.290283,633.941040 
   C1258.985474,637.391968 1267.089355,647.679504 1267.911011,663.328552 
   C1268.800659,680.272888 1268.755005,697.265442 1269.195312,714.234985 
   C1269.324585,719.215027 1269.720459,724.188171 1270.018433,729.605469 
   C1262.583496,729.605469 1255.379517,729.605469 1247.641113,729.605469 
   C1247.319824,725.722473 1246.998047,721.834167 1246.603271,717.063171 
   C1232.898193,732.991394 1216.926392,736.413330 1198.140381,727.938721 
M1207.955811,697.704712 
   C1205.940430,707.572266 1210.424194,713.302429 1220.648193,713.925476 
   C1236.120728,714.868469 1247.063599,702.604614 1245.152588,685.098267 
   C1236.677124,685.937927 1228.122314,686.374939 1219.726440,687.769104 
   C1214.579468,688.623901 1210.133179,691.507324 1207.955811,697.704712 
z"
      />
      <path
         className="shsc-logo-bg"
        opacity="1.000000"
        stroke="none"
        d="
M161.757080,732.389893 
   C154.753433,730.896179 147.657822,730.485168 141.715530,727.653687 
   C131.214508,722.650024 126.257431,713.531921 126.878738,701.600769 
   C127.488289,689.895508 132.578522,680.595215 143.466446,676.486938 
   C151.852814,673.322632 161.122864,672.218018 170.109894,671.026550 
   C176.145004,670.226440 182.372070,670.874207 188.409683,670.874207 
   C190.451004,661.225891 185.635620,653.198853 176.692001,651.255920 
   C164.712006,648.653259 153.589584,651.328125 143.125626,657.344238 
   C141.701477,658.162964 140.318024,659.052612 138.115402,660.398499 
   C137.797089,653.320312 137.405121,646.820862 137.338211,640.318115 
   C137.329834,639.503052 138.899200,638.321167 139.970154,637.898254 
   C155.533096,631.753113 171.585434,629.900635 187.952774,633.642273 
   C202.705597,637.014832 211.023453,647.825500 211.834320,665.261108 
   C212.700180,683.879028 212.721771,702.535889 213.139862,721.175171 
   C213.199341,723.825989 213.416672,726.473267 213.586288,729.587830 
   C206.253937,729.587830 199.060211,729.587830 191.361206,729.587830 
   C190.973907,725.683716 190.574921,721.661621 190.067520,716.546814 
   C182.777817,726.682190 173.539856,731.525818 161.757080,732.389893 
M176.092270,711.837280 
   C185.719986,706.447754 190.813583,695.889954 188.591446,686.320740 
   C182.057983,686.320740 175.525909,685.773193 169.142685,686.511841 
   C164.836075,687.010193 160.448730,688.804688 156.620682,690.951965 
   C151.570038,693.785217 150.282349,698.932495 151.151474,704.414612 
   C151.994385,709.731445 155.778229,712.770752 160.749695,713.145447 
   C165.570541,713.508728 170.493347,712.519287 176.092270,711.837280 
z"
      />
      <path
        className="shsc-logo-bg"
        opacity="1.000000"
        stroke="none"
        d="
M299.853699,717.984985 
   C299.856598,703.831970 299.918518,690.175293 299.821747,676.519775 
   C299.795898,672.872498 299.589508,669.172607 298.930298,665.595642 
   C297.731995,659.093445 295.197968,653.404358 287.796875,651.829651 
   C279.745636,650.116760 271.979980,653.197815 267.728973,659.726685 
   C263.091431,666.849182 261.862366,674.841553 261.841980,683.114014 
   C261.807922,696.936768 261.838226,710.759583 261.841309,724.582397 
   C261.841675,726.202881 261.841370,727.823364 261.841370,729.734497 
   C253.396103,729.734497 245.476837,729.734497 237.108368,729.734497 
   C237.108368,698.071167 237.108368,666.373901 237.108368,634.281860 
   C244.626846,634.281860 252.321045,634.281860 260.409454,634.281860 
   C260.409454,638.036499 260.409454,641.653809 260.409454,646.129822 
   C261.704529,645.081360 262.426727,644.637695 262.972260,644.034302 
   C274.227020,631.586182 288.408325,629.689087 303.685791,633.455139 
   C313.079926,635.770935 319.041443,642.563354 322.040192,651.632935 
   C323.437439,655.858948 324.693359,660.365662 324.749725,664.761353 
   C325.025208,686.232300 324.874451,707.708740 324.874451,729.606079 
   C316.731018,729.606079 308.683502,729.606079 299.853241,729.606079 
   C299.853241,725.976990 299.853241,722.229309 299.853699,717.984985 
z"
      />
      <path
         className="shsc-logo-bg"
        opacity="1.000000"
        stroke="none"
        d="
M486.019135,418.021484 
   C478.896362,419.030853 475.670990,421.889984 475.056519,428.501801 
   C474.583923,433.587433 474.962341,438.752167 474.962341,444.400452 
   C481.904968,444.400452 488.796692,444.400452 495.994568,444.400452 
   C495.994568,450.805939 495.994568,456.697266 495.994568,463.076111 
   C489.069244,463.076111 482.152771,463.076111 474.713135,463.076111 
   C474.713135,489.097961 474.713135,514.645691 474.713135,540.515686 
   C466.137268,540.515686 458.092255,540.515686 449.602112,540.515686 
   C449.602112,514.804504 449.602112,489.221832 449.602112,463.082703 
   C443.275482,463.082703 437.369720,463.082703 431.230957,463.082703 
   C431.230957,456.764832 431.230957,451.004730 431.230957,444.843750 
   C437.107391,444.843750 442.879059,444.843750 449.235321,444.843750 
   C449.350250,442.722321 449.481018,440.963806 449.534027,439.202911 
   C449.674194,434.546814 449.488159,429.860199 449.953857,425.238342 
   C451.522614,409.668457 461.730408,399.449860 477.250244,398.598511 
   C484.113281,398.222046 491.058563,399.345520 498.511993,399.822998 
   C497.817902,407.331421 497.211670,413.889069 496.582764,420.692444 
   C492.976044,419.744324 489.702057,418.883698 486.019135,418.021484 
z"
      />
      <path
        className="shsc-logo-bg"
        opacity="1.000000"
        stroke="none"
        d="
M377.020203,496.000000 
   C377.020203,484.879883 377.020203,474.259766 377.020203,463.233978 
   C370.858856,463.233978 365.097412,463.233978 358.941345,463.233978 
   C358.941345,456.978333 358.941345,451.119171 358.941345,444.804443 
   C364.673553,444.804443 370.427734,444.804443 376.748199,444.804443 
   C376.852570,442.652313 376.940643,440.908539 377.020996,439.164398 
   C377.273315,433.688660 377.005402,428.129333 377.872772,422.753021 
   C380.238251,408.090973 390.914520,398.814636 405.796387,398.530304 
   C412.235199,398.407318 418.694244,399.343506 425.947418,399.855225 
   C425.616547,403.874084 425.373688,407.260101 425.049377,410.638275 
   C424.732452,413.939850 424.332336,417.233429 423.920837,420.964447 
   C421.397308,420.056976 419.200928,419.068176 416.902222,418.477509 
   C409.762512,416.642822 404.029327,419.971008 402.876740,427.228394 
   C402.002014,432.736450 402.167450,438.409729 401.845093,444.478699 
   C409.440186,444.478699 416.363159,444.478699 423.609833,444.478699 
   C423.609833,450.800903 423.609833,456.684021 423.609833,463.025452 
   C416.568115,463.025452 409.652557,463.025452 402.214355,463.025452 
   C402.214355,489.020630 402.214355,514.569702 402.214355,540.476318 
   C393.643829,540.476318 385.604370,540.476318 377.020203,540.476318 
   C377.020203,525.695251 377.020203,511.097626 377.020203,496.000000 
z"
      />
      <path
        className="nhs-logo-background"
        opacity="1.000000"
        stroke="none"
        d="
M232.496033,840.431519 
   C236.104813,847.526001 239.537354,854.291077 242.969879,861.056152 
   C243.276978,860.987976 243.584061,860.919739 243.891144,860.851562 
   C243.891144,840.340210 243.891144,819.828857 243.891144,798.855225 
   C249.741287,798.855225 255.103699,798.855225 260.778564,798.855225 
   C260.778564,827.535339 260.778564,856.260681 260.778564,885.441895 
   C254.199646,885.441895 247.722977,885.628540 241.275543,885.277893 
   C240.046631,885.211060 238.563034,883.213196 237.822342,881.782532 
   C228.028458,862.864441 218.347214,843.888123 208.633759,824.928406 
   C208.282730,824.243164 207.889648,823.579590 206.555908,823.072510 
   C206.555908,843.679810 206.555908,864.287048 206.555908,885.189819 
   C200.677292,885.189819 195.428146,885.189819 189.782959,885.189819 
   C189.782959,856.451660 189.782959,827.756104 189.782959,798.388367 
   C196.239197,798.388367 202.697632,798.163086 209.116440,798.567261 
   C210.498657,798.654236 212.158234,800.793152 212.976440,802.359619 
   C219.521118,814.889832 225.894150,827.509644 232.496033,840.431519 
z"
      />
      <path
        className="shsc-logo-bg"
        opacity="1.000000"
        stroke="none"
        d="
M1315.581055,519.276062 
   C1321.814575,525.859497 1328.718628,522.758179 1335.788208,521.418640 
   C1336.106689,527.708435 1336.422974,533.953064 1336.765259,540.712585 
   C1325.770752,543.123352 1314.923340,544.717407 1304.308105,540.399475 
   C1291.078613,535.018127 1287.677490,523.581726 1287.519043,510.689331 
   C1287.346924,496.693329 1287.490845,482.693451 1287.496826,468.695251 
   C1287.497437,467.042725 1287.496948,465.390198 1287.496948,463.285461 
   C1281.441162,463.285461 1275.679688,463.285461 1269.527588,463.285461 
   C1269.527588,456.969910 1269.527588,451.095093 1269.527588,444.751190 
   C1275.322388,444.751190 1281.074707,444.751190 1287.484375,444.751190 
   C1287.484375,439.356537 1287.608398,434.406586 1287.437134,429.466888 
   C1287.337402,426.590454 1288.099121,424.969513 1291.145996,424.108612 
   C1298.170654,422.123871 1305.079346,419.728790 1312.725830,417.278931 
   C1312.725830,426.531281 1312.725830,435.372589 1312.725830,444.672913 
   C1320.414795,444.672913 1327.495605,444.672913 1334.718262,444.672913 
   C1334.718262,450.919556 1334.718262,456.690979 1334.718262,462.828033 
   C1327.593628,462.828033 1320.660400,462.828033 1314.062866,462.828033 
   C1313.503418,463.429749 1313.088257,463.667694 1313.087524,463.906921 
   C1313.038452,479.732758 1312.919312,495.559998 1313.109619,511.383423 
   C1313.140259,513.916016 1314.569336,516.431763 1315.581055,519.276062 
z"
      />
      <path
         className="shsc-logo-bg"
        opacity="1.000000"
        stroke="none"
        d="
M728.807495,668.794495 
   C734.523132,644.293884 750.203125,631.680298 774.837280,631.975037 
   C782.558044,632.067444 790.265808,633.254456 798.440552,633.981445 
   C797.605225,641.537842 796.898743,647.928467 796.236938,653.915283 
   C790.635681,653.040161 785.500000,651.741821 780.319763,651.528625 
   C768.504761,651.042358 760.107544,656.677368 756.156555,667.771484 
   C752.694031,677.493835 752.781982,687.472229 756.529968,697.107727 
   C761.083923,708.815613 771.143127,714.438110 783.625854,712.470337 
   C788.521423,711.698608 793.312439,710.263855 798.805603,708.977844 
   C799.169861,715.210999 799.587830,721.405823 799.814636,727.607605 
   C799.839417,728.285828 798.869629,729.484314 798.184570,729.634155 
   C779.389099,733.745544 760.659363,735.494019 744.328308,722.562256 
   C729.905396,711.141479 727.108826,694.827698 727.592590,677.599243 
   C727.671387,674.793640 728.326599,672.004272 728.807495,668.794495 
z"
      />
      <path
        className="nhs-logo-background"
        opacity="1.000000"
        stroke="none"
        d="
M297.738983,855.001770 
   C297.739044,865.296692 297.739044,875.089050 297.739044,885.176270 
   C291.782867,885.176270 286.200684,885.176270 280.341125,885.176270 
   C280.341125,856.427856 280.341125,827.856323 280.341125,798.836975 
   C285.838623,798.836975 291.377106,798.836975 297.519287,798.836975 
   C297.519287,810.103943 297.519287,821.480835 297.519287,833.199219 
   C309.299744,833.199219 320.488464,833.199219 332.046021,833.199219 
   C332.046021,821.699341 332.046021,810.425354 332.046021,798.773071 
   C338.118011,798.773071 343.824860,798.773071 349.737091,798.773071 
   C349.737091,827.673950 349.737091,856.261353 349.737091,885.164185 
   C343.973907,885.164185 338.387238,885.164185 332.253540,885.164185 
   C332.253540,872.695190 332.253540,860.315308 332.253540,847.716309 
   C320.617981,847.716309 309.522522,847.716309 297.738922,847.716309 
   C297.738922,849.963989 297.738922,852.231628 297.738983,855.001770 
z"
      />
      <path
         className="shsc-logo-bg"
        opacity="1.000000"
        stroke="none"
        d="
M997.551636,722.848389 
   C997.551147,725.448914 997.551147,727.569824 997.551147,729.865051 
   C988.957336,729.865051 981.004944,729.865051 972.677856,729.865051 
   C972.677856,683.343079 972.677856,637.001099 972.677856,590.363098 
   C980.795837,590.363098 988.717407,590.363098 997.552124,590.363098 
   C997.552124,634.332336 997.552124,678.350525 997.551636,722.848389 
z"
      />
      <path
         className="shsc-logo-bg"
        opacity="1.000000"
        stroke="none"
        d="
M1228.061157,475.000000 
   C1228.061157,450.213928 1228.061157,425.927856 1228.061157,401.268066 
   C1236.399292,401.268066 1244.458130,401.268066 1252.847168,401.268066 
   C1252.847168,447.524658 1252.847168,493.878876 1252.847168,540.536133 
   C1244.727051,540.536133 1236.676636,540.536133 1228.061157,540.536133 
   C1228.061157,518.689575 1228.061157,497.094788 1228.061157,475.000000 
z"
      />
      <path
         className="shsc-logo-bg"
        opacity="1.000000"
        stroke="none"
        d="
M668.778320,499.999878 
   C668.778320,466.854156 668.778320,434.208435 668.778320,401.229797 
   C677.105469,401.229797 685.191162,401.229797 693.627319,401.229797 
   C693.627319,447.501068 693.627319,493.877716 693.627319,540.550781 
   C685.457764,540.550781 677.394653,540.550781 668.778320,540.550781 
   C668.778320,527.061218 668.778320,513.780579 668.778320,499.999878 
z"
      />
      <path
        className="nhs-logo-background"
        opacity="1.000000"
        stroke="none"
        d="
M784.957947,819.882874 
   C792.427063,819.619019 798.136292,822.679810 803.838501,828.027954 
   C803.838501,815.530334 803.838501,804.144409 803.838501,792.417297 
   C809.531189,792.417297 814.788269,792.417297 820.460083,792.417297 
   C820.460083,823.123901 820.460083,853.998901 820.460083,885.175171 
   C815.387756,885.175171 810.143677,885.175171 804.683228,885.175171 
   C804.465149,883.252747 804.264221,881.481995 804.000732,879.159729 
   C798.233276,884.642029 791.711548,887.194031 784.055054,886.989319 
   C772.191833,886.672119 763.472595,880.328430 759.988770,868.873291 
   C757.118103,859.433838 756.973022,849.825623 759.223755,840.245789 
   C762.328064,827.032776 770.845398,820.257507 784.957947,819.882874 
M786.967346,833.133240 
   C782.035400,833.972473 778.985168,837.123413 777.358276,841.565552 
   C774.523926,849.304565 774.331543,857.129028 777.419983,864.901855 
   C779.481262,870.089661 782.881897,873.618835 788.797546,873.783813 
   C794.817566,873.951782 798.912048,870.858276 801.240234,865.551941 
   C804.675354,857.722900 804.715942,849.681702 801.394226,841.840271 
   C798.921509,836.003052 794.514587,832.586243 786.967346,833.133240 
z"
      />
      <path
        className="shsc-logo-bg"
        opacity="1.000000"
        stroke="none"
        d="
M1293.540771,650.004395 
   C1293.572510,644.841736 1293.598633,640.177795 1293.648315,635.514038 
   C1293.651733,635.200684 1293.832642,634.889160 1294.042114,634.223450 
   C1300.995361,634.223450 1308.059570,634.223450 1315.584961,634.223450 
   C1315.584961,640.115723 1315.584961,646.049561 1315.584961,651.983398 
   C1322.977539,638.173279 1332.787476,629.373108 1349.841431,632.484314 
   C1349.841431,640.811890 1349.841431,648.903809 1349.841431,657.018250 
   C1346.270142,656.652466 1342.842407,656.057922 1339.405396,655.997375 
   C1330.979126,655.848999 1324.850708,659.299438 1322.507935,667.295288 
   C1320.341675,674.687988 1319.269043,682.571838 1318.934570,690.292175 
   C1318.373413,703.242676 1318.781006,716.235107 1318.781006,729.621033 
   C1310.431519,729.621033 1302.370605,729.621033 1293.540894,729.621033 
   C1293.540894,703.327393 1293.540894,676.915283 1293.540771,650.004395 
z"
      />
      <path
        className="nhs-logo-background"
        opacity="1.000000"
        stroke="none"
        d="
M388.206177,828.775818 
   C392.190643,830.873047 395.866669,832.790833 399.621094,834.540344 
   C402.632690,835.943787 405.814148,836.991577 408.790710,838.459778 
   C419.793091,843.886475 424.184723,851.793762 423.092499,863.909546 
   C422.128357,874.605225 416.015900,881.813416 404.825684,885.051636 
   C392.740082,888.548889 380.798004,887.266357 368.377716,883.406616 
   C368.846619,878.436096 369.320435,873.413147 369.732666,869.043274 
   C376.758179,870.421204 383.313293,871.879456 389.935730,872.901917 
   C391.959534,873.214417 394.260529,872.606689 396.273407,871.936340 
   C400.762085,870.441528 404.449188,867.858826 404.856140,862.651550 
   C405.251343,857.594421 402.062775,854.642822 397.925934,852.691895 
   C392.815277,850.281616 387.534790,848.232910 382.410339,845.850159 
   C370.354980,840.244385 365.794708,832.278320 367.208038,819.587463 
   C368.454468,808.395325 375.584534,800.648499 387.670807,798.048767 
   C397.807159,795.868591 407.845856,796.864441 418.067780,800.217102 
   C417.606506,804.835632 417.150116,809.405273 416.722839,813.683105 
   C410.431244,812.744019 404.442230,811.204041 398.453369,811.204529 
   C395.003754,811.204834 390.971924,812.987427 388.239655,815.231079 
   C384.013062,818.701843 384.332184,823.615051 388.206177,828.775818 
z"
      />
      <path
        className="nhs-logo-background"
        opacity="1.000000"
        stroke="none"
        d="
M547.443481,823.345032 
   C566.064514,815.173706 585.626892,821.333313 593.756165,837.638977 
   C601.506470,853.184265 596.045837,874.053772 582.076111,882.278198 
   C566.386414,891.515259 544.923096,887.367554 535.297485,872.879639 
   C525.683105,858.408691 528.374329,832.592651 547.443481,823.345032 
M579.063721,846.764282 
   C578.720764,845.654480 578.438293,844.521545 578.024841,843.438660 
   C575.430725,836.644470 570.552307,833.098877 563.763123,833.031311 
   C557.045044,832.964417 551.548157,836.353027 549.221497,843.066406 
   C546.458374,851.039246 546.788940,859.073486 550.913513,866.691589 
   C553.889160,872.187622 558.772827,874.013611 564.684448,873.756165 
   C570.598572,873.498596 575.293274,870.659363 576.836365,865.093262 
   C578.394470,859.473328 578.444519,853.435303 579.063721,846.764282 
z"
      />
      <path
        className="nhs-logo-background"
        opacity="1.000000"
        stroke="none"
        d="
M1051.270996,842.823303 
   C1055.289551,858.794373 1050.723389,873.313293 1039.471191,881.145264 
   C1028.211792,888.982361 1010.227600,888.981445 998.901917,881.142944 
   C987.769104,873.438049 983.009033,858.676514 986.819519,843.674622 
   C990.185913,830.420898 1000.713074,821.443298 1014.514099,820.056580 
   C1032.472290,818.252014 1045.569214,826.255676 1051.270996,842.823303 
M1018.672729,873.782410 
   C1025.439575,874.024841 1030.330688,870.953003 1032.822021,864.811707 
   C1035.644409,857.853943 1036.133667,850.556885 1033.656616,843.342773 
   C1031.324219,836.550232 1025.890137,832.913330 1018.815308,833.022949 
   C1012.031738,833.128052 1006.336365,836.691711 1004.928467,843.609680 
   C1003.743469,849.432678 1003.737488,855.767273 1004.612122,861.667480 
   C1005.654663,868.700073 1010.374573,873.081604 1018.672729,873.782410 
z"
      />
      <path
        className="nhs-logo-background"
        opacity="1.000000"
        stroke="none"
        d="
M1124.543823,865.999207 
   C1124.543823,872.625366 1124.543823,878.751831 1124.543823,885.185974 
   C1118.905029,885.185974 1113.643433,885.185974 1107.678955,885.185974 
   C1107.678955,883.449585 1107.695312,881.686218 1107.676270,879.923218 
   C1107.544556,867.775330 1107.522095,855.624451 1107.211426,843.481018 
   C1107.087891,838.655273 1105.009766,834.673950 1099.943848,833.275269 
   C1094.654175,831.814880 1088.542236,833.883423 1086.335449,839.064636 
   C1084.182007,844.120361 1082.906738,849.823608 1082.577393,855.322388 
   C1081.992188,865.091309 1082.413086,874.920471 1082.413086,885.096680 
   C1076.906860,885.096680 1071.653564,885.096680 1065.983643,885.096680 
   C1065.983643,864.067627 1065.983643,842.869507 1065.983643,821.385498 
   C1071.093018,821.385498 1076.149536,821.385498 1081.501831,821.385498 
   C1081.676758,823.779785 1081.838989,825.999939 1082.065674,829.100952 
   C1087.599487,822.685791 1094.046875,819.932617 1101.739258,819.854492 
   C1114.705078,819.722717 1122.733276,826.157776 1123.994263,839.066284 
   C1124.848755,847.811768 1124.406250,856.683960 1124.543823,865.999207 
z"
      />
      <path
         className="shsc-logo-bg"
        opacity="1.000000"
        stroke="none"
        d="
M538.585449,512.000366 
   C538.585388,521.783875 538.585388,531.067383 538.585388,540.599243 
   C530.059265,540.599243 522.120605,540.599243 513.736938,540.599243 
   C513.736938,508.896942 513.736938,477.178925 513.736938,445.034851 
   C521.705688,445.034851 529.889954,445.034851 538.585449,445.034851 
   C538.585449,467.285522 538.585449,489.392914 538.585449,512.000366 
z"
      />
      <path
        className="nhs-logo-background"
        opacity="1.000000"
        stroke="none"
        d="
M863.568359,832.183838 
   C855.848083,833.766968 848.549438,835.376648 840.702393,837.107300 
   C840.702393,834.300293 840.470947,830.510864 840.835449,826.779602 
   C840.953308,825.572815 842.416809,823.958740 843.636047,823.483337 
   C853.489380,819.641907 863.691345,818.725159 874.035400,820.956665 
   C883.261047,822.946777 889.119934,829.308411 889.931519,839.076355 
   C890.701538,848.344849 890.593506,857.689941 890.763123,867.003296 
   C890.871765,872.966431 890.783569,878.933044 890.783569,885.196289 
   C886.554382,885.196289 881.955261,885.196289 876.782654,885.196289 
   C876.453491,882.981445 876.100403,880.605469 875.665710,877.680603 
   C874.590210,878.555542 873.721252,879.153625 872.980347,879.882019 
   C865.676941,887.062317 855.285095,889.010498 845.768433,884.953247 
   C837.820312,881.564819 833.462402,875.277466 833.498840,867.251282 
   C833.537659,858.724243 838.035889,851.564392 846.296997,848.926514 
   C851.908264,847.134766 857.954895,846.573547 863.855408,845.869629 
   C867.438416,845.442200 871.113953,845.790771 874.702637,845.790771 
   C875.405762,837.372437 872.858093,834.266846 863.568359,832.183838 
M853.058838,859.518555 
   C849.904480,862.433533 848.925476,866.039307 850.546265,869.935791 
   C852.216309,873.950500 856.089722,874.344177 859.820374,874.429871 
   C869.441406,874.651062 875.400940,867.494934 874.894409,856.092834 
   C867.631714,855.660095 860.387329,855.151550 853.058838,859.518555 
z"
      />
      <path
        className="nhs-logo-background"
        opacity="1.000000"
        stroke="none"
        d="
M686.012329,883.521240 
   C685.993164,862.614075 685.993164,842.165100 685.993164,821.414062 
   C691.285583,821.414062 696.185181,821.414062 701.498291,821.414062 
   C701.702576,823.669617 701.901733,825.868469 702.185974,829.007324 
   C707.688232,823.346863 713.550659,820.106812 720.905884,819.870422 
   C735.157898,819.412476 744.020264,826.654114 744.616821,840.880005 
   C745.229492,855.490234 744.752747,870.146118 744.752747,885.129272 
   C739.205322,885.129272 733.801086,885.129272 728.049194,885.129272 
   C728.049194,874.109985 728.090088,863.310730 728.023743,852.512085 
   C728.004395,849.358398 727.868652,846.181274 727.451355,843.059692 
   C726.794861,838.147888 724.942871,833.994873 719.299438,833.116882 
   C713.695312,832.244995 708.152222,834.596008 706.078613,839.853516 
   C704.290649,844.386902 703.160767,849.409973 702.919861,854.274963 
   C702.434570,864.075073 702.754089,873.915039 702.730225,883.738525 
   C702.729431,884.063904 702.605835,884.389099 702.275452,886.009766 
   C696.955566,885.344849 691.493591,884.662170 686.012329,883.521240 
z"
      />
      <path
        className="nhs-logo-background"
        opacity="1.000000"
        stroke="none"
        d="
M652.083496,830.069092 
   C652.083496,826.953796 652.083496,824.328125 652.083496,821.417847 
   C657.827148,821.417847 663.070496,821.417847 668.642578,821.417847 
   C668.642578,842.602234 668.642578,863.686829 668.642578,885.124512 
   C663.639526,885.124512 658.561157,885.124512 653.144897,885.124512 
   C653.031921,882.864136 652.920349,880.632080 652.774719,877.717224 
   C651.238892,878.943237 650.124207,879.804688 649.040161,880.703125 
   C642.777039,885.894104 635.549194,887.834656 627.576111,886.731689 
   C618.915466,885.533752 612.565430,879.521423 610.940979,870.428284 
   C610.040710,865.388977 609.994080,860.162476 609.929382,855.015747 
   C609.789612,843.899109 609.886353,832.779480 609.886353,821.390625 
   C615.695312,821.390625 620.961243,821.390625 626.623962,821.390625 
   C626.732544,822.588501 626.942200,823.845459 626.945557,825.103027 
   C626.976318,836.769531 626.878540,848.437134 627.008789,860.102295 
   C627.100464,868.305420 630.009216,872.746643 635.427063,873.623352 
   C642.133179,874.708496 647.583496,871.323853 650.174255,863.794739 
   C651.342651,860.399292 651.869263,856.662415 651.999695,853.055603 
   C652.270691,845.565430 652.082825,838.058655 652.083496,830.069092 
z"
      />
      <path
        className="nhs-logo-background"
        opacity="1.000000"
        stroke="none"
        d="
M1341.048584,825.192383 
   C1341.048828,845.447571 1341.048828,865.223083 1341.048828,885.236023 
   C1335.572632,885.236023 1330.474243,885.236023 1325.091797,885.236023 
   C1325.091797,882.903870 1325.091797,880.772766 1325.091797,877.698486 
   C1323.367065,879.014954 1322.244263,879.822693 1321.175171,880.696167 
   C1315.053955,885.696899 1308.042358,887.707214 1300.227905,886.812317 
   C1290.097046,885.652161 1282.846313,878.059998 1282.292603,866.087646 
   C1281.610352,851.334167 1282.129517,836.525146 1282.129517,821.427124 
   C1287.629028,821.427124 1293.011963,821.427124 1299.014648,821.427124 
   C1299.014648,830.550232 1298.959106,839.483154 1299.035522,848.414917 
   C1299.081055,853.731750 1299.074097,859.071533 1299.574829,864.355103 
   C1299.989624,868.731995 1302.008179,872.431641 1306.800903,873.448364 
   C1311.714111,874.490601 1316.751831,873.642273 1319.189453,869.218018 
   C1321.654907,864.743469 1323.496094,859.431213 1323.818481,854.371704 
   C1324.504150,843.606812 1324.027710,832.767883 1324.027710,821.132141 
   C1329.151611,821.132141 1334.077148,820.978943 1338.972900,821.292175 
   C1339.734619,821.340942 1340.359985,823.519165 1341.048584,825.192383 
z"
      />
      <path
        className="nhs-logo-background"
        opacity="1.000000"
        stroke="none"
        d="
M516.669067,812.345398 
   C507.933411,812.340515 499.662781,812.340515 491.191132,812.340515 
   C491.191132,819.514404 491.191132,826.115051 491.191132,833.357727 
   C500.736725,833.357727 510.267731,833.357727 519.957886,833.357727 
   C519.957886,838.292419 519.957886,842.587219 519.957886,847.309326 
   C510.386017,847.309326 500.953217,847.309326 491.038605,847.309326 
   C491.038605,860.132568 491.038605,872.542053 491.038605,885.210083 
   C485.113037,885.210083 479.669830,885.210083 473.860535,885.210083 
   C473.860535,856.489929 473.860535,827.810303 473.860535,798.765015 
   C489.744293,798.765015 505.601440,798.765015 521.677612,798.765015 
   C521.677612,803.312134 521.677612,807.577881 521.677612,812.128052 
   C520.081543,812.206116 518.607788,812.278198 516.669067,812.345398 
z"
      />
      <path
        className="shsc-logo-bg"
        opacity="1.000000"
        stroke="none"
        d="
M816.985413,687.000000 
   C816.985413,669.201538 816.985413,651.903137 816.985413,634.250916 
   C825.403320,634.250916 833.448486,634.250916 841.802063,634.250916 
   C841.802063,665.968811 841.802063,697.561157 841.802063,729.593872 
   C833.843750,729.593872 825.788757,729.593872 816.985413,729.593872 
   C816.985413,715.669556 816.985413,701.584778 816.985413,687.000000 
z"
      />
      <path
        className="nhs-logo-background"
        opacity="1.000000"
        stroke="none"
        d="
M1214.000244,798.392334 
   C1221.457642,798.392334 1228.415283,798.392334 1235.632935,798.392334 
   C1235.632935,803.225342 1235.632935,807.487854 1235.632935,812.227783 
   C1227.688965,812.227783 1219.746460,812.227783 1211.265503,812.227783 
   C1211.265503,836.735229 1211.265503,860.779846 1211.265503,885.153076 
   C1205.142090,885.153076 1199.560059,885.153076 1193.367188,885.153076 
   C1193.367188,861.070251 1193.367188,837.044739 1193.367188,812.497559 
   C1184.876831,812.497559 1176.972290,812.497559 1168.764404,812.497559 
   C1168.764404,807.736450 1168.764404,803.494324 1168.764404,798.392273 
   C1183.685181,798.392273 1198.592773,798.392273 1214.000244,798.392334 
z"
      />
      <path
        className="nhs-logo-background"
        opacity="1.000000"
        stroke="none"
        d="
M1378.100098,819.872803 
   C1384.661987,820.402344 1390.744507,820.935730 1397.261108,821.507141 
   C1396.890381,826.280090 1396.585938,830.200439 1396.266724,834.310608 
   C1391.443115,833.553162 1387.084961,832.639771 1382.681519,832.273193 
   C1380.449463,832.087402 1377.782349,832.304077 1375.957275,833.406860 
   C1374.112915,834.521179 1372.114136,836.882202 1371.963501,838.834473 
   C1371.836548,840.477295 1373.900513,843.039673 1375.640137,843.920654 
   C1379.317261,845.783081 1383.448975,846.726624 1387.335571,848.201355 
   C1396.469849,851.667236 1400.298950,856.841614 1400.352783,865.578674 
   C1400.409546,874.791992 1396.315552,880.841553 1387.544067,884.381958 
   C1376.605347,888.796875 1365.740845,887.053833 1354.506348,884.154236 
   C1354.800171,879.626831 1355.075806,875.379578 1355.351074,871.139099 
   C1360.332153,872.263733 1364.937988,873.454407 1369.607666,874.285034 
   C1371.522339,874.625610 1373.615356,874.409790 1375.556641,874.058228 
   C1379.227905,873.393494 1382.290771,871.697510 1382.683350,867.519409 
   C1383.058960,863.521301 1380.440552,861.434021 1377.107056,860.197693 
   C1373.213257,858.753418 1369.223755,857.569275 1365.304199,856.191528 
   C1358.551392,853.817810 1354.495972,849.239807 1353.917114,841.925476 
   C1353.299194,834.115540 1355.692871,827.430786 1362.915527,823.932190 
   C1367.416138,821.752197 1372.692627,821.173828 1378.100098,819.872803 
z"
      />
      <path
        className="nhs-logo-background"
        opacity="1.000000"
        stroke="none"
        d="
M1418.732666,853.002197 
   C1418.732788,846.387024 1418.732788,840.271118 1418.732788,833.753235 
   C1414.361084,833.753235 1410.595947,833.753235 1406.532593,833.753235 
   C1406.532593,829.446411 1406.532593,825.554993 1406.532593,821.216675 
   C1410.500977,821.216675 1414.260376,821.216675 1418.576416,821.216675 
   C1418.576416,816.715515 1418.576416,812.640076 1418.576416,808.491089 
   C1424.232178,806.696167 1429.397949,805.056763 1435.110962,803.243652 
   C1435.110962,809.175476 1435.110962,814.736755 1435.110962,820.960938 
   C1440.255371,820.960938 1444.946777,820.960938 1449.929443,820.960938 
   C1449.929443,825.502319 1449.929443,829.378845 1449.929443,833.794495 
   C1445.077271,833.794495 1440.495483,833.794495 1435.759766,833.794495 
   C1435.759766,845.243591 1435.483521,856.166809 1435.885986,867.065002 
   C1436.065063,871.917236 1439.130371,873.898682 1444.107056,873.673584 
   C1446.214966,873.578125 1448.307739,873.150940 1450.831055,872.818787 
   C1450.831055,877.127747 1450.831055,881.192139 1450.831055,886.685181 
   C1445.284668,886.685181 1439.657227,887.265991 1434.198242,886.554749 
   C1425.065063,885.364868 1419.448486,878.632690 1418.873413,868.967468 
   C1418.567383,863.827637 1418.763306,858.657837 1418.732666,853.002197 
z"
      />
      <path
        className="nhs-logo-background"
        opacity="1.000000"
        stroke="none"
        d="
M901.385559,833.716797 
   C900.437988,829.680359 899.949768,825.734558 899.391052,821.218323 
   C904.135010,821.218323 907.890930,821.218323 911.951355,821.218323 
   C912.069397,819.822205 912.204224,818.879517 912.220398,817.934937 
   C912.384155,808.378967 912.378662,808.378662 921.703308,805.463196 
   C923.913940,804.772095 926.124878,804.081970 929.124695,803.144836 
   C929.124695,806.421265 929.124695,809.316589 929.124695,812.211914 
   C929.124695,814.998291 929.124695,817.784607 929.124695,820.951660 
   C934.053589,820.951660 938.496887,820.951660 943.395630,820.951660 
   C943.395630,825.206970 943.395630,829.090942 943.395630,833.554382 
   C938.988708,833.554382 934.409912,833.554382 929.461304,833.554382 
   C929.461304,845.202454 929.185425,856.298340 929.598389,867.368469 
   C929.761230,871.734192 933.070618,874.041382 937.630005,873.730286 
   C939.757446,873.585083 941.866821,873.175781 944.493774,872.816528 
   C944.493774,877.106995 944.493774,881.171021 944.493774,886.681885 
   C939.099365,886.681885 933.469421,887.263733 928.008118,886.551697 
   C918.763428,885.346313 913.115906,879.010559 912.558594,869.092102 
   C911.998535,859.125671 912.296204,849.110962 912.222290,839.117371 
   C912.210327,837.499756 912.220642,835.882019 912.220642,833.807556 
   C908.422485,833.807556 905.133728,833.807556 901.385559,833.716797 
z"
      />
      <path
        className="nhs-logo-background"
        opacity="1.000000"
        stroke="none"
        d="
M1250.920898,863.045776 
   C1250.919067,870.658447 1250.919067,877.778748 1250.919067,885.192505 
   C1245.265137,885.192505 1240.004150,885.192505 1234.363525,885.192505 
   C1234.363525,863.967163 1234.363525,842.811523 1234.363525,821.383118 
   C1239.111572,821.383118 1243.692505,821.383118 1248.692139,821.383118 
   C1248.692139,825.021851 1248.692139,828.607117 1248.692139,832.192383 
   C1249.021973,832.329895 1249.351929,832.467407 1249.681763,832.604858 
   C1250.409546,831.358948 1251.073975,830.070557 1251.875854,828.874329 
   C1255.720703,823.138123 1260.724609,819.545471 1268.021240,819.840027 
   C1270.608765,819.944397 1272.063110,820.474548 1271.922485,823.492920 
   C1271.722412,827.784485 1271.871582,832.092346 1271.871582,836.276489 
   C1271.219971,836.498047 1270.902588,836.727844 1270.636230,836.678955 
   C1258.695190,834.488464 1253.396484,838.158142 1251.728638,850.120972 
   C1251.156860,854.222839 1251.174561,858.406799 1250.920898,863.045776 
z"
      />
      <path
        className="nhs-logo-background"
        opacity="1.000000"
        stroke="none"
        d="
M955.506958,868.037903 
   C955.507141,852.288086 955.507141,836.989197 955.507141,821.410156 
   C961.191833,821.410156 966.427490,821.410156 972.012207,821.410156 
   C972.012207,842.579712 972.012207,863.638062 972.012207,885.076172 
   C966.844421,885.076172 961.604614,885.076172 955.506714,885.076172 
   C955.506714,879.627380 955.506714,874.058044 955.506958,868.037903 
z"
      />
      <path
         className="shsc-logo-bg"
        opacity="1.000000"
        stroke="none"
        d="
M513.306885,415.972168 
   C513.306763,411.535370 513.306763,407.587738 513.306763,403.362915 
   C521.892151,403.362915 529.922546,403.362915 538.270508,403.362915 
   C538.270508,411.256500 538.270508,418.970276 538.270508,426.949036 
   C530.015991,426.949036 521.954529,426.949036 513.307068,426.949036 
   C513.307068,423.333710 513.307068,419.897552 513.306885,415.972168 
z"
      />
      <path
         className="shsc-logo-bg"
        opacity="1.000000"
        stroke="none"
        d="
M838.869385,592.791138 
   C840.269958,593.451355 841.955811,594.086853 841.974365,594.767639 
   C842.167725,601.879761 842.093628,608.999207 842.093628,616.392273 
   C833.622375,616.392273 825.691833,616.392273 817.357910,616.392273 
   C817.357910,608.787109 817.357910,601.222290 817.357910,592.787476 
   C824.230957,592.787476 831.305786,592.787476 838.869385,592.791138 
z"
      />
      <path
        className="nhs-logo-background"
        opacity="1.000000"
        stroke="none"
        d="
M955.500183,801.075073 
   C955.500610,798.486694 955.500610,796.356201 955.500610,793.925049 
   C961.247070,793.925049 966.596924,793.925049 972.169678,793.925049 
   C972.169678,799.158936 972.169678,804.205078 972.169678,809.600708 
   C966.782654,809.600708 961.577209,809.600708 955.499756,809.600708 
   C955.499756,806.975525 955.499756,804.254272 955.500183,801.075073 
z"
      />
      <path
        fill="#252122"
        opacity="0.000000"
        stroke="none"
        d="
M828.896484,246.910156 
   C836.929993,209.385529 844.891968,172.274490 852.871277,135.167191 
   C855.633911,122.319725 858.439819,109.481552 861.327087,96.172829 
   C864.560730,96.172829 867.698303,96.173073 870.835876,96.172791 
   C889.500732,96.171120 908.166931,96.286308 926.829407,96.076172 
   C930.924561,96.030060 932.772827,97.029991 934.011902,101.270462 
   C947.595947,147.758194 961.447815,194.167709 975.236450,240.595627 
   C975.465637,241.367584 975.814453,242.104034 977.015320,242.912628 
   C978.524048,235.736420 980.074890,228.568756 981.534607,221.382584 
   C989.751953,180.927353 997.960449,140.470337 1006.116028,100.002617 
   C1006.588074,97.660255 1007.083618,96.078568 1010.061279,96.102303 
   C1026.225098,96.231194 1042.390381,96.178505 1058.555176,96.209747 
   C1059.019043,96.210640 1059.482422,96.450516 1060.386108,96.700897 
   C1059.269775,102.252098 1058.218018,107.778130 1057.043579,113.277969 
   C1043.416992,177.092194 1029.742554,240.896301 1016.220764,304.732697 
   C1015.574036,307.785889 1014.632874,308.844910 1011.464294,308.825256 
   C990.467102,308.695099 969.468384,308.695953 948.471313,308.835052 
   C945.437622,308.855133 944.129761,307.931335 943.255249,305.016357 
   C931.775146,266.752869 920.179321,228.524094 908.590515,190.293289 
   C905.752014,180.929398 902.834167,171.589600 899.055115,162.262436 
   C897.843262,167.862213 896.564331,173.448425 895.430298,179.063919 
   C886.961670,221.000778 878.505859,262.940247 870.114929,304.892639 
   C869.649048,307.221741 869.348938,308.874542 866.278564,308.847565 
   C850.115173,308.705414 833.950012,308.765167 817.785461,308.734070 
   C817.310425,308.733154 816.835815,308.501709 815.766479,308.222473 
   C820.125549,287.904724 824.478638,267.614960 828.896484,246.910156 
z"
      />
      <path
        fill="#252122"
        opacity="0.000000"
        stroke="none"
        d="
M1206.447510,98.427109 
   C1207.575562,97.409798 1208.577393,96.278061 1209.585693,96.272438 
   C1227.082397,96.174957 1244.579712,96.211845 1262.077026,96.249268 
   C1262.515991,96.250206 1262.954346,96.590294 1263.745483,96.918404 
   C1249.023193,167.425003 1234.307007,237.903091 1219.559448,308.530975 
   C1200.543213,308.530975 1181.934570,308.530975 1162.608032,308.530975 
   C1168.878296,278.298706 1175.107300,248.265427 1181.409668,217.878403 
   C1179.549805,217.772064 1178.262207,217.635513 1176.974365,217.634247 
   C1157.310059,217.614792 1137.644653,217.719040 1117.982422,217.510986 
   C1114.368652,217.472763 1113.306519,218.565002 1112.604858,222.032166 
   C1106.794678,250.744064 1100.728516,279.404175 1094.670654,308.411133 
   C1075.881714,308.411133 1057.137573,308.411133 1037.668701,308.411133 
   C1052.445801,237.643463 1067.189087,167.037231 1081.949463,96.349464 
   C1101.083252,96.349464 1119.713135,96.349464 1138.912354,96.349464 
   C1133.379639,123.231300 1127.895020,149.879013 1122.312500,177.002380 
   C1123.879639,177.179825 1125.110718,177.439270 1126.342163,177.440720 
   C1146.006470,177.463715 1165.672119,177.333527 1185.333984,177.553650 
   C1189.223877,177.597168 1190.374023,176.179413 1191.082520,172.666412 
   C1196.050293,148.033783 1201.219604,123.441780 1206.447510,98.427109 
z"
      />
      <path
        fill="#242122"
        opacity="0.000000"
        stroke="none"
        d="
M1337.722168,138.526611 
   C1323.206665,146.201996 1322.105957,160.986633 1335.708740,169.300919 
   C1343.589111,174.117630 1352.578369,177.103531 1361.008911,181.041138 
   C1368.539673,184.558441 1376.357544,187.650345 1383.405151,191.977936 
   C1405.555542,205.579391 1412.101074,225.065933 1408.445557,250.389481 
   C1404.031006,280.973389 1383.854980,297.380157 1356.020020,305.539490 
   C1317.002441,316.976837 1278.282837,313.140930 1239.144409,301.369659 
   C1243.560181,287.100037 1247.902100,273.068787 1252.357666,258.670563 
   C1267.954590,266.649933 1284.357178,269.842133 1301.299194,270.307739 
   C1314.157104,270.661102 1327.011108,270.250305 1338.805908,264.042358 
   C1354.026978,256.031158 1355.418091,237.660782 1340.804688,228.602737 
   C1331.842529,223.047592 1321.591553,219.579681 1311.948120,215.112442 
   C1305.461548,212.107513 1298.767700,209.435883 1292.613159,205.848801 
   C1271.562134,193.579620 1265.388916,176.442123 1268.472290,153.383377 
   C1272.871948,120.482239 1294.707764,104.411949 1324.667114,96.945808 
   C1348.952881,90.893608 1373.619873,91.837997 1398.189453,94.753754 
   C1408.174561,95.938713 1417.982056,98.620575 1428.659424,100.780960 
   C1423.746338,115.867172 1419.253052,129.664490 1414.929443,142.940582 
   C1404.599609,140.397415 1394.938965,137.219116 1385.025269,135.761719 
   C1369.311035,133.451630 1353.476562,133.108902 1337.722168,138.526611 
z"
      />
      <path
        fill="#252223"
        opacity="0.000000"
        stroke="none"
        d="
M780.518188,477.691132 
   C784.196655,488.550964 783.951355,498.966431 779.974304,509.303589 
   C776.500671,518.332397 769.992798,523.221069 761.319824,523.278992 
   C752.813904,523.335815 746.501038,518.749817 743.191956,509.517761 
   C738.933716,497.637512 738.927551,485.704926 743.790649,473.936981 
   C746.695312,466.907959 751.749146,462.473419 759.540344,461.947815 
   C767.657410,461.400208 773.607483,465.186157 777.781433,471.936279 
   C778.822998,473.620575 779.499756,475.530426 780.518188,477.691132 
z"
      />
      <path
        fill="#252223"
        opacity="0.000000"
        stroke="none"
        d="
M396.637054,652.107422 
   C403.129272,654.200439 407.182037,658.508362 409.635803,664.211121 
   C415.059113,676.815430 414.914062,689.473206 408.883392,701.832031 
   C405.275818,709.225098 399.093994,713.077942 390.790649,712.765564 
   C382.573639,712.456360 377.185608,707.801514 374.191101,700.449646 
   C369.413391,688.719788 369.283569,676.750854 373.536835,664.864990 
   C377.359802,654.181702 385.014618,650.010681 396.637054,652.107422 
z"
      />
      <path
        fill="#252223"
        opacity="0.000000"
        stroke="none"
        d="
M680.229858,706.202515 
   C666.724487,718.388306 647.808105,713.445618 642.414490,696.530640 
   C639.206665,686.470337 638.460449,676.322632 642.579773,666.240234 
   C646.400635,656.888428 653.744751,651.561584 663.099915,651.429443 
   C673.728149,651.279358 681.683777,655.963501 685.247314,665.991089 
   C690.187744,679.893494 689.216858,693.473816 680.229858,706.202515 
z"
      />
      <path
        fill="#272425"
        opacity="0.000000"
        stroke="none"
        d="
M1062.999268,483.152649 
   C1054.701294,483.152374 1046.903320,483.152374 1039.158447,483.152374 
   C1037.791992,472.150360 1046.342285,461.951752 1057.418579,460.923309 
   C1071.092163,459.653656 1079.373169,467.461548 1080.188599,483.152954 
   C1074.618042,483.152954 1069.058594,483.152954 1062.999268,483.152649 
z"
      />
      <path
        fill="#272425"
        opacity="0.000000"
        stroke="none"
        d="
M606.683289,460.839783 
   C618.411255,462.755280 624.609436,470.586945 624.322327,482.886505 
   C610.619019,482.886505 596.907593,482.886505 583.198547,482.886505 
   C582.823486,468.577484 594.360352,459.329529 606.683289,460.839783 
z"
      />
      <path
        fill="#272425"
        opacity="0.000000"
        stroke="none"
        d="
M286.947235,469.041443 
   C294.020477,461.190857 303.468109,458.641541 311.929260,462.052124 
   C319.429382,465.075378 324.282837,473.386566 323.919098,482.839203 
   C322.823120,482.940247 321.696167,483.132568 320.568909,483.134491 
   C307.952728,483.155945 295.336517,483.148041 281.161438,483.148041 
   C283.262726,477.952087 285.003204,473.648315 286.947235,469.041443 
z"
      />
      <path
        fill="#272425"
        opacity="0.000000"
        stroke="none"
        d="
M1398.691040,650.536377 
   C1413.553223,649.766235 1421.201294,656.862183 1421.965942,672.059082 
   C1408.150024,672.059082 1394.351074,672.059082 1380.537476,672.059082 
   C1381.146240,659.958618 1387.071533,652.865601 1398.691040,650.536377 
z"
      />
      <path
        fill="#272425"
        opacity="0.000000"
        stroke="none"
        d="
M903.656738,713.875244 
   C901.073792,713.767944 898.876160,713.883545 896.780823,713.474854 
   C890.389526,712.228027 886.881775,708.938232 886.131470,703.821167 
   C885.312317,698.233643 888.010376,691.829041 893.630676,689.932068 
   C900.118286,687.742432 907.105286,686.927490 913.932922,685.896057 
   C917.150085,685.410034 920.501587,685.812683 923.726562,685.812683 
   C926.117737,700.004517 917.992493,711.441589 903.656738,713.875244 
z"
      />
      <path
        fill="#272425"
        opacity="0.000000"
        stroke="none"
        d="
M1172.945190,496.291199 
   C1175.528687,496.273468 1177.639038,496.273468 1179.681152,496.273468 
   C1181.517944,514.428955 1169.622559,526.548218 1153.098633,524.037720 
   C1147.029175,523.115540 1143.104858,520.049255 1142.087891,515.434326 
   C1140.918335,510.126831 1142.886230,503.404144 1148.014160,501.299713 
   C1154.296509,498.721558 1161.286743,497.841858 1168.000366,496.361847 
   C1169.425537,496.047668 1170.978271,496.311432 1172.945190,496.291199 
z"
      />
      <path
        fill="#272425"
        opacity="0.000000"
        stroke="none"
        d="
M1208.085938,697.338379 
   C1210.133179,691.507324 1214.579468,688.623901 1219.726440,687.769104 
   C1228.122314,686.374939 1236.677124,685.937927 1245.152588,685.098267 
   C1247.063599,702.604614 1236.120728,714.868469 1220.648193,713.925476 
   C1210.424194,713.302429 1205.940430,707.572266 1208.085938,697.338379 
z"
      />
      <path
        fill="#272425"
        opacity="0.000000"
        stroke="none"
        d="
M175.731827,711.980591 
   C170.493347,712.519287 165.570541,713.508728 160.749695,713.145447 
   C155.778229,712.770752 151.994385,709.731445 151.151474,704.414612 
   C150.282349,698.932495 151.570038,693.785217 156.620682,690.951965 
   C160.448730,688.804688 164.836075,687.010193 169.142685,686.511841 
   C175.525909,685.773193 182.057983,686.320740 188.591446,686.320740 
   C190.813583,695.889954 185.719986,706.447754 175.731827,711.980591 
z"
      />
      <path
        fill="#272425"
        opacity="0.000000"
        stroke="none"
        d="
M787.374451,833.106445 
   C794.514587,832.586243 798.921509,836.003052 801.394226,841.840271 
   C804.715942,849.681702 804.675354,857.722900 801.240234,865.551941 
   C798.912048,870.858276 794.817566,873.951782 788.797546,873.783813 
   C782.881897,873.618835 779.481262,870.089661 777.419983,864.901855 
   C774.331543,857.129028 774.523926,849.304565 777.358276,841.565552 
   C778.985168,837.123413 782.035400,833.972473 787.374451,833.106445 
z"
      />
      <path
        fill="#252223"
        opacity="0.000000"
        stroke="none"
        d="
M579.109497,847.172363 
   C578.444519,853.435303 578.394470,859.473328 576.836365,865.093262 
   C575.293274,870.659363 570.598572,873.498596 564.684448,873.756165 
   C558.772827,874.013611 553.889160,872.187622 550.913513,866.691589 
   C546.788940,859.073486 546.458374,851.039246 549.221497,843.066406 
   C551.548157,836.353027 557.045044,832.964417 563.763123,833.031311 
   C570.552307,833.098877 575.430725,836.644470 578.024841,843.438660 
   C578.438293,844.521545 578.720764,845.654480 579.109497,847.172363 
z"
      />
      <path
        fill="#272425"
        opacity="0.000000"
        stroke="none"
        d="
M1018.262451,873.774780 
   C1010.374573,873.081604 1005.654663,868.700073 1004.612122,861.667480 
   C1003.737488,855.767273 1003.743469,849.432678 1004.928467,843.609680 
   C1006.336365,836.691711 1012.031738,833.128052 1018.815308,833.022949 
   C1025.890137,832.913330 1031.324219,836.550232 1033.656616,843.342773 
   C1036.133667,850.556885 1035.644409,857.853943 1032.822021,864.811707 
   C1030.330688,870.953003 1025.439575,874.024841 1018.262451,873.774780 
z"
      />
      <path
        fill="#272425"
        opacity="0.000000"
        stroke="none"
        d="
M853.379028,859.304260 
   C860.387329,855.151550 867.631714,855.660095 874.894409,856.092834 
   C875.400940,867.494934 869.441406,874.651062 859.820374,874.429871 
   C856.089722,874.344177 852.216309,873.950500 850.546265,869.935791 
   C848.925476,866.039307 849.904480,862.433533 853.379028,859.304260 
z"
      />
    </svg>
  );
};

export default NewLogo;
