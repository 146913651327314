import React from "react";

import { InnerHeaderBlock } from "../../components/innerHeaderBlock/InnerHeaderBlock";
import { PageLayout } from "../../components/PageLayout";
import { useSelector } from "react-redux";
import LoginResponse from "../../types/auth";
import { useNavigate } from "react-router-dom";

interface ReportAboutProps {}

export const ReportAbout: React.FC<ReportAboutProps> = () => {
  const userLoginData: LoginResponse = useSelector(
    (state: any) => state.adminLogin
  );
  const navigate = useNavigate();

  const handleBackBtn = () => {
    if (userLoginData.isUserLoggedIn && userLoginData.terms_and_condition) {
      navigate(-1);
    } else {
      navigate("/admin");
    }
  };

  return (
    <PageLayout size="lg" role="commissioner">
      <div>
        <InnerHeaderBlock
          backtext="Back"
          showSignOut={userLoginData?.isUserLoggedIn}
          heading="About"
          handleBackBtn={handleBackBtn}
        >
          <div className="head-info">
            <p>
              Safe2Share aims to collect quantitative feedback from service
              user, carers and support networks to discover where the quality of
              care is reportedly low.
            </p>
            <p>
              By collecting this information, it is shared in a heat map form to
              provide oversight of how units are performing and where perhaps
              further support may be needed.
            </p>
            <p>
              We trust that you will interpret the results of the heatmap
              alongside local knowledge on the individual unit, and escalate any
              concerns within existing quality, risk management and patient
              feedback protocols.
            </p>
          </div>
        </InnerHeaderBlock>
      </div>
    </PageLayout>
  );
};
