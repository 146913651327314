import React from 'react'
import { PageLayout } from '../../components/PageLayout';
import { reportContent } from "../../content/report/report-content";

export const ReportPilot = () => {
  return (
    <PageLayout size="lg" role="commissioner">
      <div className='dashboard-block'>
        <h2 className='fb-notfound-title text-center mb-10'>{reportContent.pilotTitle}</h2>
        <div dangerouslySetInnerHTML={{ __html: reportContent.pilotMessage }}></div>
      </div>
    </PageLayout>
  );
}