import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import {
  SelectDropdown,
  ErrorMessage,
  AverageAssessment,
} from "../../components";
import { PageLayout } from "../../components/PageLayout";
import {
  AuditTrailDataView,
  AuditTrailCatList,
  commissionerTypes,
} from "../../data/userdata";

import Table from "../../components/table/Table";
import { InnerHeaderBlock } from "../../components/innerHeaderBlock/InnerHeaderBlock";
import { DatePicker } from "../../components/datepicker/DatePicker";
import {
  getUnitListService,
  getDemographyFeedbackStatsService,
  getFeedbackOverviewService,
  getFeedbackGraphStatsService,
  getDownloadCSV,
} from "../../services/apiService";
import { convertDemographyStatsData } from "../../utils/DataConvertion";
import MapComponent from "./MapComponent";
import { topology } from "../../data/TopologyMapdata";
import { useSelector, useDispatch } from "react-redux";
import {
  setViewOption,
  setDateRangeOption,
  setUnitOption,
  setRoleOption,
} from "../../slices/heapmapFIlterOptionSlice";
import { resetFilterOption } from "../../slices/heapmapFIlterOptionSlice";
import { FeedbackDemographicStats } from "../../components/FeedbackDemographicStats";
import Loading from "../../components/Loading";
import { LoginResponse } from "../../types";
import {
  IFeedbackStatPayload,
  IDemographyStats,
  IFeedbackOverview,
  IUnitList,
  IFeedbackMapResponse,
} from "../../types/feedback";
import { CSVLink } from "react-csv";

interface FeedbackOverViewProps {}

export const FeedbackOverView: React.FC<FeedbackOverViewProps> = () => {
  const [unitDropDownList, setUnitDropDownList] = useState<IUnitList[]>([]);
  const [demographyStatsArray, setDemographyStatsArray] = useState<
    IDemographyStats[]
  >([]);
  const [feedbackTableData, setFeedbackTableData] = useState<
    IFeedbackOverview[]
  >([]);
  const [downloadCSV, setDownloadCSV] = useState<any>([]);
  const [perPageCount, setPerPageCount] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [feedbackGraphData, setFeedbackGraphData] =
    useState<IFeedbackMapResponse | null>();
  const [topologyList, setTopologyList] = useState<any>();
  const [isFeedbackDemographyLoader, setFeedbackDemographyLoader] =
    useState<boolean>(false);
  const [isFeedbackLoader, setFeedbackLoader] = useState<boolean>(false);
  const [isFeedbackMapLoader, setFeedbackMapLoader] = useState<boolean>(false);
  const [errorState, setErrorState] = useState<any>({
    unitError: {},
    demographyStatsErr: {},
    feedbackTableErr: {},
    feedbackMapErr: {},
  });

  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const csvLinkEl = useRef<any>(null);

  const filterOptions = useSelector((state: any) => state.heapMapOptions);
  const { viewOption, dateRange, unitOption, roleOption } = filterOptions;
  const userLoginData: LoginResponse = useSelector(
    (state: any) => state.adminLogin
  );

  const onChange = (dates: any) => {
    const [start, end] = dates;
    const dateObj: any = { fromDate: start, toDate: end };
    dispatch(setDateRangeOption(dateObj));
  };

  const getFeedbackDemographyStats = async () => {
    setFeedbackDemographyLoader(true);
    let reqPayload: IFeedbackStatPayload = {
      start_date: dateRange.startDate,
      end_date: dateRange.endDate,
      feedback_for: roleOption,
    };

    if (unitOption !== "allunits") {
      reqPayload.unit_id = unitOption;
    }
    try {
      const demographyResponse = await getDemographyFeedbackStatsService(
        reqPayload
      );
      const convertedArray = convertDemographyStatsData(
        demographyResponse.data
      );
      setDemographyStatsArray(convertedArray);
      setFeedbackDemographyLoader(false);
    } catch (error) {
      setFeedbackDemographyLoader(false);
      setErrorState({
        ...errorState,
        demographyStatsErr: error,
      });
    }
  };

  const getFeedbackOverviewData = async () => {
    try {
      setFeedbackLoader(false);
      let reqPayload: IFeedbackStatPayload = {
        start_date: dateRange.startDate,
        end_date: dateRange?.endDate,
        per_page: perPageCount,
        page: currentPage,
        feedback_for: roleOption,
      };
      if (unitOption !== "allunits") {
        reqPayload.unit_id = unitOption;
      }

      const tableViewData = await getFeedbackOverviewService(reqPayload);
      setFeedbackTableData(tableViewData.data.feedback);
      setTotalRows(tableViewData.data.count);
      setFeedbackLoader(false);
      // if (reqPayload.feedback_for === "child" || "parent") {
      //   getdataDownload(roleOption);
      // }
    } catch (error) {
      setFeedbackLoader(false);
      setErrorState({
        ...errorState,
        feedbackTableErr: error,
      });
    }
  };

  const getUnits = async () => {
    try {
      const unitListResponse = await getUnitListService();
      setUnitDropDownList(unitListResponse.data);

      if (unitListResponse.data.length > 0) {
        dispatch(setUnitOption(unitListResponse.data[0]));
      }
    } catch (error) {
      setErrorState({
        ...errorState,
        unitError: error,
      });
    }
  };

  const getFeedbackGraphStats = async () => {
    setFeedbackMapLoader(true);
    setFeedbackGraphData(null);
    try {
      let reqPayload: IFeedbackStatPayload = {
        start_date: dateRange.startDate,
        end_date: dateRange.endDate,
        feedback_for: roleOption,
      };
      if (unitOption !== "allunits") {
        reqPayload.unit_id = unitOption;
      }
      const graphDataResponse: any = await getFeedbackGraphStatsService(
        reqPayload
      );

      setFeedbackGraphData(graphDataResponse);
      setFeedbackMapLoader(false);
    } catch (error) {
      setFeedbackMapLoader(false);
      setErrorState({
        ...errorState,
        feedbackMapErr: error,
      });
    }
  };

  useEffect(() => {
    if (
      viewOption === "graphview" &&
      unitOption !== "" &&
      dateRange.startDate !== null &&
      dateRange.endDate !== null
    ) {
      getFeedbackGraphStats();
    }
    // eslint-disable-next-line
  }, [filterOptions]);

  // eslint-disable-next-line
  useEffect(() => {
    if (
      unitOption !== "" &&
      dateRange.startDate !== null &&
      dateRange.endDate !== null
    ) {
      getFeedbackDemographyStats();
      getFeedbackOverviewData();
    }
    // eslint-disable-next-line
  }, [filterOptions, perPageCount, currentPage]);

  useEffect(() => {
    getUnits();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTopologyList(topology);
  }, [feedbackGraphData]);

  const columns = [
    {
      name: "Unit",
      selector: (row: any) => `${row.unit_name}`,
      sortable: true,
      minWidth: "120px",
      warp: false,
      cell: (row: any) => <div tabIndex={0}>{`${row.unit_name}`}</div>,
      sortField: "unit",
    },
    {
      name: "Submissions",
      selector: (row: any) => `${row.total_submissions}`,
      sortable: true,
      minWidth: "160px",
      cell: (row: any) => <div tabIndex={0}>{`${row.total_submissions}`}</div>,
      sortField: "submissioncount",
    },
    {
      name: "Average assessment",
      selector: (row: any) => `${row.feedback_status}`,
      sortable: false,
      minWidth: "220px",
      center: false,
      cell: (row: any) => (
        <AverageAssessment
          status={row.feedback_status}
          average={row.feedback_average}
        />
      ),
    },
    {
      name: "View",
      selector: (row: any) => `${row.view}`,
      sortable: false,
      allowOverflow: true,
      minWidth: "150px",
      cell: (row: any) => (
        <div className="flex gap-6">
          <span
            className="click_menu"
            onClick={() => navigate(`/feedback-view/${row.unit_id}`)}
          >
            View data
          </span>
        </div>
      ),
    },
  ];

  const handleBackBtn = () => {
    dispatch(resetFilterOption());
    navigate(-1);
  };
  // const getdataDownload = async (role: string) => {
  //   let reqPayload: IFeedbackStatPayload = {
  //     start_date: dateRange.startDate,
  //     end_date: dateRange.endDate,
  //     feedback_for: role,
  //   };

  //   try {
  //     const downloadResponse = await getDownloadCSV(reqPayload);
  //     setDownloadCSV(downloadResponse.data);
  //   } catch (error) {
  //     setErrorState({
  //       ...errorState,
  //       downloadErr: error,
  //     });
  //   }
  // };
  const handleDownload = async () => {
    let reqPayload: IFeedbackStatPayload = {
      start_date: dateRange.startDate,
      end_date: dateRange.endDate,
      feedback_for: roleOption,
    };
    try {
      if (unitOption !== "allunits") {
        reqPayload.unit_id = unitOption;
      }
      const downloadResponse = await getDownloadCSV(reqPayload);
      setDownloadCSV(downloadResponse.data);
    } catch (error) {
      setErrorState({
        ...errorState,
        downloadErr: error,
      });
    }
  };
  useEffect(() => {
    if (downloadCSV.length > 0 && csvLinkEl.current) {
      csvLinkEl.current.link.click();
    }
  }, [downloadCSV]);
  return (
    <PageLayout size="lg" role="commissioner">
      <div>
        <div className="relative">
          <InnerHeaderBlock
            backtext="Back"
            backLink="/report-dashboard"
            showSignOut
            heading="Heat map - England"
            handleBackBtn={handleBackBtn}
            contant={
              "This is the table heat map that displays the selected unit, some statistics and an overview of the responses received"
            }
            contant2="To download reports, please select either Service User, or Carers and Support Network from the dropdown below"
          />

          {roleOption === "both" ? (
            ""
          ) : (
            <button
              type="button"
              onClick={handleDownload}
              className="unit-button btn-postion"
            >
              Download
            </button>
          )}
          <CSVLink
            data={downloadCSV}
            asyncOnClick={true}
            ref={csvLinkEl}
            filename={`${
              roleOption === "child" ? "service-user" : "carers-support"
            }.csv`}
          />
        </div>
        <section className="select-group-section">
          <div className="select-group-block">
            {/* <SelectDropdown
              addclass="secondary-select"
              type="text"
              name="view"
              placeholder="Select"
              optionsArray={AuditTrailDataView}
              onChange={(e: any) => dispatch(setViewOption(e.value))}
              value={viewOption}
            /> */}
            <div className="rang-date-block ">
              <DatePicker
                id="dob"
                dateFormat="dd/MM/yyyy"
                className="form-control w-full date-field"
                name="dob"
                dropdownMode="select"
                maxDate={new Date()}
                selectsRange={true}
                onChange={onChange}
                placeholderText="Start Date"
                selected={dateRange.startDate}
                selectsStart
                startDate={dateRange.startDate}
                endDate={dateRange.endDate}
                monthsShown={2}
              />
            </div>
            <SelectDropdown
              addclass="secondary-select"
              type="text"
              name="unit"
              placeholder="Select"
              optionsArray={unitDropDownList}
              onChange={(e: any) => {
                setFeedbackLoader(false);
                dispatch(setUnitOption(e));
              }}
              value={unitOption}
            />
            <SelectDropdown
              addclass="secondary-select"
              type="text"
              name="gender"
              placeholder="Select"
              optionsArray={AuditTrailCatList}
              onChange={(e: any) => {
                setFeedbackLoader(false);
                dispatch(setRoleOption(e));
              }}
              value={roleOption}
            />
          </div>
          {/* <div className="select-group-block">
            <SelectDropdown
              addclass="secondary-select"
              type="text"
              name="gender"
              placeholder="Select"
              optionsArray={AuditTrailCatList}
              onChange={(e: any) => {
                setFeedbackLoader(false);
                dispatch(setRoleOption(e));
              }}
              value={roleOption}
            />
          </div> */}
        </section>
        {/* Error handling on unit list */}
        {errorState && errorState.unitError?.message ? (
          <ErrorMessage message={errorState.unitError?.message} />
        ) : null}
        {/* {viewOption === "tableview" ? ( */}
        <>
          {errorState && errorState.demographyStatsErr?.message ? (
            <ErrorMessage message={errorState.demographyStatsErr?.message} />
          ) : (
            <FeedbackDemographicStats
              demographyStatsArray={demographyStatsArray}
              isLoader={isFeedbackDemographyLoader}
            />
          )}
          <div className="h-6" />
          <section>
            {errorState && errorState.feedbackTableErr?.message ? (
              <ErrorMessage message={errorState.feedbackTableErr?.message} />
            ) : isFeedbackLoader ? (
              <div className="no-data-block">
                <Loading addclass="inner-loader" />
              </div>
            ) : (
              <Table
                columns={columns}
                data={feedbackTableData}
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={(count: number) => setPerPageCount(count)}
                onChangePage={(pageNumber: number) =>
                  setCurrentPage(pageNumber)
                }
              />
            )}
          </section>
        </>
        {/* ) : (
          <div>
            {errorState && errorState.feedbackMapErr?.message ? (
              <ErrorMessage message={errorState.feedbackMapErr?.message} />
            ) : isFeedbackMapLoader ? (
              <div className="no-data-block mt-6">
                <Loading addclass="inner-loader" />
              </div>
            ) : (
              <MapComponent
                graphData={feedbackGraphData}
                topology={topologyList}
              />
            )}
          </div>
        )} */}
      </div>
    </PageLayout>
  );
};
