import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getQuestionsRequest } from "../services/apiService";

const initialState: any = {
  roleId: null,
  userRole: "",
  unitName: "",
  loader: false,
  errorMessage: "",
  termsAndCondition: false
};

export const validateRoleId = createAsyncThunk(
  "role/checkRoleId",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await getQuestionsRequest(payload);
      // console.log('role response :>>', response);
      return response;
    } catch (error: any) {
      // console.log('role error :>>', error);
      if (error && error.role && error.hasOwnProperty('feedbackStatus') && error.feedbackStatus === false) {
        sessionStorage.setItem('role', error.role);
        window.location.href = '/pilot-closed';
        return;
      }
      throw rejectWithValue(error)
    }
  }
);

// Then, handle actions in your reducers:
const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    updateRole: (state: any, action: any) => {
      if (action && action?.payload) {
        const { id, terms } = action.payload;
        return {
          ...state,
          roleId: id,
          termsAndCondition: terms,
        };
      }
    },
    storeTermsAndCondition: (state: any, action: any) => {
      if (action && action?.payload) {
        return {
          ...state,
          termsAndCondition: action?.payload,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(validateRoleId.pending, (state, action) => {
        state.loader = true;
        state.roleId = null;
        state.userRole = "";
        state.unitName = "";
      })
      .addCase(validateRoleId.fulfilled, (state, action: any) => {
        if (action && action?.payload) {
          const { question_set_for, unit } = action.payload;
          return {
            ...state,
            loader: false,
            roleId: action?.meta?.arg?.id,
            errorMessage: "",
            userRole: question_set_for,
            unitName: unit
          }
        }
      })
      .addCase(validateRoleId.rejected, (state, action) => {
        state.errorMessage = action?.payload;
        state.loader = false;
        state.roleId = null;
        state.userRole = "";
        state.unitName = "";
      })
  },
});
export const { updateRole, storeTermsAndCondition } = roleSlice.actions;
const { reducer } = roleSlice;
export default reducer;