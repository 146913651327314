interface TACParentProps {
  agreement?: any;
}
const TACParent: React.FC<TACParentProps> = ({ agreement }) => {
  return (
    <div>
      <div className="headerText">About Safe2Share</div>
      <p>
        Safe2Share is a digital platform where you can give anonymous feedback
        about the service your significant other or person you are caring for is
        receiving. The purpose being:
      </p>
      <ul>
        <li>Enhancing service quality and safety.</li>
        <li>Amplifying user voices and empowerment.</li>
        <li>Facilitating early issue detection and resolution.</li>
        <li>
          Fostering collaboration and better communication across SHSC teams.
        </li>
      </ul>
      <p>
        This will help us see where things are going well or not so well and
        help us make improvements and provide better services for adults.
      </p>
      <p>
        Safe2Share is not a mechanism to provide complaints. If you would like
        to make a complaint regarding your care at SHSC, there are three ways:
      </p>
      <p>
        <strong>By writing:</strong> Complaints Team, Sheffield Health and
        Social Care NHS Foundation Trust, Centre Court, Atlas Way, Sheffield, S4
        7QQ
        <br />
        <strong> By emailing: </strong>
        <a className="ml-2" href="mailto:complaints@shsc.nhs.uk">
          complaints@shsc.nhs.uk
        </a>
        <br /> <strong>By calling:</strong> 0114 2718956
      </p>
      <div className="headerText">What will happen to my data?</div>
      <p>
        <strong>Who receives my data?</strong>
        <br /> Your answers go to the staff in your area and trust as well as
        ‘commissioners’ – the people who make decisions about mental health
        services in your area.
        <br />
        <div className="h-4" />
        We will look for themes and trends in the data from significant others
        and carers, and from service users, and seek to address issues.
      </p>
      <p>
        <strong>Anonymity</strong>
        <br /> This app is not designed as a process for raising complaints
        about individual care, but for looking at broad trends and identifying
        where we might need to make changes. Your responses to questions will
        not be linked to you. Therefore, it is important that if your
        significant other or the person you are caring for is experiencing
        difficulties and requires an immediate response regarding their care,
        please inform the staff in the service.
      </p>
      <p>
        <strong>Feedback Response</strong>
        <br /> The feedback you provide in this app will not be monitored on a
        daily basis. Any concerns raised will not be responded to immediately.
        Themes and feedback from the Safe2Share tool will be incorporated into
        existing Trust governance structures and reviewed locally in existing
        groups e.g., community team meetings / mutual help meetings and carer
        group meetings.
      </p>
      {agreement ? (
        ""
      ) : (
        <>
          <div className="headerText">Please accept our terms of service</div>
          <p>
            Please tick the box below to show you understand the purpose of
            Safe2Share and the Terms & Conditions before you give feedback.
          </p>
        </>
      )}
    </div>
  );
};

export default TACParent;
