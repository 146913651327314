import React, { useState } from "react";
import { PageLayout } from "../../components/PageLayout";
import { Card } from "../../components/Card";
import "../../components/styles/termsandconditions.css";
import BackButton from "../../components/backButton/BackButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { storeTermsAndCondition } from "../../slices/roleSlice";
import { patientTypes } from "../../data/userdata";
import { Checkbox, CustomButton } from "../../components";
import TACChild from "../../content/child/TACChild";
import TACParent from "../../content/parent/TACParent";
import Iconsvg from "../../components/icon/Icon";

interface TermsAndConditionsReadProps {}

export const TermsAndConditionsRead: React.FC<
  TermsAndConditionsReadProps
> = () => {
  const [isAgreeTAC, setAgreeTAC] = useState<any>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<any>();
  const tacAnswer = useSelector((state: any) => state.roleInfo);
  const { termsAndCondition } = tacAnswer;

  const handleClick = () => {
    if (isAgreeTAC) {
      sessionStorage.setItem("terms", JSON.stringify(isAgreeTAC));
      dispatch(storeTermsAndCondition(isAgreeTAC));
      navigate("/demographic");
    }
  };
  const backBtn=()=>{
    if(location.search){
      return navigate("/")
    }else{
      return navigate(-1)
    }
  }

  const handleTermsCondition = (event: any) => {
    const { checked } = event?.target;
    setAgreeTAC(checked);
  };
  const roleData = useSelector((state: any) => state.roleInfo);
  const feedbackID: string | null = sessionStorage.getItem("feedbackId");
  return (
    <PageLayout>
      <h1 className="page-head">Terms and Conditions</h1>
      <Card type={true} cardTitle="Safe2Share terms of service">
        <div className="card-content">
          {roleData.userRole === patientTypes.parent ? (
            <>
              {termsAndCondition ? (
                <TACParent agreement={true} />
              ) : (
                <TACParent />
              )}
            </>
          ) : (
            <>
              {termsAndCondition ? <TACChild agreement={true} /> : <TACChild />}
            </>
          )}
          <div className="h-4"></div>

          {termsAndCondition ? (
           <>{location.search&& 
            <div className="back-button">
              <button type="button" onClick={() => navigate("/demographic")} className="demoNextBtn">
                <Iconsvg name="nextButton" />
                <div className="h-2"></div>
                <div className="button-text text-primaryContent">Next</div>
              </button>
            </div>}</>
          ) : (
            <>
              <div className="flex justify-center">
                <Checkbox
                  type="checkbox"
                  label="I agree with the terms and conditions"
                  name="checkBoxCard"
                  onChange={handleTermsCondition}
                  selectedValue={isAgreeTAC}
                />
              </div>
              <div className="h-6"></div>
              <div className="tac-footer-container">
                <CustomButton
                  id="agreement-submit"
                  name="Submit"
                  hanldeClick={handleClick}
                  disable={!isAgreeTAC}
                />
                <a href={`/qr/${feedbackID}`}>Cancel</a>
              </div>
            </>
          )}
        </div>
        <div className="stepper-content-section">
          <div className="tac-backBtn">
            <div className="back-button">
              <button type="button" onClick={backBtn}>
                <Iconsvg name="backButton" />
                <div className="h-2"></div>
                <div className="button-text text-primaryContent">Back</div>
              </button>
            </div>
          </div>
        </div>
      </Card>
    </PageLayout>
  );
};
